import { Action, configureStore, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { translations } from 'translations';
import { rootReducer } from 'reducers';

import { persistStore, persistReducer, createMigrate, MigrationManifest } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import { migrations } from './persistMigrations';

function initStore() {
  const persistConfig = {
    key: 'ccp-store',
    version: 4,
    whitelist: ['settings', 'metadata'],
    storage,
    migrate: createMigrate(migrations as MigrationManifest),
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });

  const persistor = persistStore(store);

  return { store, persistor };
}

const initializer = initStore();
const { store } = initializer;

syncTranslationWithStore(store);

store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('en-US'));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = Dispatch<Action> & ThunkDispatch<RootState, null, Action>;

export const { persistor } = initializer;
export default store;
