import { createAction } from '@reduxjs/toolkit';
import { Customer } from 'types/Customer';

export const setSelectedCustomerAddressId = createAction<string>(
  'punchOrderFlow/setSelectedCustomerAddressId'
);

export const setCoordinatesMatchingError = createAction<string | undefined>(
  'punchOrderFlow/setCoordinatesMatchingError'
);

export const setSelectedCustomer = createAction<Customer>('punchOrderFlow/setSelectedCustomer');

export const resetSelectedCustomer = createAction('punchOrderFlow/resetSelectedCustomer');

export const setCustomerDefaults = createAction('punchOrderFlow/setCustomerDefaults');

export const setIsCustomerEditModeEnabled = createAction<boolean>(
  'punchOrderFlow/setIsCustomerEditModeEnabled'
);
