import { Nullable } from 'types/Nullable';

export interface OrderPositionPrice {
  isPriceOverriddenAsNet: Nullable<boolean>;
  grossPrice: number;
  netPrice: number;
}

export const isOrderPositionPrice = (obj: any): obj is OrderPositionPrice =>
  'isPriceOverriddenAsNet' in obj && 'grossPrice' in obj && 'netPrice' in obj;
