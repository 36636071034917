import { CreateOrder } from 'types/CreateOrder';

import { PunchOrderFlowState } from 'store/punchOrderFlow/punchOrderFlow.types';
import { PunchOrderFlowDiscountsState } from 'store/punchOrderFlowDiscounts/punchOrderFlowDiscounts.types';
import { SettingsState } from 'store/settings/settings.reducer';
import { getBaseOrderRequestBody } from './getBaseOrderRequestBody.util';

export const getCreateOrderRequestBody = (
  state: {
    punchOrderFlow: PunchOrderFlowState;
    punchOrderFlowDiscounts: PunchOrderFlowDiscountsState;
    settings: SettingsState;
  },
  orderCompositionDurationInSeconds: number
): CreateOrder | undefined => {
  const { selectedCustomer: customer, selectedCustomerAddressId } = state.punchOrderFlow;
  const baseOrderBody = getBaseOrderRequestBody(state, orderCompositionDurationInSeconds);

  if (!customer?.firstName || !baseOrderBody) {
    return undefined;
  }

  return {
    ...baseOrderBody,
    customer: {
      address: customer.addresses?.find(address => address.id === selectedCustomerAddressId),
      customerCode: customer.customerCode,
      email: customer.email,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phoneNumber: customer.phoneNumber,
      type: customer.type,
    },
  };
};
