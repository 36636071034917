import { AxiosRequestConfig } from 'axios';
import { instanceId } from 'service/metrics.service';
import { APP_TYPE, APP_VERSION } from 'utils/constants';

const getMetrics = () => {
  return {
    applicationType: APP_TYPE,
    applicationVersion: APP_VERSION,
    instanceId,
  };
};

export const addMetrics = (config: AxiosRequestConfig) => {
  const { applicationType, applicationVersion, instanceId } = getMetrics();

  return {
    ...config,
    headers: {
      ...config.headers,
      'X-App-Type': applicationType,
      'X-App-Version': applicationVersion,
      'X-App-Instance-Id': instanceId,
    },
  };
};
