import { applyTaxRate, formatPrice } from 'utils/price';
import { SelectedPositionPrice } from 'types/SelectedPositionPrice';

export const createSelectedPositionPrice = ({
  menuNetPrice,
  taxRate,
}: {
  menuNetPrice: number;
  taxRate: number;
}): SelectedPositionPrice => ({
  menuNetPrice,
  netPrice: formatPrice(menuNetPrice),
  grossPrice: formatPrice(applyTaxRate(menuNetPrice, taxRate)),
  isGrossEdited: false,
  isEdited: false,
});
