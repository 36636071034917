import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as discountsService from 'service/discounts.service';
import { Discount } from 'types/Discount';
import { PunchOrderFlowDiscountsState } from './punchOrderFlowDiscounts.types';
import { isEqual } from 'lodash';

export const getAvailableDiscounts = createAsyncThunk<
  Discount[],
  { countryId: string; pointOfSaleId: string; menuMasterIds: string[] },
  { state: { punchOrderFlowDiscounts: PunchOrderFlowDiscountsState } }
>(
  'punchOrderFlowDiscounts/getAvailableDiscounts',
  ({ countryId, pointOfSaleId, menuMasterIds }) =>
    discountsService
      .getAvailableDiscounts({ countryId, pointOfSaleId, menuMasterIds })
      .then(({ elements }) => elements),
  {
    condition: ({ menuMasterIds }, { getState }) => {
      const state = getState();
      const { availableDiscountsMenuMasterIds } = state.punchOrderFlowDiscounts;

      return !isEqual(menuMasterIds, availableDiscountsMenuMasterIds);
    },
  }
);

export const setIsPositionLevelDiscount = createAction<boolean>(
  'punchOrderFlowDiscounts/setIsPositionLevelDiscount'
);
export const setDeliveryDiscount = createAction<Discount | undefined>(
  'punchOrderFlowDiscounts/setDeliveryDiscount'
);
export const setOrderLevelDiscounts = createAction<Discount[]>(
  'punchOrderFlowDiscounts/setOrderLevelDiscounts'
);
export const setPositionLevelDiscount = createAction<{
  id: string;
  discount: Discount;
}>('punchOrderFlowDiscounts/setPositionLevelDiscount');

export const clearAvailableDiscounts = createAction(
  'punchOrderFlowDiscounts/clearAvailableDiscounts'
);

export const resetDiscounts = createAction('punchOrderFlowDiscounts/resetDiscounts');
