import { Info } from '@mui/icons-material';
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Box,
  FormControlLabel,
  Tooltip,
  IconButton,
  Switch,
  SwitchProps,
} from '@mui/material';
import { oldAppColors } from 'utils/theme/oldAppColors';
import { featureFlagDescriptions } from 'utils/constants';
import { FeatureEntries } from './FeatureToggles';
interface FeaturesListProps {
  features: FeatureEntries;
  isFeaturesDescriptionEnabled?: boolean;
  keySuffix: string;
  title: string;
  subtitle: string;
  switchProps?: SwitchProps;
  onChangeFeature?: (key: string, val: boolean) => void;
}

export const FeaturesList = ({
  features,
  isFeaturesDescriptionEnabled,
  keySuffix,
  title,
  subtitle,
  switchProps,
  onChangeFeature,
}: FeaturesListProps) => {
  return (
    <Stack direction="column" flex="50%">
      <Card
        variant="outlined"
        sx={{ borderColor: onChangeFeature ? oldAppColors.dimOrange : oldAppColors.mediumGray }}
      >
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {title}
            <Typography variant="caption" component="div">
              {subtitle}
            </Typography>
          </Typography>
          {features.map(([key, val]) => {
            return (
              <Box width="100%" key={`${key}-${keySuffix}`}>
                <FormControlLabel
                  data-testid={`${key}-${keySuffix}-label`}
                  data-dupe={`${key}-${keySuffix}`}
                  control={
                    <Switch
                      checked={val}
                      {...switchProps}
                      data-testid={`${key}-${keySuffix}-switch`}
                      onChange={() => onChangeFeature?.(key, !val)}
                    />
                  }
                  label={key}
                  name={key}
                />
                {isFeaturesDescriptionEnabled && (
                  <Tooltip title={featureFlagDescriptions[key]}>
                    <IconButton color="default">
                      <Info />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            );
          })}
        </CardContent>
      </Card>
    </Stack>
  );
};
