import { createReducer } from '@reduxjs/toolkit';
import {
  resetDiscounts,
  getAvailableDiscounts,
  setPositionLevelDiscount,
  setOrderLevelDiscounts,
  setDeliveryDiscount,
  setIsPositionLevelDiscount,
  clearAvailableDiscounts,
} from './punchOrderFlowDiscounts.actions';
import { PunchOrderFlowDiscountsState } from './punchOrderFlowDiscounts.types';
import {
  clearPositions,
  punchOrder,
  resetKitchen,
} from 'store/punchOrderFlow/punchOrderFlow.actions';

const punchOrderFlowDiscountsState: PunchOrderFlowDiscountsState = {
  isLoadingAvailableDiscounts: false,
  availableDiscounts: [],
  availableDiscountsMenuMasterIds: [],
  isPositionLevelDiscount: false,
  selectedOrderLevelDiscounts: [],
  selectedPositionLevelDiscounts: {},
};

const resetDiscountsState = (state: PunchOrderFlowDiscountsState) => {
  state.isLoadingAvailableDiscounts = false;
  state.availableDiscounts = [];
  state.availableDiscountsMenuMasterIds = [];
  delete state.selectedDeliveryDiscount;
  state.isPositionLevelDiscount = false;
  state.selectedOrderLevelDiscounts = [];
  state.selectedPositionLevelDiscounts = {};
};

export const punchOrderFlowDiscounts = createReducer(punchOrderFlowDiscountsState, builder => {
  builder
    .addCase(getAvailableDiscounts.pending, (state, { meta }) => {
      state.isLoadingAvailableDiscounts = true;
      state.availableDiscountsMenuMasterIds = meta.arg.menuMasterIds;
    })
    .addCase(getAvailableDiscounts.rejected, state => {
      state.isLoadingAvailableDiscounts = false;
      state.availableDiscountsMenuMasterIds = [];
    })
    .addCase(getAvailableDiscounts.fulfilled, (state, { payload }) => {
      state.isLoadingAvailableDiscounts = false;
      state.availableDiscounts = payload;
    })
    .addCase(setDeliveryDiscount, (state, { payload }) => {
      state.selectedDeliveryDiscount = payload;
    })
    .addCase(setOrderLevelDiscounts, (state, { payload }) => {
      state.selectedOrderLevelDiscounts = payload;
      state.isPositionLevelDiscount = false;
      state.selectedPositionLevelDiscounts = {};
    })
    .addCase(setPositionLevelDiscount, (state, { payload }) => {
      state.selectedOrderLevelDiscounts = [];
      state.isPositionLevelDiscount = true;
      state.selectedPositionLevelDiscounts[payload.id] = payload.discount;
    })
    .addCase(setIsPositionLevelDiscount, (state, { payload }) => {
      state.selectedOrderLevelDiscounts = [];
      state.selectedPositionLevelDiscounts = {};
      state.isPositionLevelDiscount = payload;
    })
    .addCase(clearAvailableDiscounts, state => {
      state.availableDiscounts = [];
      state.selectedOrderLevelDiscounts = [];
      state.selectedPositionLevelDiscounts = {};
      state.availableDiscountsMenuMasterIds = [];
    })
    .addCase(resetDiscounts, state => {
      resetDiscountsState(state);
    })
    .addCase(punchOrder.fulfilled, state => {
      resetDiscountsState(state);
    })
    .addCase(resetKitchen, state => {
      resetDiscountsState(state);
    })
    .addCase(clearPositions, state => {
      resetDiscountsState(state);
    });
});
