import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getUpdatedOrder } from 'components/OrderDetails/OrderDetailsBody/OrderDetailsBody.utils';
import { MenuPositionType } from 'types/MenuPositionType';
import * as orderService from 'service/order.service';
import * as paymentService from 'service/payments.service';
import { PaymentMethod } from 'types/PaymentMethod';
import { Order } from 'types/Order';
import { notify } from 'utils/notifications';
import { Discount } from 'types/Discount';
import * as discountsService from 'service/discounts.service';
import { groupBy, isEqual, map } from 'lodash';
import {
  BrandPositions,
  ComboDetails,
  OrderEditionState,
  SelectedAddon,
  SelectedGroupMenuItem,
  SelectedPosition,
} from 'store/orderEdition/orderEdition.types';
import { CcpMenuPosition } from 'types/CcpMenuPosition';
import { createPriceAdjustments } from 'utils/orders';
import { Nullable } from 'types/Nullable';
import { addWalletPointsToOrderSummary } from './orderSummaryWalletPoints.util';
import { OrderValidationResult } from 'types/OrderValidationResult';
import { v4 as uuidv4 } from 'uuid';
import { AdjustedPrice } from 'types/AdjustedPrice';
import { generateRandomString } from 'utils/string';

interface GetOrderSummaryOptions {
  currentOrderBody: Record<string, BrandPositions>;
  order: Order;
  customDeliveryFee: string;
  selectedDeliveryDiscount?: Discount;
  orderLevelDiscounts: Discount[];
  positionLevelDiscounts: Record<string, Discount>;
  canChangeDeliveryFee: boolean;
  isPriceDiscrepancy: boolean;
  expectedTotal: string;
  initialDiscounts?: {
    selectedDeliveryDiscount?: Discount;
    selectedOrderLevelDiscounts: Discount[];
    selectedPositionLevelDiscounts: Record<string, Discount>;
  };
}

export const getOrderSummary = createAsyncThunk<OrderValidationResult, GetOrderSummaryOptions>(
  'orderEdition/getOrderSummary',
  (
    {
      currentOrderBody,
      order,
      selectedDeliveryDiscount,
      customDeliveryFee,
      orderLevelDiscounts,
      positionLevelDiscounts,
      canChangeDeliveryFee,
      isPriceDiscrepancy,
      expectedTotal,
      initialDiscounts,
    },
    { signal }
  ) => {
    const updatedOrder = getUpdatedOrder(
      currentOrderBody,
      orderLevelDiscounts,
      positionLevelDiscounts,
      selectedDeliveryDiscount,
      initialDiscounts
    );

    const orderBody = {
      orderPositions: updatedOrder.orderPositions,
      customDeliveryFee: canChangeDeliveryFee ? Number(customDeliveryFee) : undefined,
      paymentMethod: order.paymentMethod,
      orderType: order.orderType,
      includeCutlery: order.includeCutlery,
      contactNumber: order.contactNumber || null,
      sourceOrderFriendlyId: order.sourceOrderFriendlyId,
      kitchenId: order.kitchenId,
      discountId: updatedOrder.orderDiscountId,
      deliveryDiscountId: updatedOrder.orderDeliveryDiscountId,
      discountsValidationDateTime: order.created,
      priceAdjustments: createPriceAdjustments({ isPriceDiscrepancy, expectedTotal }),
    };

    return orderService
      .validateOrder(
        {
          posID: order.pointOfSaleId,
          orderBody,
        },
        {
          hasErrorDialog: false,
          signal,
        }
      )
      .then(newOrderSummary =>
        addWalletPointsToOrderSummary(newOrderSummary, order, isPriceDiscrepancy)
      );
  }
);

export const changePaymentMethod = createAsyncThunk(
  'orderEdition/changePaymentMethod',
  ({ orderId, paymentMethod }: { orderId: string; paymentMethod: PaymentMethod }) =>
    paymentService.changeOrderPaymentMethod({ orderId, paymentMethod }).then(() => {
      notify.success('Payment method successfully updated');
    })
);

export const getAvailableDiscounts = createAsyncThunk<
  Discount[],
  {
    orderId: string;
    countryId: string;
    pointOfSaleId: string;
    menuMasterIds: string[];
    date: string;
  },
  { state: { orderEdition: OrderEditionState } }
>(
  'orderEdition/getAvailableDiscounts',
  ({ countryId, pointOfSaleId, menuMasterIds, date }) =>
    discountsService
      .getAvailableDiscounts({ countryId, pointOfSaleId, menuMasterIds, date })
      .then(({ elements }) => elements),
  {
    condition: ({ orderId, menuMasterIds }, { getState }) => {
      const state = getState();
      const orderEditionEntry = state.orderEdition.orders[orderId];

      return (
        !orderEditionEntry ||
        !isEqual(menuMasterIds, orderEditionEntry.availableDiscountsMenuMasterIds)
      );
    },
  }
);

export const clearAvailableDiscounts = createAction<string>('orderEdition/clearAvailableDiscounts');

export const initializeEdition = createAction<{
  orderId: string;
  orderBody: Record<string, BrandPositions>;
  isPriceDiscrepancy: boolean;
  expectedTotal: string;
  expectedTotalDiscountName: string | null;
  isScheduledForPartialRefund: Nullable<boolean>;
}>('orderEdition/initializeEdition');

export const modifyPositionQuantity = createAction<{
  orderId: string;
  brandId: string;
  positionTempId: string;
  quantity: number;
}>('orderEdition/modifyPositionQuantity');

export const removeExistingPosition = createAction<{
  orderId: string;
  brandId: string;
  positionTempId: string;
}>('orderEdition/removeExistingPosition');

export const removePosition = createAction<{
  orderId: string;
  brandId: string;
  positionTempId: string;
}>('orderEdition/removePosition');

export const restoreExistingPosition = createAction<{
  orderId: string;
  brandId: string;
  positionTempId: string;
}>('orderEdition/restoreExistingPosition');

export const setPositionPriceAdjustment = createAction<{
  orderId: string;
  brandId: string;
  positionTempId: string;
  adjustedPrice: AdjustedPrice;
}>('orderEdition/setPositionPriceAdjustment');

export const setIsComboValid = createAction<{
  orderId: string;
  masterId: string;
  isComboValid: boolean;
}>('orderEdition/setIsComboValid');

export const setDeliveryDiscount = createAction<{ orderId: string; value?: Discount }>(
  'orderEdition/setDeliveryDiscount'
);

export const setIsPositionLevelDiscount = createAction<{
  orderId: string;
  isPositionLevelDiscount: boolean;
}>('orderEdition/setIsPositionLevelDiscount');

export const updateItemDetails = createAction<{
  orderId: string;
  brandId: string;
  positionTempId: string;
  details: { selectedAddons: Record<string, SelectedAddon>; itemNote: string };
}>('orderEdition/updateItemDetails');

export const addPosition = createAction(
  'orderEdition/addPosition',
  ({
    orderId,
    option,
    brandId,
    brandName,
  }: {
    orderId: string;
    option: CcpMenuPosition;
    brandId: string;
    brandName: string;
  }) => {
    const position: SelectedPosition = {
      brandId,
      brandName,
      quantity: 1,
      id: uuidv4(),
      positionTempId: generateRandomString(),
    };

    if (option.type === MenuPositionType.ITEM) {
      position.item = {
        addons: {},
        netPrice: option.unitNetPrice,
        variantValueName: option.variantValueName,
        variantValueId: option.variantValueId,
        menuItemName: option.name,
        menuItemId: option.masterId,
        menuMasterId: option.menuMasterId,
      };
    }

    if (option.type === MenuPositionType.COMBO) {
      position.combo = {
        addons: {},
        comboGroups: [],
        netPrice: option.unitNetPrice,
        menuComboName: option.name,
        menuComboId: option.masterId,
        menuMasterId: option.menuMasterId,
      };
    }

    return {
      payload: {
        orderId,
        brandId,
        position,
      },
    };
  }
);

export const updateComboDetails = createAction(
  'orderEdition/updateComboDetails',
  ({
    orderId,
    brandId,
    positionTempId,
    details,
  }: {
    orderId: string;
    brandId: string;
    positionTempId: string;
    details: ComboDetails;
  }) => {
    const comboGroups = map(details.comboBody, (group, groupId) => {
      const groupPositions = Object.values(group.positions);
      const positionsGroupedByType = groupBy(groupPositions, 'type');

      return {
        menuComboGroupId: groupId,
        menuComboGroupName: groupPositions.length ? groupPositions[0].groupName : '',
        addons: (positionsGroupedByType[MenuPositionType.ADDON] ?? []) as SelectedAddon[],
        items: (positionsGroupedByType[MenuPositionType.ITEM] ?? []) as SelectedGroupMenuItem[],
      };
    });

    return {
      payload: {
        orderId,
        brandId,
        positionTempId,
        details,
        comboGroups,
        setTouched: details.isComboPreset,
      },
    };
  }
);

export const setOrderLevelDiscounts = createAction<{ orderId: string; discounts: Discount[] }>(
  'orderEdition/setOrderLevelDiscounts'
);

export const setPositionLevelDiscount = createAction<{
  orderId: string;
  id: string;
  discount: Discount;
}>('orderEdition/setPositionLevelDiscount');

export const destroyOrderEditionEntry = createAction<string>(
  'orderEdition/destroyOrderEditionEntry'
);

export const initializeDiscounts = createAction<{
  orderId: string;
  isPositionLevelDiscount: boolean;
  selectedOrderLevelDiscounts: Discount[];
  selectedPositionLevelDiscounts: Record<string, Discount>;
  selectedDeliveryDiscount?: Discount;
}>('orderEdition/initializeDiscounts');

export const setIsPriceDiscrepancy = createAction<{ orderId: string; isPriceDiscrepancy: boolean }>(
  'orderEdition/setIsPriceDiscrepancy'
);

export const setExpectedTotal = createAction<{ orderId: string; expectedTotal: string }>(
  'orderEdition/setExpectedTotal'
);

export const setExpectedTotalDiscountName = createAction<{
  orderId: string;
  expectedTotalDiscountName: string | null;
}>('orderEdition/setExpectedTotalDiscountName');

export const setIsScheduledForPartialRefund = createAction<{
  orderId: string;
  isScheduledForPartialRefund: boolean;
}>('orderEdition/setIsScheduledForPartialRefund');
