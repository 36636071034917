import { FunctionComponent, ReactNode } from 'react';
import {
  ErrorDialogContainer,
  Header,
  PreContainer,
  StyledDialogContent,
  StyledSentimentVeryDissatisfiedIcon,
} from './ErrorDialog,styles';
import { Typography } from '@mui/material';

interface ErrorDialogProps {
  title?: string;
  message: ReactNode;
  customMessage?: string;
  traceId?: string;
  dataTest?: string;
}

export const ErrorDialog: FunctionComponent<ErrorDialogProps> = ({
  title,
  message,
  customMessage,
  traceId,
  dataTest = 'error-dialog',
}) => (
  <ErrorDialogContainer data-testid={dataTest}>
    <Header>
      <StyledSentimentVeryDissatisfiedIcon />
      <Typography variant="h3" color="inherit">
        {title || 'Ouups...'}
      </Typography>
    </Header>

    <StyledDialogContent>
      {customMessage && <Typography color="textSecondary">{customMessage}</Typography>}
      {!customMessage && (
        <>
          <Typography color="textSecondary">
            {message || 'Something went wrong, try again later'}
          </Typography>
          {!!traceId && <PreContainer>traceId: {traceId}</PreContainer>}
        </>
      )}
    </StyledDialogContent>
  </ErrorDialogContainer>
);
