import { SelectedPositionPrice } from 'types/SelectedPositionPrice';
import { PriceOverride } from 'types/PriceOverride';
import { serializeSelectedPositionPrice } from './serializeSelectedPositionPrice.util';

export const serializeSelectedPositionPriceDivideByQuantity = (
  selectedPositionPrice: SelectedPositionPrice,
  isNet: boolean,
  quantity: number,
  parentQuantity: number
): PriceOverride | undefined => {
  const serializedPriceOverride: PriceOverride | undefined = serializeSelectedPositionPrice(
    selectedPositionPrice,
    isNet,
    quantity,
    parentQuantity
  );
  if (serializedPriceOverride) {
    return {
      total: serializedPriceOverride.total / parentQuantity,
      isNet,
    };
  }
  return undefined;
};
