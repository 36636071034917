import { toResourceIdAndLocation } from '../responseTransformers';
import { AxiosResponse } from 'axios';

export const successResponse = (response: AxiosResponse) => {
  if (response.status >= 400) {
    return Promise.reject(response);
  }

  if (response.config.hasFullResponse) {
    return response;
  }

  if (response.config.hasResourceLocation) {
    return toResourceIdAndLocation(response);
  }

  return response.data;
};
