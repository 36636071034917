import * as brandService from 'service/brand.service';
import * as orderService from 'service/order.service';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { OrderDetails } from '../punchOrderFlow.types';

export const getBrandPointOfSaleDeliveryPartners = createAsyncThunk(
  'brands/getBrandPointOfSaleDeliveryPartners',
  ({
    kitchenId,
    brandId,
    pointOfSaleId,
  }: {
    kitchenId: string;
    brandId: string;
    pointOfSaleId: string;
  }) =>
    brandService
      .getBrandPointOfSaleDeliveryPartners({ kitchenId, brandId, pointOfSaleId })
      .then(({ elements }) => elements)
);

export const getKitchenDeliveryPartners = createAsyncThunk(
  'brands/getKitchenDeliveryPartners',
  ({ kitchenId }: { kitchenId: string }) =>
    brandService.getKitchenDeliveryPartners({ kitchenId }).then(({ elements }) => elements)
);

export const assignDeliveryPartner = createAsyncThunk(
  'punchOrderFlow/assignDeliveryPartner',
  orderService.assignDeliveryPartner
);

interface UpdateOrderDetailsParams
  extends Omit<OrderDetails, 'brandDeliveryPartners' | 'kitchenDeliveryPartners'> {
  compositionStartTime: number | null;
}

export const updateOrderDetails = createAction<UpdateOrderDetailsParams>(
  'punchOrderFlow/updateOrderDetails'
);
