import { createAsyncThunk } from '@reduxjs/toolkit';
import * as walletsService from 'service/wallets.service';
import { ChangeWalletPointsAmount } from 'types/ChangeWalletPointsAmount';
import { Wallet } from 'types/Wallet';
import { WalletAction } from 'types/WalletAction';

export const loadWalletsList = createAsyncThunk<
  { wallets: Wallet[]; pagesCount: number },
  { searchPhrase: string; page: number; perPage: number; state: string }
>('wallets/loadList', ({ searchPhrase, page, perPage, state }, { signal }) => {
  return walletsService
    .loadWallets({
      phrase: searchPhrase,
      page,
      perPage,
      signal,
      state,
    })
    .then(({ elements, totalCount }) => ({
      wallets: elements,
      pagesCount: Math.ceil(totalCount / perPage),
    }));
});

export const getWalletDetails = createAsyncThunk(
  'wallets/getWalletDetails',
  ({ walletId }: { walletId: string }) => walletsService.getWalletDetails({ walletId })
);

export const getWalletActions = createAsyncThunk<
  { actions: WalletAction[]; pagesCount: number },
  { page?: number; perPage: number; walletId: string }
>('wallets/getWalletActions', ({ page, perPage, walletId }, { signal }) =>
  walletsService
    .getWalletActions({
      page,
      perPage,
      walletId,
      signal,
    })
    .then(({ elements, totalCount }) => ({
      actions: elements,
      pagesCount: Math.ceil(totalCount / perPage),
    }))
);

export const updateWallet = createAsyncThunk<
  Wallet,
  { walletId: string } & ChangeWalletPointsAmount
>('wallets/updateWallet', wallet =>
  walletsService
    .updateWallet(wallet)
    .then(() => walletsService.getWalletDetails({ walletId: wallet.walletId }))
);
