import { useAppSettings } from 'context/AppSettings/AppSettingsContextProvider';
import { useFeatureToggles } from 'context/FeatureTogglesContext/useFeatureToggles';
import { darkTheme } from 'utils/theme/appThemes/dark';
import { lightTheme } from 'utils/theme/appThemes/light';

export const useAppTheme = () => {
  const { appSettings, setAppSettings } = useAppSettings();
  const { features } = useFeatureToggles();

  const toggleTheme = () => {
    setAppSettings({
      themeMode: appSettings.themeMode === 'dark' ? 'light' : 'dark',
    });
  };

  const themes = {
    dark: darkTheme,
    light: lightTheme,
  };

  const theme = features.themeMode ? themes[appSettings.themeMode] : themes.dark;

  return { theme, toggleTheme };
};
