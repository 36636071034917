import { PunchOrderFlowState } from 'store/punchOrderFlowNext/punchOrderFlow.types';
import { PunchOrderFlowDiscountsState } from 'store/punchOrderFlowDiscounts/punchOrderFlowDiscounts.types';
import { SettingsState } from 'store/settings/settings.reducer';
import { ValidateOrder } from 'types/ValidateOrder';
import { getBaseOrderRequestBody } from './getBaseOrderRequestBody.util';

export const getValidateOrderRequestBody = (state: {
  punchOrderFlowNext: PunchOrderFlowState;
  punchOrderFlowDiscounts: PunchOrderFlowDiscountsState;
  settings: SettingsState;
}): ValidateOrder | undefined => getBaseOrderRequestBody(state);
