import { Url } from 'utils/constants';
import { List } from 'types/List';
import { CcpBrand } from 'types/CcpBrand';
import { KitchenBrandDeliveryPartner } from 'types/KitchenBrandDeliveryPartner';
import { KitchenDeliveryPartner } from 'types/KitchenDeliveryPartner';
import { api } from 'api';

export const getKitchenAndPosBrands = ({
  kitchenId,
  pointOfSaleId,
}: {
  kitchenId: string;
  pointOfSaleId: string;
}): Promise<List<CcpBrand>> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/kitchens/${kitchenId}/point-of-sale/${pointOfSaleId}/brands`
  );

export const getDeliveryAreaAndPosBrands = ({
  pointOfSaleId,
  deliveryAreaId,
}: {
  pointOfSaleId: string;
  deliveryAreaId: string;
}): Promise<List<CcpBrand>> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/point-of-sales/${pointOfSaleId}/delivery-areas/${deliveryAreaId}/brands`
  );

export const getBrandPointOfSaleDeliveryPartners = ({
  kitchenId,
  brandId,
  pointOfSaleId,
}: {
  kitchenId: string;
  brandId: string;
  pointOfSaleId: string;
}): Promise<List<KitchenBrandDeliveryPartner>> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/ccp/kitchens/${kitchenId}/brands/${brandId}/point-of-sales/${pointOfSaleId}/delivery-partners`
  );

export const getKitchenDeliveryPartners = ({
  kitchenId,
}: {
  kitchenId: string;
}): Promise<List<KitchenDeliveryPartner>> =>
  api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/ccp/kitchens/${kitchenId}/delivery-partners`);
