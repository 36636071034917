import { createReducer } from '@reduxjs/toolkit';

import {
  getWalletDetails,
  loadWalletsList,
  getWalletActions,
  updateWallet,
} from './wallets.actions';
import { WalletsState } from './wallets.types';

const initialState: WalletsState = {
  isLoadingWalletsList: true,
  wallets: [],
  walletActions: [],
  walletsPagesCount: 0,
  walletActionsPagesCount: 0,
  isLoadingWalletDetails: false,
  isLoadingWalletActions: false,
  walletDetails: undefined,
  isWalletUpdating: false,
};

export const wallets = createReducer(initialState, builder =>
  builder
    .addCase(loadWalletsList.pending, state => {
      state.isLoadingWalletsList = true;
    })
    .addCase(loadWalletsList.fulfilled, (state, { payload }) => {
      state.isLoadingWalletsList = false;
      state.wallets = payload.wallets;
      state.walletsPagesCount = payload.pagesCount;
    })
    .addCase(loadWalletsList.rejected, (state, { error }) => {
      if (error.name !== 'AbortError') {
        state.isLoadingWalletsList = false;
        state.wallets = [];
        state.walletsPagesCount = 0;
      }
    })
    .addCase(getWalletDetails.pending, state => {
      state.isLoadingWalletDetails = true;
    })
    .addCase(getWalletDetails.fulfilled, (state, { payload }) => {
      state.isLoadingWalletDetails = false;
      state.walletDetails = payload;
    })
    .addCase(getWalletDetails.rejected, state => {
      state.isLoadingWalletDetails = false;
    })
    .addCase(getWalletActions.pending, state => {
      state.isLoadingWalletActions = true;
    })
    .addCase(getWalletActions.fulfilled, (state, { payload }) => {
      state.isLoadingWalletActions = false;
      state.walletActions = payload.actions;
      state.walletActionsPagesCount = payload.pagesCount;
    })
    .addCase(getWalletActions.rejected, (state, { error }) => {
      if (error.name !== 'AbortError') {
        state.isLoadingWalletActions = false;
        state.walletActions = [];
        state.walletActionsPagesCount = 0;
      }
    })
    .addCase(updateWallet.pending, state => {
      state.isWalletUpdating = true;
    })
    .addCase(updateWallet.fulfilled, (state, { payload }) => {
      state.isWalletUpdating = false;
      state.walletDetails = payload;
      const currentWallet = state.wallets.find(wallet => wallet.id === payload.id);
      if (currentWallet) {
        currentWallet.balance = payload.balance;
        currentWallet.points.balance = payload.points.balance;
      }
    })
    .addCase(updateWallet.rejected, state => {
      state.isWalletUpdating = false;
    })
);
