import styled from '@emotion/styled';
import { AltRoute } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton, Stack, alpha } from '@mui/material';
import { useFeatureToggles } from 'context/FeatureTogglesContext/useFeatureToggles';
import { entries, isEqual } from 'lodash';
import { useCallback, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { oldAppColors } from 'utils/theme/oldAppColors';
import { FeatureFlags } from 'utils/constants';
import { FeaturesList } from './FeaturesList';
import { FeatureTogglesActions } from './FeatureTogglesActions';
import { FeatureTogglesHeader } from './FeatureTogglesHeader';

export type FeatureEntries = [string, boolean][];

const FeatureTogglesTrigger = styled(IconButton)`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
  background-color: ${alpha(oldAppColors.secondary, 0.7)};
  color: ${oldAppColors.white};
  &:hover {
    background-color: ${oldAppColors.secondary};
  }
`;

export const FeatureToggles = () => {
  const { setFeatures, features, environmentsFeatureFlags, isTogglingAllowed, currentEnvironment } =
    useFeatureToggles();

  const [initialState, setInitialState] = useState<FeatureFlags | null>(null);
  const [currentState, setCurrentState] = useState<FeatureFlags>(features);

  const [isOpen, setIsOpen] = useState(false);
  const closeDialog = () => setIsOpen(false);

  const envFeatureFlags = environmentsFeatureFlags[currentEnvironment];

  useEffectOnce(() => {
    setInitialState(features);
  });

  const onApply = useCallback(() => {
    setFeatures(previousState => ({
      ...previousState,
      ...currentState,
    }));
    window.location.reload();
  }, [currentState, setFeatures]);

  const onChangeFeature = useCallback(
    (key: string, val: boolean) => {
      setCurrentState(previousState => ({
        ...previousState,
        [key]: val,
      }));
    },
    [setCurrentState]
  );

  const onResetAll = useCallback(
    (featureFlags?: FeatureFlags) => {
      const envState = {
        ...(featureFlags || envFeatureFlags),
      };
      setCurrentState(envState);
    },
    [envFeatureFlags]
  );

  const localFeatures: FeatureEntries = entries(currentState);

  const environmentalFeatures: FeatureEntries = entries(envFeatureFlags || []);

  if (!isTogglingAllowed) {
    return null;
  }

  const areFeaturesChanged = !isEqual(initialState, currentState);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(_, reason) => {
          reason !== 'backdropClick' && setIsOpen(false);
        }}
        maxWidth="md"
        fullWidth
        data-testid="feature-toggles-modal"
      >
        <FeatureTogglesHeader onResetAll={onResetAll} currentState={currentState} />
        <DialogContent>
          <Stack direction="row" spacing={2} width="100%">
            <FeaturesList
              title={`Environmental feature flags (${currentEnvironment})`}
              subtitle="Related to the release of the application (read only)"
              features={environmentalFeatures}
              isFeaturesDescriptionEnabled
              keySuffix="env-flag"
              switchProps={{
                color: 'info',
                disabled: true,
              }}
            />
            <FeaturesList
              title="Local feature flags"
              subtitle="Related to the device (overrides the environmental flag)"
              features={localFeatures}
              keySuffix="user-flag"
              switchProps={{
                color: 'secondary',
              }}
              onChangeFeature={onChangeFeature}
            />
          </Stack>
        </DialogContent>
        <FeatureTogglesActions
          closeDialog={closeDialog}
          areFeaturesChanged={areFeaturesChanged}
          onApply={onApply}
        />
      </Dialog>

      <FeatureTogglesTrigger
        color="secondary"
        onClick={() => setIsOpen(true)}
        size="small"
        data-testid="feature-toggles-trigger"
      >
        <AltRoute />
      </FeatureTogglesTrigger>
    </>
  );
};
