import { AppSettings } from './AppSettingsContext.types';

export const migrations = [
  (settings: AppSettings) => ({
    ...settings,
    isSideNavigationOpen: false,
  }),
];

export const currentVersion = migrations.length;
