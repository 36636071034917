import { DeliveryFeeType } from './DeliveryFeeType';

export enum CountryCurrency {
  AED = 'AED',
  GBP = 'GBP',
  KWD = 'KWD',
  SAR = 'SAR',
  USD = 'USD',
}

export enum CustomerIdentifierType {
  PHONE_NUMBER = 'PHONE_NUMBER',
  NONE = 'NONE',
}

export enum DeliveryFeeTaxType {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE',
}

export enum DeliveryProviderType {
  NO_DELIVERY = 'NO_DELIVERY',
  KITOPI_OWN_DELIVERY = 'KITOPI_OWN_DELIVERY',
  PARTNER_DELIVERY = 'PARTNER_DELIVERY',
}

export enum KitchenAssignmentStrategy {
  DELIVERY_AREA = 'DELIVERY_AREA',
  BRANCH_ID = 'BRANCH_ID',
}

export interface POSDetails {
  cardPaymentSupported: boolean;
  cashPaymentSupported: boolean;
  countryCurrency: CountryCurrency;
  customerCodeRequired: boolean;
  customerIdentifierType: CustomerIdentifierType;
  deliveryFeeTax: {
    taxId: string;
    type: DeliveryFeeTaxType;
    value: number;
  };
  deliveryFeeType: DeliveryFeeType;
  deliveryGrossFee: number;
  deliveryNetFee: number;
  deliveryProviderType: DeliveryProviderType;
  kitchenAssignmentStrategy: KitchenAssignmentStrategy;
  multiBrandOrderAllowed: boolean;
  onlinePaymentSupported: boolean;
  orderDeliveryChangeable: boolean;
  partnerName: string;
  pointOfSaleId: string;
  sourceOrderIdRequired: boolean;
}
