import { Dialog, DialogProps } from '@mui/material';
import { ConfirmDialog } from 'components/dialogs/ConfirmDialog/ConfirmDialog';
import { ErrorDialog } from 'components/dialogs/ErrorDialog/ErrorDialog';
import { InfoDialog } from 'components/dialogs/InfoDialog/InfoDialog';
import { useModal } from 'mui-modal-provider';
import { ReactNode } from 'react';

interface ConfirmDialogProps {
  title: string;
  message: ReactNode;
  isDeleteAction?: boolean;
}

interface ErrorDialogProps {
  title?: string;
  message: ReactNode;
  customMessage?: string;
  traceId?: string;
  dataTest?: string;
}
interface InfoDialogProps {
  title: string;
  message: ReactNode;
}

type WithDialogProps = ReturnType<typeof useModal>;

const BaseDialog = ({
  DialogComponent,
  ...muiDialogProps
}: DialogProps & { DialogComponent: ({ onClose }: { onClose: () => void }) => JSX.Element }) => {
  return (
    <Dialog {...muiDialogProps}>
      <DialogComponent onClose={() => muiDialogProps.onClose?.({}, 'backdropClick')} />
    </Dialog>
  );
};

const ConfirmBaseDialog = ({
  DialogComponent,
  ...muiDialogProps
}: DialogProps & { DialogComponent: () => JSX.Element }) => <DialogComponent {...muiDialogProps} />;

let useDialogRef: WithDialogProps | undefined;

export const DialogUtilsConfigurator = () => {
  useDialogRef = useModal();
  return null;
};

export const dialog = {
  confirm(props: ConfirmDialogProps, muiDialogProps?: DialogProps) {
    return new Promise((resolve: (value: boolean) => void) => {
      this.genericDialog(
        ConfirmBaseDialog,
        () => (
          <ConfirmDialog
            {...props}
            onConfirm={isConfirmed => {
              resolve(isConfirmed);
            }}
          />
        ),
        muiDialogProps
      );
    });
  },
  error(props: ErrorDialogProps, muiDialogProps?: DialogProps) {
    this.genericDialog(BaseDialog, () => <ErrorDialog {...props} />, muiDialogProps);
  },
  info(props: InfoDialogProps, muiDialogProps?: DialogProps) {
    this.genericDialog(
      BaseDialog,
      selfProps => <InfoDialog {...selfProps} {...props} />,
      muiDialogProps
    );
  },
  genericDialog<T>(
    Component: ({
      DialogComponent,
      ...muiDialogProps
    }: DialogProps & {
      DialogComponent: (props?: T) => JSX.Element;
    }) => JSX.Element,
    DialogComponent: (props?: T) => JSX.Element,
    muiDialogProps?: DialogProps
  ) {
    useDialogRef?.showModal(Component, { DialogComponent, ...muiDialogProps });
  },
};
