export const oldAppColors = {
  white: '#fff',
  lightGray: '#e7eaec',
  dimWhite: '#e6e6e6',
  gray: '#dadada',
  mediumGray: '#7b7b7b',
  darkGray: '#474d5b',
  navyBlue: '#2f4050',
  midBlack: '#4a4a4a',
  dark: '#171717',
  green: '#33cc70',
  lightRed: '#f4433666',
  red: '#f44336',
  dimRed: '#633a40',
  pink: 'rgb(212, 41, 107)',
  silver: '#8a929a',
  holoGreen: '#218A95',
  dimHoloGreen: '#4f8e94',
  menuBackground: '#293038',
  appBackground: '#323b44',
  primary: '#269ec9',
  secondary: '#ff9800',
  warning: '#f7b84b',
  atlassianPrimary: '#2584ff',
  defaultButton: '#495561',
  errorButton: '#98452b',
  transparentOrange: '#ff98000d',
  lighterOrange: '#d28c2526',
  lightOrange: '#d28c2540',
  dimOrange: '#ff9800bf',
  inputBackground: '#272f36',
  darkInputBackground: '#1d2328',
  darkPanelBackground: '#2b333b',
};
