export interface FeatureFlags {
  serviceDeskTicketCreation: boolean;
  kitchenOperatingHoursAlert: boolean;
  salesforceAgentRole: boolean;
  loyaltyPointsManagerRole: boolean;
  mamIssueResolvingForIframe: boolean;
  jestTestFlag: boolean;
  invoicedOrders: boolean;
  themeMode: boolean;
  punchingOrderFreezer: boolean;
  grossAndNetPricesInPunchingOrder: boolean;
  punchingOrderNext: boolean;
}

export type Environment = 'local' | 'test' | 'dev' | 'stage' | 'prod';

interface ProcessEnv {
  APP_VERSION: string;
  ENVIRONMENT: Environment;
  SENTRY_DSN: string;
  API_ROOT: string;
  DYNATRACE_SCRIPT_URL: string;
  SERVICE_DESK_URL: string;
  DASHBOARD_URL: string;
  PUSHER_APP_KEY: string;
  PUSHER_CLUSTER: string;
  FEATURE_FLAGS: FeatureFlags;
  GOOGLE_MAPS_API_KEY: string;
  MUI_X_LICENSE_KEY: string;
  ALL_FEATURE_FLAGS: Record<string, FeatureFlags>;
}

export const {
  APP_VERSION,
  ENVIRONMENT,
  SENTRY_DSN,
  DASHBOARD_URL,
  SERVICE_DESK_URL,
  PUSHER_APP_KEY,
  PUSHER_CLUSTER,
  FEATURE_FLAGS,
  GOOGLE_MAPS_API_KEY,
  DYNATRACE_SCRIPT_URL,
  MUI_X_LICENSE_KEY,
  ALL_FEATURE_FLAGS,
} = process.env as unknown as ProcessEnv;

const { API_ROOT } = process.env as unknown as ProcessEnv;

export const featureFlagDescriptions: Record<keyof Omit<FeatureFlags, 'jestTestFlag'>, string> = {
  serviceDeskTicketCreation: 'The ability to report complaints in order edition view',
  kitchenOperatingHoursAlert: 'Kitchen closing alert in the second step of order punching',
  salesforceAgentRole: 'A role that allows to open Salesforce iframes only by a Salesforce Agent',
  loyaltyPointsManagerRole:
    'A role that allows to change loyalty points only by Loyalty Points Manager',
  mamIssueResolvingForIframe:
    'Disables the ability to resolving MAM issues by the Salesforce frames',
  invoicedOrders: 'For testing purposes only',
  themeMode: 'Enables theme mode',
  punchingOrderFreezer:
    'Enables punching order freezer - to freeze order punching state in each step and state (only for development purposes)',
  grossAndNetPricesInPunchingOrder:
    'Enables gross and net prices in punching order - experimentally dsiabled',
  punchingOrderNext: 'Enables next version of punching order',
};

export const featureTogglesEnvironments = ['local', 'dev', 'stage'];

export const APP_TYPE = 'ccp';

export const Url = {
  get KITCHEN_MANAGEMENT_API_URL(): string {
    return `${API_ROOT}/kitchen-management`;
  },
  get METADATA_API_URL(): string {
    return `${API_ROOT}/metadata`;
  },
  get DELIVERY_HUB_API_URL(): string {
    return `${API_ROOT}/delivery-hub`;
  },
  get ORDER_READ_API_URL(): string {
    return `${API_ROOT}/order-read`;
  },
  get MANUAL_ACTIONS_API_URL(): string {
    return `${API_ROOT}/mam`;
  },
  get CUSTOMER_API_URL(): string {
    return `${API_ROOT}/customer`;
  },
  get OPERATIONAL_ZONE_API_URL(): string {
    return `${API_ROOT}/operational-zone`;
  },
  get MENU_MANAGEMENT_API_URL(): string {
    return `${API_ROOT}/menu-management`;
  },
  get PERSONALIZATION_API_URL(): string {
    return `${API_ROOT}/personalization`;
  },
  get CRM_BRIDGE_API_URL(): string {
    return `${API_ROOT}/crm-bridge`;
  },
  get EXPERIMENTS_API_URL(): string {
    return `${API_ROOT}/experiments-api`;
  },
};

export const BREAKPOINTS = {
  mobile: 576,
  tablet: 1100,
  desktop: 1800,
};

export const ASC = 'ASC';
export const DESC = 'DESC';

export const SEARCH_ORDERS_MAX_DATE_RANGE = 93;
export const SEARCH_ORDERS_DATE_FORMAT_FROM = `yyyy-MM-dd'T'00:00:00XX`;
export const SEARCH_ORDERS_DATE_FORMAT_TO = `yyyy-MM-dd'T'23:59:59XX`;
