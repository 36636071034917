import { api } from 'api';
import { Url } from 'utils/constants';
import { CcpMenuPosition } from 'types/CcpMenuPosition';
import { CcpMenuCombo } from 'types/CcpMenuCombo';
import { List } from 'types/List';
import { CcpMenuItem } from 'types/CcpMenuItem';

export const getPositionsForBrand = ({
  posId,
  kitchenId,
  brandId,
}: {
  posId: string;
  kitchenId: string;
  brandId: string;
}): Promise<List<CcpMenuPosition>> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/point-of-sales/${posId}/kitchens/${kitchenId}/brands/${brandId}/positions`
  );

export const getBrandCombo = ({
  kitchenId,
  posId,
  brandId,
  masterId,
}: {
  kitchenId: string;
  posId: string;
  brandId: string;
  masterId: string;
}): Promise<CcpMenuCombo> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/point-of-sales/${posId}/kitchens/${kitchenId}/brands/${brandId}/positions/combos/${masterId}`
  );

export const getBrandItem = ({
  kitchenId,
  posId,
  brandId,
  masterId,
}: {
  kitchenId: string;
  posId: string;
  brandId: string;
  masterId: string;
}): Promise<CcpMenuItem> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/point-of-sales/${posId}/kitchens/${kitchenId}/brands/${brandId}/positions/items/${masterId}`
  );
