import { useConfirmationDialog } from 'components/common/ConfirmationDialog/useConfirmationDialog';
import { ReactNode } from 'react';
import { useEffectOnce } from 'usehooks-ts';

interface ConfirmDialogProps {
  title: string;
  message: ReactNode;
  onConfirm: (confirmed: boolean) => void;
  isDeleteAction?: boolean;
}

export const ConfirmDialog = ({
  onConfirm,
  title,
  message,
  isDeleteAction,
}: ConfirmDialogProps) => {
  const { showConfirmation } = useConfirmationDialog();
  useEffectOnce(() => {
    showConfirmation({
      title,
      message,
      confirmButtonLabel: 'Yes',
      confirmButtonColor: isDeleteAction ? 'error' : 'primary',
    }).then(onConfirm);
  });
  return <></>;
};
