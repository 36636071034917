import { api } from 'api';
import { Url } from 'utils/constants';
import { Nullable } from 'types/Nullable';
import { CustomerType } from 'types/CustomerType';
import { CreateCustomerAddress } from 'types/CreateCustomerAddress';
import { ResourceLocation } from 'types/ResourceLocation';
import { List } from 'types/List';
import { Customer } from 'types/Customer';

interface CreateNewCustomerProps {
  address: Nullable<CreateCustomerAddress>;
  customerCode: Nullable<string>;
  email: Nullable<string>;
  firstName: string;
  lastName: Nullable<string>;
  phoneNumber: Nullable<string>;
  type: CustomerType;
}

export const createNewCustomer = ({
  address,
  customerCode,
  email,
  firstName,
  lastName,
  phoneNumber,
  type,
}: CreateNewCustomerProps): Promise<ResourceLocation> =>
  api.post(
    `${Url.CUSTOMER_API_URL}/customers`,
    {
      address,
      customerCode,
      email,
      firstName,
      lastName,
      phoneNumber,
      type,
    },
    { hasResourceLocation: true }
  );

interface GetCustomersProps {
  page: number;
  perPage: number;
  searchPhrase: string;
  signal?: AbortSignal;
}

export const getCustomers = ({
  page,
  perPage,
  searchPhrase,
  signal,
}: GetCustomersProps): Promise<List<Customer>> =>
  api.get(`${Url.CUSTOMER_API_URL}/customers`, {
    params: {
      page,
      perPage,
      phoneNumberPrefix: searchPhrase,
    },
    signal,
    hasErrorDialog: true,
  });

export const getCustomer = ({ customerId }: { customerId: string }): Promise<Customer> =>
  api.get(`${Url.CUSTOMER_API_URL}/customers/${customerId}`);

export const resolveCustomer = ({ phoneNumber }: { phoneNumber: string }): Promise<Customer> =>
  api.get(`${Url.CUSTOMER_API_URL}/customers/by-phone/${phoneNumber}`, { hasErrorDialog: false });

export interface UpdateCustomerProps {
  customerCode: Nullable<string>;
  customerId: string;
  email: Nullable<string>;
  firstName: string;
  lastName: Nullable<string>;
  note: Nullable<string>;
  phoneNumber: Nullable<string>;
  type: CustomerType;
}

export const updateCustomer = ({
  customerCode,
  customerId,
  email,
  firstName,
  lastName,
  note,
  phoneNumber,
  type,
}: UpdateCustomerProps): Promise<void> =>
  api.put(`${Url.CUSTOMER_API_URL}/customers/${customerId}`, {
    customerCode,
    email,
    firstName,
    lastName,
    note,
    phoneNumber,
    type,
  });

export interface CreateCustomerAddressProps {
  address: CreateCustomerAddress;
  customerId: string;
}

export const createCustomerAddress = ({
  customerId,
  address,
}: CreateCustomerAddressProps): Promise<ResourceLocation> =>
  api.post(`${Url.CUSTOMER_API_URL}/customers/${customerId}/addresses`, address, {
    hasResourceLocation: true,
  });

export interface UpdateCustomerAddressProps {
  address: CreateCustomerAddress;
  customerAddressId: string;
  customerId: string;
}

export const updateCustomerAddress = ({
  customerId,
  customerAddressId,
  address,
}: UpdateCustomerAddressProps): Promise<void> =>
  api.put(
    `${Url.CUSTOMER_API_URL}/customers/${customerId}/addresses/${customerAddressId}`,
    address
  );
