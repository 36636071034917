import { AxiosResponse } from 'axios';

interface ResourceAndLocationResponse {
  location?: string[];
  resourceId?: string;
}

export const toResourceIdAndLocation = (response: AxiosResponse): ResourceAndLocationResponse => {
  const location: string[] | undefined = response.headers?.location?.split('/');
  const [resourceId] = (location?.filter?.(Boolean) ?? []).splice(-1);

  return {
    location,
    resourceId,
  } as const;
};
