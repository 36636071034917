import styled from '@emotion/styled';

import { SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon } from '@mui/icons-material';
import { alpha, DialogContent } from '@mui/material';

export const ErrorDialogContainer = styled.div``;

export const Header = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.error.main, 0.8),
  color: theme.palette.error.contrastText,
  padding: theme.spacing(4),
  textAlign: 'center',
}));

export const StyledSentimentVeryDissatisfiedIcon = styled(SentimentVeryDissatisfiedIcon)({
  fontSize: '100px',
});

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

export const PreContainer = styled('pre')({
  marginBottom: 0,
});
