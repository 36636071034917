import { ReactNode, useCallback, useRef, useState } from 'react';

import { ConfirmationDialog } from './ConfirmationDialog';
import { DialogData } from './ConfirmationDialog.types';
import { ConfirmationDialogContext } from './ConfirmationDialogContext';

type Resolver = (value: boolean) => void;

interface ConfirmationDialogProviderProps {
  children: ReactNode;
}

export const ConfirmationDialogProvider = ({ children }: ConfirmationDialogProviderProps) => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [currentDialogData, setCurrentDialogData] = useState<DialogData | null>(null);
  const resolver = useRef<Resolver>();

  const showConfirmation = useCallback((dialogData: DialogData) => {
    setIsDialogVisible(true);
    setCurrentDialogData(dialogData);

    return new Promise((resolve: Resolver) => {
      resolver.current = resolve;
    });
  }, []);

  const handleConfirm = () => {
    resolver.current && resolver.current(true);
    setIsDialogVisible(false);
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setIsDialogVisible(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ showConfirmation }}>
      {children}

      <ConfirmationDialog
        isVisible={isDialogVisible}
        title={currentDialogData?.title}
        message={currentDialogData?.message}
        cancelButtonLabel={currentDialogData?.cancelButtonLabel}
        confirmButtonLabel={currentDialogData?.confirmButtonLabel}
        confirmButtonColor={currentDialogData?.confirmButtonColor}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </ConfirmationDialogContext.Provider>
  );
};
