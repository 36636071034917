import { PaymentMethod } from 'types/PaymentMethod';

export interface SupportedPaymentMethods {
  cardPaymentSupported: boolean;
  cashPaymentSupported: boolean;
  onlinePaymentSupported: boolean;
}

export const getAvailablePosPaymentMethods = (supportedMethodsObject: SupportedPaymentMethods) => {
  const result: PaymentMethod[] = [];

  if (supportedMethodsObject.cardPaymentSupported) {
    result.push(PaymentMethod.CARD);
  }

  if (supportedMethodsObject.cashPaymentSupported) {
    result.push(PaymentMethod.CASH);
  }

  if (supportedMethodsObject.onlinePaymentSupported) {
    result.push(PaymentMethod.PAID);
  }

  return result;
};

export const getAnySupportedPaymentMethod = (supportedMethodsObject: SupportedPaymentMethods) => {
  const supportedMethods = getAvailablePosPaymentMethods(supportedMethodsObject);

  if (!supportedMethods.length) {
    throw new Error('There are no supported payment methods');
  }

  return supportedMethods[0];
};
