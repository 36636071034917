import { Discount } from 'types/Discount';

import { CcpMenuComboGroupMenuItem } from 'types/CcpMenuComboGroupMenuItem';
import { MenuPositionType } from 'types/MenuPositionType';
import { CcpMenuComboGroupAddon } from 'types/CcpMenuComboGroupAddon';
import { CcpMenuComboGroupQuantity } from 'types/CcpMenuComboGroupQuantity';
import { OrderValidationResult } from 'types/OrderValidationResult';
import { OrderPosition } from 'types/OrderPosition';
import { AdjustedPrice } from 'types/AdjustedPrice';

export interface SelectedAddon extends CcpMenuComboGroupAddon {
  label: string;
  value: string;
  type: MenuPositionType.ADDON;
  groupId: string;
  groupName: string;
  quantity: number;
}

export interface SelectedGroupMenuItem extends CcpMenuComboGroupMenuItem {
  label: string;
  value: string;
  type: MenuPositionType.ITEM;
  groupId: string;
  groupName: string;
  menuItemId: string;
  quantity: number;
}

interface SelectedItem {
  addons: Record<string, SelectedAddon>;
  netPrice: number;
  variantValueName?: string;
  variantValueId?: string;
  menuItemName: string;
  menuItemId: string;
  menuMasterId: string;
}

export interface SelectedComboGroup {
  menuComboGroupId: string;
  menuComboGroupName: string;
  addons: SelectedAddon[];
  items: SelectedGroupMenuItem[];
}

interface SelectedCombo {
  addons: Record<string, SelectedAddon>;
  comboGroups: SelectedComboGroup[];
  netPrice: number;
  menuComboName: string;
  menuComboId: string;
  menuMasterId: string;
}

export interface NewPosition {
  brandId: string;
  brandName: string;
  note?: string;
  quantity: number;
  combo?: SelectedCombo;
  item?: SelectedItem;
  id: string;
  adjustedPrice?: AdjustedPrice;
  positionTempId: string;
}

export interface ComboGroupItemOption extends CcpMenuComboGroupMenuItem {
  label: string;
  value: string;
  type: MenuPositionType.ITEM;
  groupId: string;
  groupName: string;
  menuItemId: string;
}

interface ComboGroupAddonOption extends CcpMenuComboGroupAddon {
  label: string;
  value: string;
  type: MenuPositionType.ADDON;
  groupId: string;
  groupName: string;
}

export type ComboGroupOption = ComboGroupItemOption | ComboGroupAddonOption;

export interface ComboGroup {
  name: string;
  quantity?: CcpMenuComboGroupQuantity;
  hideFromOrdering: boolean;
  positions: (ComboGroupOption & { quantity: number })[];
}

export interface ComboDetails {
  selectedAddons: Record<string, SelectedAddon>;
  comboNote: string;
  comboBody: Record<string, ComboGroup>;
  isComboPreset: boolean;
}

export interface ItemDetails {
  selectedAddons: Record<string, SelectedAddon>;
  itemNote: string;
}

export interface ExistingPosition extends OrderPosition {
  isRemoved: boolean;
  isExistingPosition: boolean;
  adjustedPrice?: AdjustedPrice;
  positionTempId: string;
}

export type SelectedPosition = ExistingPosition | NewPosition;

export const isExistingPosition = (position: SelectedPosition): position is ExistingPosition =>
  'isExistingPosition' in position;

export const isNewPosition = (position: SelectedPosition): position is NewPosition =>
  !isExistingPosition(position);

export interface BrandPositions {
  brandName: string;
  positions: Record<string, SelectedPosition>;
}

export interface OrderEditionEntry {
  summary?: OrderValidationResult;
  isLoadingSummary: boolean;
  summaryValidationError?: string;
  isUpdatingPaymentMethod: boolean;
  orderBody: Record<string, BrandPositions>;
  isChanged: boolean;
  isComboValid: Record<string, boolean>;
  discountsInitialized: boolean;
  availableDiscountsLoaded: boolean;
  availableDiscounts: Discount[];
  isLoadingAvailableDiscounts: boolean;
  availableDiscountsMenuMasterIds: string[];
  selectedDeliveryDiscount?: Discount;
  isPositionLevelDiscount: boolean;
  selectedOrderLevelDiscounts: Discount[];
  selectedPositionLevelDiscounts: Record<string, Discount>;
  isPriceDiscrepancy: boolean;
  expectedTotal: string;
  expectedTotalDiscountName: string | null;
  isScheduledForPartialRefund: boolean;
  canChangeScheduledForPartialRefund: boolean;
  initialDiscounts: {
    selectedDeliveryDiscount?: Discount;
    selectedOrderLevelDiscounts: Discount[];
    selectedPositionLevelDiscounts: Record<string, Discount>;
  };
}

export interface OrderEditionState {
  orders: Record<string, OrderEditionEntry>;
}
