import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';

interface InfoDialogProps {
  title: string;
  message: ReactNode;
  onClose?: () => void;
}

export const InfoDialog: FunctionComponent<InfoDialogProps> = ({ title, message, onClose }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={() => onClose?.()}>
        ok
      </Button>
    </DialogActions>
  </>
);
