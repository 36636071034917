import { dialog } from 'utils/dialog';
import * as Sentry from '@sentry/browser';
import { isAxiosError } from '../isAxiosError';
import { AxiosError, AxiosResponse } from 'axios';
import { signOut } from '@kitopi-com/auth';

interface BaseError {
  message?: string;
}
const notAuthorizedStatus = new Set([401, 403]);

const isUserNotAuthorized = (response: AxiosResponse) => notAuthorizedStatus.has(response.status);

export const errorResponse = <T>(error: AxiosError<T & BaseError>) => {
  const url = error.response?.config.url;
  const method = error.response?.config.url;
  const hasFullResponse = error.response?.config.hasFullResponse;

  const hasErrorDialog = error.response?.config.hasErrorDialog;
  const hasConnectivityErrorDialog = error.response?.config.hasConnectivityErrorDialog;

  if (error.response && isUserNotAuthorized(error.response)) {
    return signOut().then(() => Promise.reject(error.response));
  }

  if (hasFullResponse) {
    return error.response;
  }

  if (error.response?.status && error.response?.status >= 400) {
    const traceId = error.response?.headers['x-b3-trace-id'];
    const errorData = error.response.data;

    if (hasErrorDialog) {
      const errorMessage = errorData?.message;
      dialog.error({ traceId, message: errorMessage });
    }

    return Promise.reject({ ...errorData, traceId, status: error.response?.status });
  }

  if (error instanceof TypeError || isAxiosError(error)) {
    if (hasConnectivityErrorDialog) {
      dialog.error({
        title: 'Connectivity error',
        message: `There was and unexpected problem when trying to connect to the server.
        Please check your connectivity status and try again.`,
      });
    }
    Sentry.addBreadcrumb({
      category: 'fetch',
      message: `[${method || 'GET'}] ${url}`,
      level: 'error',
    });
  }

  return Promise.reject(error);
};
