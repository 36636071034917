import * as metadataService from 'service/metadata.service';
import { getCCPKitchens } from 'service/kitchen.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Metadata, MetadataState } from './metadata.types';

import { dialog } from 'utils/dialog';
import { getDeliveryAreas } from 'service/deliveryArea.service';
import { List } from 'types/List';
import { POSDetails } from 'types/POSDetails';
import { RegionTaxRate } from 'types/RegionTaxRate';

const checkCache = <T>(fetchData: () => Promise<List<T>>, state?: T[]): Promise<T[]> =>
  fetchData()
    .then(({ elements }) => elements)
    .catch(e => {
      if (state?.length) {
        return state;
      }
      return Promise.reject(e);
    });

export const loadMetadata = createAsyncThunk<
  Metadata,
  void,
  { state: { metadata: MetadataState } }
>('metadata/loadMetadata', (_, { getState }) => {
  const { brands, countries, deliveryAreas, deliveryPartners, kitchens, regions, pointsOfSale } =
    getState().metadata;

  return Promise.all([
    checkCache(metadataService.getBrands, brands),
    checkCache(metadataService.getCountries, countries),
    metadataService.getCountryPrefixes(),
    checkCache(getDeliveryAreas, deliveryAreas),
    checkCache(metadataService.getDeliveryPartners, deliveryPartners),
    checkCache(metadataService.getRegions, regions),
    checkCache(getCCPKitchens, kitchens),
    checkCache(metadataService.getPointsOfSale, pointsOfSale),
  ])
    .then(
      ([
        brands,
        countries,
        countryPhonePrefixes,
        deliveryAreas,
        deliveryPartners,
        regions,
        kitchens,
        pointsOfSale,
      ]) => ({
        brands,
        countries,
        countryPhonePrefixes,
        deliveryAreas,
        deliveryPartners,
        regions,
        kitchens,
        pointsOfSale,
      })
    )
    .catch(e => {
      dialog.error({
        message: 'Could not get metadata',
        title: 'Connectivity error',
      });
      return Promise.reject(e);
    });
});

export const getPosesForRegion = createAsyncThunk<
  POSDetails[],
  { regionId: string },
  { state: { metadata: MetadataState } }
>('metadata/getPosesForRegion', async ({ regionId }, { getState }) => {
  const { posesForRegion } = getState().metadata;
  try {
    const { elements } = await metadataService.getPosesForRegion({ regionId });
    return elements;
  } catch (e) {
    if (posesForRegion[regionId]) {
      return posesForRegion[regionId];
    }
    dialog.error({
      message: 'Could not get region poses',
      title: 'Connectivity error',
    });
    return Promise.reject(e);
  }
});

export const getRegionTaxes = createAsyncThunk<
  RegionTaxRate[],
  { regionId: string },
  { state: { metadata: MetadataState } }
>('metadata/getRegionTaxes', async ({ regionId }, { getState }) => {
  const { regionTaxes } = getState().metadata;
  try {
    const { elements } = await metadataService.getRegionTaxes({ regionId });
    return elements;
  } catch (e) {
    if (regionTaxes[regionId]) {
      return regionTaxes[regionId];
    }
    dialog.error({
      message: 'Could not get region taxes',
      title: 'Connectivity error',
    });
    return Promise.reject(e);
  }
});
