import { getJSON } from 'api';
import { AxiosRequestConfig } from 'axios';

/**
 * @description This is a hack to fetch all data at once
 * ! This one should be removed as soon as possible
 */

export const getAllPages = async <TResponse = unknown>(
  url: string,
  config?: AxiosRequestConfig<TResponse>
) => {
  const perPage = 1000;
  const { params = {}, signal, ...rest } = config ?? {};

  const response = await getJSON<TResponse>(url, {
    ...rest,
    params: {
      perPage,
      ...params,
      page: 1,
    },
    signal,
  });

  const remainingPages = Math.ceil(response.totalCount / perPage) - 1;

  const results = await Promise.all(
    Array.from({ length: remainingPages }).map((_, index) =>
      getJSON<TResponse>(url, {
        ...rest,
        params: {
          perPage,
          ...params,
          page: index + 2,
        },
        signal,
      })
    )
  );

  return {
    elements: results.reduce((acc, item) => [...acc, ...item.elements], response.elements),
    totalCount: response.totalCount,
  } as TResponse;
};
