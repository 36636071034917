import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as orderService from 'service/order.service';
import { PunchOrderFlowState } from './punchOrderFlow.types';
import { SettingsState } from 'store/settings/settings.reducer';
import { PunchOrderFlowDiscountsState } from 'store/punchOrderFlowDiscounts/punchOrderFlowDiscounts.types';
import { dialog } from 'utils/dialog';
import store, { AppDispatch } from 'store';
import { getBranchesForPOS } from 'service/branch.service';
import { setCurrency } from './punchOrderFlow.actions';
import { getCreateOrderRequestBody } from './utils/getCreateOrderRequestBody.util';
import { Kitchen } from 'types/Kitchen';
import { getDeliveryAreasForKitchenAndBrand, getDeliveryAreas } from 'service/deliveryArea.service';
import { OrderPriceDetails } from 'types/OrderPriceDetails';
import { OrderPosition } from 'types/OrderPosition';
import { getValidateOrderRequestBody } from './utils/getValidateOrderRequestBody.util';

export * from './orderBody/orderBody.actions';
export * from './orderDetails/orderDetails.actions';
export * from './orderSource/orderSource.actions';
export * from './selectedCustomer/selectedCustomer.actions';

export const punchOrder = createAsyncThunk<
  unknown,
  number,
  {
    state: {
      punchOrderFlowNext: PunchOrderFlowState;
      settings: SettingsState;
      punchOrderFlowDiscounts: PunchOrderFlowDiscountsState;
    };
  }
>('punchOrderFlowNext/punchOrder', (orderCompositionDurationInSeconds, { getState }) => {
  const state = getState();
  const { draftId } = state.punchOrderFlowNext.orderSource;

  if (!draftId) {
    const message = 'Lack of order draft identifier. Please try to create order again.';
    dialog.error({ message, title: '' });
    return Promise.reject(message);
  }

  const requestBody = getCreateOrderRequestBody(state, orderCompositionDurationInSeconds);

  if (!requestBody) {
    const message = 'Lack of order body. Please try to create order again.';
    dialog.error({ message, title: '' });
    return Promise.reject(message);
  }

  return orderService.punchNewOrder({
    draftId,
    orderBody: requestBody,
  });
});

export const setInitialState = createAction<PunchOrderFlowState>(
  'punchOrderFlowNext/setInitialState'
);

export const fetchBranchesForPOS = createAsyncThunk(
  'punchOrderFlowNext/fetchBranchesForPOS',
  getBranchesForPOS
);

export const setCustomDeliveryFee = createAction<string>('punchOrderFlowNext/setCustomDeliveryFee');

export const resetPunchOrderFlow = createAction('punchOrderFlowNext/resetPunchOrderFlow');

export const setRegionCurrency =
  (regionId: string) => (dispatch: AppDispatch, getState: typeof store.getState) => {
    const region = getState().metadata.regions.find(region => region.id === regionId);
    const country = getState().metadata.countries.find(country => country.id === region?.countryId);
    dispatch(setCurrency(country?.currency || null));
  };

export const resolveKitchen = createAction<Kitchen | 'KITCHEN_NOT_AVAILABLE' | null>(
  'punchOrderFlowNext/resolveKitchen'
);

export const setDefaultKitchenForBrandAndDeliveryArea = createAction(
  'punchOrderFlowNext/setDefaultKitchenForBrandAndDeliveryArea'
);

export const getDeliveryAreasForBranch = createAsyncThunk(
  'punchOrderFlowNext/getDeliveryAreasForBranch',
  getDeliveryAreasForKitchenAndBrand
);

export const getDeliveryAreasForRegion = createAsyncThunk(
  'punchOrderFlowNext/getDeliveryAreasForRegion',
  getDeliveryAreas
);

export const assignDeliveryPartner = createAsyncThunk(
  'punchOrderFlowNext/assignDeliveryPartner',
  orderService.assignDeliveryPartner
);

export const applyDiscounts = createAsyncThunk<
  {
    orderPriceDetails: OrderPriceDetails;
    orderPositions: OrderPosition[] | undefined;
  },
  undefined,
  {
    state: {
      punchOrderFlowNext: PunchOrderFlowState;
      punchOrderFlowDiscounts: PunchOrderFlowDiscountsState;
      settings: SettingsState;
    };
  }
>('punchOrderFlowNext/applyDiscounts', (_, { getState }) => {
  const { punchOrderFlowNext, punchOrderFlowDiscounts, settings } = getState();

  const pointOfSaleId = punchOrderFlowNext.orderSource.selectedPos?.pointOfSaleId;
  const requestBody = getValidateOrderRequestBody({
    punchOrderFlowNext,
    punchOrderFlowDiscounts,
    settings,
  });

  if (!requestBody) {
    return Promise.reject();
  }

  return orderService
    .validateOrder({
      posID: pointOfSaleId,
      orderBody: requestBody,
    })
    .then(({ orderPriceDetails, orderPositions }) => ({
      orderPriceDetails: {
        ...orderPriceDetails,
        taxAmount: orderPriceDetails?.taxes?.[0]?.taxValue ?? 0,
      },
      orderPositions,
    }));
});
