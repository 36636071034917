import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { TaxRate } from 'types/TaxRate';
import * as kitchenService from 'service/kitchen.service';

import { CcpBranch } from 'types/CcpBranch';
import { OrderType } from 'types/OrderType';
import { RegionOption } from 'store/metadata/metadata.selectors';
import { CcpKitchen } from 'types/CcpKitchen';
import { DeliveryType } from 'types/DeliveryType';
import { FinalDeliveryType } from 'types/FinalDeliveryType';
import { Kitchen } from 'types/Kitchen';
import { POSDetails } from 'types/POSDetails';
import * as orderService from 'service/order.service';
import { PunchOrderFlowState } from 'store/punchOrderFlowNext/punchOrderFlow.types';
import { ResourceLocation } from 'types/ResourceLocation';
import { OrderListElement } from 'types/OrderListElement';

export const resetSelectedKitchenAndBranch = createAction(
  'punchOrderFlowNext/resetSelectedKitchenAndBranch'
);

export const setTaxRate = createAction<TaxRate>('punchOrderFlowNext/setTaxRate');

export const createOrderDraft = createAsyncThunk<
  ResourceLocation,
  { pointOfSaleId: string; sourceOrderFriendlyId?: string },
  { state: { punchOrderFlowNext: PunchOrderFlowState } }
>('punchOrderFlowNext/createOrderDraft', orderService.createDraft, {
  condition: ({ pointOfSaleId, sourceOrderFriendlyId }, { getState }) => {
    const state = getState();
    const { punchOrderFlowNext } = state;
    const { draftCreatedForPos, draftCreatedForSourceOrderFriendlyId } =
      punchOrderFlowNext.orderSource;

    return (
      draftCreatedForPos !== pointOfSaleId ||
      draftCreatedForSourceOrderFriendlyId !== sourceOrderFriendlyId
    );
  },
});

export const getPotentialDuplicates = createAsyncThunk<
  { potentialDuplicates: OrderListElement[] },
  { pointOfSaleId: string; sourceOrderFriendlyId: string },
  { state: { punchOrderFlowNext: PunchOrderFlowState } }
>(
  'punchOrderFlowNext/getPotentialDuplicates',
  ({ pointOfSaleId, sourceOrderFriendlyId }, { getState }) =>
    orderService
      .getPotentialDuplicates({ pointOfSaleId, sourceOrderFriendlyId })
      .then(({ elements }) => {
        const { draftId } = getState().punchOrderFlowNext.orderSource;

        return { potentialDuplicates: elements.filter(order => order.id !== draftId) };
      })
);

export const resetPotentialDuplicates = createAction('punchOrderFlowNext/resetPotentialDuplicates');

export const resolveSelectedKitchen = createAsyncThunk(
  'punchOrderFlowNext/resolveSelectedKitchen',
  kitchenService.getCCPKitchen
);

export const getAvailableKitchensForRegion = createAsyncThunk(
  'punchOrderFlowNext/getAvailableKitchensForRegion',
  kitchenService.getCCPKitchens
);

export const setCurrency = createAction<string | null>('punchOrderFlowNext/setCurrency');

interface UpdateOrderSourceParams {
  isSourceOrderIdRequired?: boolean;
  compositionStartTime: number | null;
  orderType: OrderType;
  selectedRegion: RegionOption | null;
  selectedPos: POSDetails | null;
  sourceOrderID: string;
  selectedBranch?: CcpBranch | null;
  selectedKitchen?: CcpKitchen | null;
  deliveryType: DeliveryType | FinalDeliveryType.NO_DELIVERY;
  resolvedKitchen: Kitchen | 'KITCHEN_NOT_AVAILABLE' | null;
}

export const updateOrderSource = createAction<UpdateOrderSourceParams>(
  'punchOrderFlowNext/updateOrderSource'
);
