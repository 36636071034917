import styled from '@emotion/styled';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

export const AppErrorBoundaryRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  marginTop: theme.spacing(8),
  padding: theme.spacing(2),
}));

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  fontSize: '170px',
  marginBottom: theme.spacing(2),
}));
