import { api } from 'api';
import { Url } from 'utils/constants';
import { PaymentMethod } from 'types/PaymentMethod';

export const changeOrderPaymentMethod = ({
  orderId,
  paymentMethod,
}: {
  orderId: string;
  paymentMethod: PaymentMethod;
}): Promise<void> =>
  api.put(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/payments`, {
    paymentMethod,
  });
