import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as brandService from 'service/brand.service';
import { CcpBrand } from 'types/CcpBrand';
import { OrderType } from 'types/OrderType';
import { KitchenAssignmentStrategy } from 'types/POSDetails';
import { CcpMenuCombo } from 'types/CcpMenuCombo';
import { CcpMenuComboGroup } from 'types/CcpMenuComboGroup';
import { CcpMenuItem } from 'types/CcpMenuItem';
import { CcpMenuPosition } from 'types/CcpMenuPosition';
import { CcpPositionAddon } from 'types/CcpPositionAddon';
import { SelectedMenuComboGroupAddon } from 'types/SelectedMenuComboGroupAddon';
import { SelectedMenuComboGroupMenuItem } from 'types/SelectedMenuComboGroupMenuItem';
import { SelectedPosition } from 'types/SelectedPosition';
import { SelectedPositionPrice } from 'types/SelectedPositionPrice';

export const fetchAvailableBrands = createAsyncThunk(
  'punchOrderFlow/fetchAvailableBrands',
  ({
    pointOfSaleId,
    kitchenId,
    deliveryAreaId,
    kitchenAssignmentStrategy,
    orderType,
  }: {
    pointOfSaleId: string;
    kitchenId: string | undefined;
    deliveryAreaId: string | undefined;
    kitchenAssignmentStrategy: KitchenAssignmentStrategy | undefined;
    orderType: OrderType;
  }) => {
    if (
      kitchenAssignmentStrategy === KitchenAssignmentStrategy.BRANCH_ID &&
      orderType === OrderType.DELIVERY
    ) {
      return Promise.resolve([]);
    }

    if (kitchenId) {
      return brandService
        .getKitchenAndPosBrands({
          pointOfSaleId,
          kitchenId,
        })
        .then(({ elements }) => elements);
    }

    return deliveryAreaId
      ? brandService
          .getDeliveryAreaAndPosBrands({
            pointOfSaleId,
            deliveryAreaId,
          })
          .then(({ elements }) => elements)
      : Promise.resolve([]);
  }
);

export const setCurrentBrand = createAction<CcpBrand | null>('punchOrderFlow/setCurrentBrand');

export const selectBrand = createAction<CcpBrand>('punchOrderFlow/selectBrand');

export const resetSelectedBrands = createAction('punchOrderFlow/resetSelectedBrands');

export const removeBrand = createAction('punchOrderFlow/removeBrand', (brandId: string | null) => ({
  payload: brandId,
}));

export const resetBrand = createAction('punchOrderFlow/resetBrand');

export const setIsPriceDiscrepancy = createAction<boolean>('punchOrderFlow/setIsPriceDiscrepancy');

export const setExpectedTotal = createAction<string>('punchOrderFlow/setExpectedTotal');

export const setExpectedTotalDiscountName = createAction<string | null>(
  'punchOrderFlow/setExpectedTotalDiscountName'
);
export const setShowMenuExpanded = createAction<boolean>('punchOrderFlow/setShowMenuExpanded');
export const setShowCategories = createAction<boolean>('punchOrderFlow/setShowCategories');
export const setOrderNote = createAction<string>('punchOrderFlow/setOrderNote');
export const setActivePositionID = createAction<string | null>(
  'punchOrderFlow/setActivePositionID'
);

interface SetComboGroupValidityParams {
  brandId: string;
  positionIndex: number;
  groupId: string;
  isValid: boolean;
}

export const setComboGroupValidity = createAction<SetComboGroupValidityParams>(
  'punchOrderFlow/setComboGroupValidity'
);

interface SetCustomizationCompletedParams {
  brandId: string;
  positionIndex: number;
}

export const setCustomizationCompleted = createAction<SetCustomizationCompletedParams>(
  'punchOrderFlow/setCustomizationCompleted'
);

export const setPositionsForCurrentBrand = createAction<CcpMenuPosition[]>(
  'punchOrderFlow/setPositionsForCurrentBrand'
);

export const setPositionsLoading = createAction<boolean>('punchOrderFlow/setPositionsLoading');

interface SetSelectedPositionsForCurrentOrderParams {
  selectedPositions: SelectedPosition[];
  currentBrandId: string;
}

export const setSelectedPositionsForCurrentOrder =
  createAction<SetSelectedPositionsForCurrentOrderParams>(
    'punchOrderFlow/setSelectedPositionsForCurrentOrder'
  );

interface SetPositionDetailsParams {
  brandId: string;
  masterId: string;
  details: CcpMenuItem | CcpMenuCombo;
}

export const setPositionDetails = createAction<SetPositionDetailsParams>(
  'punchOrderFlow/setPositionDetails'
);

interface SelectComboGroupPositionParams {
  brandId: string;
  groupId: string;
  element: SelectedMenuComboGroupMenuItem | SelectedMenuComboGroupAddon;
  comboIndex: number;
  groupDetails: CcpMenuComboGroup | undefined;
}

export const selectComboGroupPosition = createAction<SelectComboGroupPositionParams>(
  'punchOrderFlow/selectComboGroupPosition'
);

interface SelectPositionAddonParams {
  brandId: string;
  addon: CcpPositionAddon;
  positionIndex: number;
}

export const selectPositionAddon = createAction<SelectPositionAddonParams>(
  'punchOrderFlow/selectPositionAddon'
);

interface RemovePositionAddonParams {
  brandId: string;
  positionIndex: number;
  addonIndex: number;
}

export const removePositionAddon = createAction<RemovePositionAddonParams>(
  'punchOrderFlow/removePositionAddon'
);
interface ChangeGroupPositionQuantityParams {
  brandId: string;
  groupId: string;
  comboIndex: number;
  elementIndex?: number;
  quantity: number;
}

export const changeGroupPositionQuantity = createAction<ChangeGroupPositionQuantityParams>(
  'punchOrderFlow/changeGroupPositionQuantity'
);

interface AddPositionNoteParams {
  brandId: string;
  positionIndex: number;
  note: string;
}

export const addPositionNote = createAction<AddPositionNoteParams>(
  'punchOrderFlow/addPositionNote'
);

interface RemoveGroupPositionParams {
  brandId: string;
  groupId: string;
  comboIndex: number;
  elementIndex: number;
}

export const removeGroupPosition = createAction<RemoveGroupPositionParams>(
  'punchOrderFlow/removeGroupPosition'
);

interface ChangePositionAddonQuantityParams {
  brandId: string;
  positionIndex: number;
  addonIndex: number;
  quantity: number;
}

export const changePositionAddonQuantity = createAction<ChangePositionAddonQuantityParams>(
  'punchOrderFlow/changePositionAddonQuantity'
);

export const setAllPositionsTouched = createAction('punchOrderFlow/setAllPositionsTouched');

export const clearPositions = createAction('punchOrderFlow/clearPositions');

interface SetPositionPriceOverrideParams {
  index: number;
  brandId: string;
  price: Omit<SelectedPositionPrice, 'isEdited' | 'menuNetPrice'>;
}

export const setPositionPriceOverride = createAction<SetPositionPriceOverrideParams>(
  'punchOrderFlow/setPositionPriceOverride'
);

interface SetGroupPositionPriceOverrideParams {
  brandId: string;
  elementIndex: number;
  comboIndex: number;
  groupId: string;
  price: Omit<SelectedPositionPrice, 'menuNetPrice' | 'isEdited'>;
}

export const setGroupPositionPriceOverride = createAction<SetGroupPositionPriceOverrideParams>(
  'punchOrderFlow/setGroupPositionPriceOverride'
);

interface SetAddonPriceOverrideParams {
  brandId: string;
  positionIndex: number;
  addonIndex: number;
  price: Omit<SelectedPositionPrice, 'menuNetPrice' | 'isEdited'>;
}

export const setAddonPriceOverride = createAction<SetAddonPriceOverrideParams>(
  'punchOrderFlow/setAddonPriceOverride'
);

export const resetKitchen = createAction('punchOrderFlow/resetKitchen');

export const searchMenuItem = createAction<string>('punchOrderFlow/searchMenuItem');
