import { lightThemeConfig, Theme } from '@kitopi-com/ui';
import { createTheme, lighten } from '@mui/system';
import { AppTheme } from 'types/AppTheme';

export const lightTheme = createTheme({
  ...lightThemeConfig,
  components: {
    ...lightThemeConfig.components,
    Typography: {
      baseColor: '#000',
      secondaryBaseColor: lighten('#000', 0.1),
    },
    TopBar: {
      backgroundColor: '#fff',
    },
    Body: {
      backgroundColor: lightThemeConfig.palette.grey[50],
    },
    Drawer: {
      backgroundColor: lightThemeConfig.palette.grey[50],
    },
    MenuPosition: {
      default: {
        borderColor: '#546B7E',
        backgroundColor: '#39404C',
      },
    },
    PunchingOrderProgressIndicator: {
      backgroundColor: lightThemeConfig.palette.white,
      expandButton: {
        backgroundColor: lightThemeConfig.palette.grey[50],
        color: '#000',
      },
      stepLink: {
        backgroundColor: lightThemeConfig.palette.grey[50],
        color: lightThemeConfig.palette.grey[400],
        enabledBackgroundColor: lightThemeConfig.palette.grey[100],
        enabledColor: '#000',
      },
    },
  },
} satisfies AppTheme) as Theme;
