import { AxiosRequestConfig } from 'axios';

export const defaultsInterceptor = (config: AxiosRequestConfig) => {
  const {
    hasAuthorization,
    hasResourceLocation,
    hasFullResponse,
    hasErrorDialog,
    hasConnectivityErrorDialog,
  } = config;

  return {
    ...config,
    headers: {
      ...(config.hasNoContentType ? null : { 'Content-Type': 'application/json' }),
      ...config.headers,
    },
    hasAuthorization: hasAuthorization != null ? hasAuthorization : true,
    hasFullResponse: hasFullResponse != null ? hasFullResponse : false,
    hasResourceLocation: hasResourceLocation != null ? hasResourceLocation : false,
    hasErrorDialog: hasErrorDialog != null ? hasErrorDialog : true,
    hasConnectivityErrorDialog:
      hasConnectivityErrorDialog != null ? hasConnectivityErrorDialog : true,
  };
};
