import { MenuPositionType } from 'types/MenuPositionType';
import { SelectedPosition } from 'types/SelectedPosition';
import { Discount } from 'types/Discount';

export const getOrderLevelPositionDiscount = (
  selectedOrderLevelDiscounts: Discount[],
  position: SelectedPosition
) => {
  const includedInBrandDiscount = (discount: Discount) =>
    discount.brandDiscountDetails?.menuMasterIds?.includes(position.menuMasterId);

  const includedInBogoDiscount = (discount: Discount) => {
    const details = discount.buyXGetYFreeDiscountDetails;

    if (details && details.menuMasterIds?.includes(position.menuMasterId)) {
      if (position.type === MenuPositionType.COMBO) {
        return details.menuComboMasterIds?.includes(position.masterId);
      } else {
        return details.menuItemMasterIds?.includes(position.masterId);
      }
    }

    return false;
  };

  return selectedOrderLevelDiscounts.find(
    discount => includedInBrandDiscount(discount) || includedInBogoDiscount(discount)
  );
};
