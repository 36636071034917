import { FunctionComponent, PropsWithChildren } from 'react';
import { Features } from './FeatureTogglesProvider.types';
import { FeatureTogglesContext } from './FeatureTogglesContext';
import { featureTogglesDefaultValues } from './FeatureTogglesDefaultValues';
import { useLocalStorageStateWithMigrations } from 'hooks/useLocalStorageStateWithMigrations';
import { migrations } from './FeatureTogglesProvider.migrations';
import {
  ALL_FEATURE_FLAGS,
  ENVIRONMENT,
  featureFlagDescriptions,
  FEATURE_FLAGS,
} from 'utils/constants';
import { omit } from 'lodash';

interface FeatureTogglesProviderProps {
  environments: string[];
}

export const FeatureTogglesProvider: FunctionComponent<
  FeatureTogglesProviderProps & PropsWithChildren
> = ({ children, environments }) => {
  const [features, setFeatures] = useLocalStorageStateWithMigrations<Features>(
    'featureToggles',
    featureTogglesDefaultValues,
    migrations
  );
  const filteredFeatures = omit(features, '_version');
  const isTogglingAllowed = environments.includes(ENVIRONMENT);

  return (
    <FeatureTogglesContext.Provider
      value={{
        features: isTogglingAllowed ? filteredFeatures : FEATURE_FLAGS,
        environmentsFeatureFlags: ALL_FEATURE_FLAGS,
        currentEnvironment: ENVIRONMENT,
        featureFlagDescriptions,
        setFeatures,
        isTogglingAllowed,
        environments,
      }}
    >
      {children}
    </FeatureTogglesContext.Provider>
  );
};
