import { Box, LinearProgress } from '@mui/material';

import {
  SplashScreenRoot,
  StyledKitopiLogo,
  StyledPaper,
  StyledTypography,
} from './SplashScreen.styles';
interface SplashScreenProps {
  readonly text: string;
}

export const SplashScreen = ({ text }: SplashScreenProps) => {
  return (
    <SplashScreenRoot data-testid="splash-screen">
      <StyledPaper>
        <Box p={6} pb={8}>
          <StyledKitopiLogo />

          <Box mt={8}>
            <StyledTypography color="textSecondary">{text}</StyledTypography>
          </Box>
        </Box>

        <LinearProgress color="secondary" />
      </StyledPaper>
    </SplashScreenRoot>
  );
};
