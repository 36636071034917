import { AxiosRequestConfig } from 'axios';
import kebabCase from 'lodash/kebabCase';

export const normalizeParams = (config: AxiosRequestConfig) => {
  const newConfig = {
    ...config,
    ...(config.params
      ? {
          params: Object.fromEntries(
            Object.entries(config.params)
              .filter(([, value]) => value !== null && value !== undefined && value !== '')
              .map(([key, value]) => [kebabCase(key), value])
          ),
        }
      : {}),
  };
  return newConfig;
};
