import {
  cancelOrder,
  setOrderAsDelivered,
  fetchPositionDetails,
  fetchPositions,
  getOrderDetails,
  searchOrders,
  setCurrentPage,
  updateSearchOrdersFilters,
  updateOrderAddress,
  getDeliveryDetails,
  assignAgent,
  loadPosDetails,
  reassignDeliveryPartner,
  getBrandPointOfSaleDeliveryPartners,
  getKitchenDeliveryPartners,
  getOrderModifications,
  fetchInternalOrderNotes,
  addInternalOrderNote,
  deleteInternalOrderNote,
  resolveInternalOrderNote,
  getParcelCheckInTime,
} from './orders.actions';

import { cancelOrder as cancelOrderFromManualActions } from '../manualActions/manualActions.actions';

import { createReducer } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { getQueryParamsFromURL } from './utils';
import { OrdersState } from 'store/orders/orders.types';
import { Order } from 'types/Order';
import { OrderListElement } from 'types/OrderListElement';
import { changePaymentMethod } from 'store/orderEdition/orderEdition.actions';

import { DESC } from 'utils/constants';
import { OrderSort } from 'types/OrderSort';

export const initialState: OrdersState = {
  searchOrdersFilters: {
    orderStatuses: [],
    searchRange: [new Date(), new Date()],
    sort: OrderSort.CREATED,
    sortOrder: DESC,
    businessId: '',
    phoneNumber: '',
    orderNumber: '',
    kitchenId: null,
    brandId: null,
    regionId: null,
    pointsOfSaleId: [],
    orderTypes: [],
    finalDeliveryType: null,
    deliveryPartnerId: null,
    ...getQueryParamsFromURL(),
  },
  isAssigningAgent: false,
  isReassigningDeliveryPartner: false,
  isOrdersSearching: false,
  searchResults: [],
  totalCountByStatus: {},
  selectedPage: 1,
  pagesCount: null,
  currentlyLoadingOrders: {},
  currentlyLoadingTimeFrames: {},
  currentlyLoadingDeliveryDetails: {},
  currentlyLoadingCheckInDetails: {},
  currentlyLoadingOrderModifications: [],
  currentlyCancellingOrders: {},
  currentlySettingAsDeliveredOrders: {},
  internalOrderNotes: {},
  currentlyLoadingInternalOrderNotes: {},
  currentlyAddingInternalOrderNote: {},
  currentlyResolvingInternalOrderNote: {},
  currentlyRemovingInternalOrderNote: {},
  ordersDetails: {},
  ordersDeliveryDetails: {},
  ordersCheckInTime: {},
  ordersTimeFrames: {},
  orderModifications: {},
  poses: {},
  brandDeliveryPartners: [],
  kitchenDeliveryPartners: [],
  editOrder: {
    positionDetails: {},
    kitchenId: null,
    posId: null,
    brandId: null,
    isPositionsLoading: false,
    isPositionDetailsLoading: false,
    positionsPerBrand: {},
    positionDetailsByMasterId: {},
  },
};

const updateOrderListItem = (state: OrdersState, order: Order) => {
  const foundOrder = state.searchResults.find(
    (searchItem: OrderListElement) => searchItem.id === order.id
  );

  if (!foundOrder) {
    return;
  }

  if (order.customerDetails) {
    foundOrder.customerName = order.customerDetails.fullName;

    if (order.customerDetails.phone) {
      foundOrder.customerPhone = order.customerDetails.phone;
    }
  }

  if (order.orderPriceDetails) {
    foundOrder.total = order.orderPriceDetails.total;
    foundOrder.currency = order.orderPriceDetails.currency;
  }

  if (order.agent?.fullName) {
    foundOrder.ccpAgentName = order.agent.fullName;
  }

  foundOrder.timeline = order.timeline;
  foundOrder.orderCompositionDurationInSeconds = order.orderCompositionDurationInSeconds;
  foundOrder.created = order.created;
  foundOrder.pointOfSaleName = order.pointOfSaleName;
  foundOrder.orderType = order.orderType;
  foundOrder.orderStatus = order.status;
  foundOrder.finalDeliveryType = order.finalDeliveryType;
  foundOrder.businessId = order.businessId;
  foundOrder.sourceOrderFriendlyId = order.sourceOrderFriendlyId;
  foundOrder.kitchenName = order.kitchenName;
  foundOrder.expectedPickUpTime = order.expectedPickUpTime;
  foundOrder.mostRecentContact = order.mostRecentContact;
};

export const orders = createReducer(initialState, builder =>
  builder
    .addCase(updateSearchOrdersFilters.fulfilled, (state, { payload }) => {
      state.selectedPage = 1;
      state.searchOrdersFilters = {
        ...state.searchOrdersFilters,
        ...payload,
      };
    })
    .addCase(searchOrders.pending, (state, { meta }) => {
      if (!meta.arg.silently) {
        state.isOrdersSearching = true;
      }
    })
    .addCase(searchOrders.fulfilled, (state, { payload }) => {
      state.isOrdersSearching = false;
      state.searchResults = payload.orders;
      state.totalCountByStatus = payload.totalCountByStatus;
      state.pagesCount = payload.pagesCount;
    })
    .addCase(searchOrders.rejected, (state, { error }) => {
      if (error.name !== 'AbortError') {
        state.isOrdersSearching = false;
        state.searchResults = initialState.searchResults;
      }
    })
    .addCase(setCurrentPage, (state, { payload }) => {
      state.selectedPage = payload.pageNumber;
    })
    .addCase(getOrderDetails.pending, (state, { payload: { orderID, silently } }) => {
      if (!silently) {
        state.currentlyLoadingOrders[orderID] = true;
      }
    })
    .addCase(getOrderDetails.rejected, (state, { payload: { orderID } }) => {
      delete state.currentlyLoadingOrders[orderID];
    })
    .addCase(getOrderDetails.fulfilled, (state, { payload: { order } }) => {
      delete state.currentlyLoadingOrders[order.id];
      state.ordersDetails[order.id] = order;
      updateOrderListItem(state, order);
    })
    .addCase(getDeliveryDetails.pending, (state, { meta }) => {
      if (!meta.arg.silently) {
        state.currentlyLoadingDeliveryDetails[meta.arg.orderID] = true;
      }
    })
    .addCase(getDeliveryDetails.rejected, (state, { meta }) => {
      delete state.currentlyLoadingDeliveryDetails[meta.arg.orderID];
    })
    .addCase(getDeliveryDetails.fulfilled, (state, { meta, payload: { deliveryDetails } }) => {
      delete state.currentlyLoadingDeliveryDetails[meta.arg.orderID];

      state.ordersDeliveryDetails[meta.arg.orderID] = deliveryDetails;
    })
    .addCase(getParcelCheckInTime.pending, (state, { meta }) => {
      state.currentlyLoadingDeliveryDetails[meta.arg.orderId] = true;
    })
    .addCase(getParcelCheckInTime.rejected, (state, { meta }) => {
      delete state.currentlyLoadingDeliveryDetails[meta.arg.orderId];
    })
    .addCase(getParcelCheckInTime.fulfilled, (state, { meta, payload }) => {
      delete state.currentlyLoadingDeliveryDetails[meta.arg.orderId];
      state.ordersCheckInTime[meta.arg.orderId] = payload;
    })
    .addCase(cancelOrder.pending, (state, { meta }) => {
      state.currentlyCancellingOrders[meta.arg.orderID] = true;
    })
    .addCase(cancelOrder.rejected, (state, { meta }) => {
      delete state.currentlyCancellingOrders[meta.arg.orderID];
    })
    .addCase(cancelOrder.fulfilled, (state, { payload }) => {
      delete state.currentlyCancellingOrders[payload.id];
      state.ordersDetails[payload.id] = payload;
      updateOrderListItem(state, payload);
    })
    .addCase(cancelOrderFromManualActions.pending, (state, { meta }) => {
      state.currentlyCancellingOrders[meta.arg.orderID] = true;
    })
    .addCase(cancelOrderFromManualActions.rejected, (state, { meta }) => {
      delete state.currentlyCancellingOrders[meta.arg.orderID];
    })
    .addCase(cancelOrderFromManualActions.fulfilled, (state, { meta }) => {
      delete state.currentlyCancellingOrders[meta.arg.orderID];
    })
    .addCase(setOrderAsDelivered.pending, (state, { meta }) => {
      state.currentlySettingAsDeliveredOrders[meta.arg.orderId] = true;
    })
    .addCase(setOrderAsDelivered.rejected, (state, { meta }) => {
      delete state.currentlySettingAsDeliveredOrders[meta.arg.orderId];
    })
    .addCase(setOrderAsDelivered.fulfilled, (state, { payload }) => {
      delete state.currentlySettingAsDeliveredOrders[payload.id];
      state.ordersDetails[payload.id] = payload;
      updateOrderListItem(state, payload);
    })
    .addCase(loadPosDetails.fulfilled, (state, { meta, payload }) => {
      state.poses[meta.arg.pointOfSaleId] = payload;
    })
    .addCase(fetchPositions.pending, (state, { meta }) => {
      state.editOrder.isPositionsLoading = true;
      state.editOrder.posId = meta.arg.posId;
      state.editOrder.kitchenId = meta.arg.kitchenId;
      state.editOrder.brandId = meta.arg.brandId;
    })
    .addCase(fetchPositions.fulfilled, (state, { meta, payload }) => {
      const positionsSorted = sortBy(payload, ['name']);

      state.editOrder.isPositionsLoading = false;
      state.editOrder.positionsPerBrand[meta.arg.brandId] = positionsSorted;
    })
    .addCase(fetchPositions.rejected, state => {
      state.editOrder.isPositionsLoading = false;
    })
    .addCase(fetchPositionDetails.pending, state => {
      state.editOrder.isPositionDetailsLoading = true;
    })
    .addCase(fetchPositionDetails.fulfilled, (state, { payload }) => {
      state.editOrder.isPositionDetailsLoading = false;

      const positionDetails = {
        ...payload,
        addons: sortBy(payload.addons, [addon => addon.name.toLowerCase()]),
        groups:
          'groups' in payload &&
          sortBy(payload.groups, 'order').map(group => ({
            ...group,
            menuItems: sortBy(group.menuItems, [item => item.name.toLowerCase()]),
            addons: sortBy(group.addons, [addon => addon.name.toLowerCase()]),
          })),
      };

      state.editOrder.positionDetails = positionDetails;

      state.editOrder.positionDetailsByMasterId[payload.masterId] = positionDetails;
    })
    .addCase(fetchPositionDetails.rejected, state => {
      state.editOrder.isPositionDetailsLoading = false;
      state.editOrder.positionDetails = initialState.editOrder.positionDetails;
    })
    .addCase(updateOrderAddress.fulfilled, (state, { meta }) => {
      const { orderID: orderId, address } = meta.arg;

      if (state.ordersDetails[orderId] && address.kitopiDeliveryAreaName) {
        state.ordersDetails[orderId].deliveryAddress = {
          ...state.ordersDetails[orderId].deliveryAddress,
          kitopiDeliveryAreaName: address.kitopiDeliveryAreaName,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          postCode: address.postCode,
          additionalAddressInstructions: address.additionalAddressInstructions,
        };

        updateOrderListItem(state, state.ordersDetails[orderId]);
      }
    })

    .addCase(changePaymentMethod.fulfilled, (state, { meta }) => {
      const order = state.ordersDetails[meta.arg.orderId];

      if (order) {
        order.paymentMethod = meta.arg.paymentMethod;
      }
    })
    .addCase(assignAgent.pending, state => {
      state.isAssigningAgent = true;
    })
    .addCase(assignAgent.fulfilled, (state, { payload }) => {
      state.isAssigningAgent = false;
      state.ordersDetails[payload.id] = payload;
      updateOrderListItem(state, payload);
    })
    .addCase(assignAgent.rejected, state => {
      state.isAssigningAgent = false;
    })
    .addCase(reassignDeliveryPartner.pending, state => {
      state.isReassigningDeliveryPartner = true;
    })
    .addCase(reassignDeliveryPartner.fulfilled, state => {
      state.isReassigningDeliveryPartner = false;
    })
    .addCase(reassignDeliveryPartner.rejected, state => {
      state.isReassigningDeliveryPartner = false;
    })
    .addCase(getBrandPointOfSaleDeliveryPartners.fulfilled, (state, { payload }) => {
      state.brandDeliveryPartners = payload;
    })
    .addCase(getKitchenDeliveryPartners.fulfilled, (state, { payload }) => {
      state.kitchenDeliveryPartners = payload;
    })
    .addCase(getOrderModifications.pending, (state, { meta }) => {
      state.currentlyLoadingOrderModifications.push(meta.arg.orderId);
    })
    .addCase(getOrderModifications.fulfilled, (state, { payload, meta }) => {
      state.currentlyLoadingOrderModifications = state.currentlyLoadingOrderModifications.filter(
        orderId => orderId !== meta.arg.orderId
      );
      state.orderModifications[meta.arg.orderId] = payload.elements;
    })
    .addCase(getOrderModifications.rejected, (state, { meta }) => {
      state.currentlyLoadingOrderModifications = state.currentlyLoadingOrderModifications.filter(
        orderId => orderId !== meta.arg.orderId
      );
    })
    .addCase(fetchInternalOrderNotes.pending, (state, { meta }) => {
      state.currentlyLoadingInternalOrderNotes[meta.arg.orderId] = true;
    })
    .addCase(fetchInternalOrderNotes.fulfilled, (state, { payload, meta }) => {
      state.currentlyLoadingInternalOrderNotes[meta.arg.orderId] = false;
      state.internalOrderNotes[meta.arg.orderId] = payload;
    })
    .addCase(fetchInternalOrderNotes.rejected, (state, { meta }) => {
      state.currentlyLoadingInternalOrderNotes[meta.arg.orderId] = false;
    })
    .addCase(addInternalOrderNote.pending, (state, { meta }) => {
      state.currentlyAddingInternalOrderNote[meta.arg.orderId] = true;
    })
    .addCase(addInternalOrderNote.fulfilled, (state, { meta }) => {
      state.currentlyAddingInternalOrderNote[meta.arg.orderId] = false;
    })
    .addCase(addInternalOrderNote.rejected, (state, { meta }) => {
      state.currentlyAddingInternalOrderNote[meta.arg.orderId] = false;
    })
    .addCase(deleteInternalOrderNote.pending, (state, { meta }) => {
      state.currentlyRemovingInternalOrderNote[meta.arg.noteId] = true;
    })
    .addCase(deleteInternalOrderNote.fulfilled, (state, { meta }) => {
      state.currentlyRemovingInternalOrderNote[meta.arg.noteId] = false;
    })
    .addCase(deleteInternalOrderNote.rejected, (state, { meta }) => {
      state.currentlyRemovingInternalOrderNote[meta.arg.noteId] = false;
    })
    .addCase(resolveInternalOrderNote.pending, (state, { meta }) => {
      state.currentlyResolvingInternalOrderNote[meta.arg.noteId] = true;
    })
    .addCase(resolveInternalOrderNote.fulfilled, (state, { meta }) => {
      state.currentlyResolvingInternalOrderNote[meta.arg.noteId] = false;
    })
    .addCase(resolveInternalOrderNote.rejected, (state, { meta }) => {
      state.currentlyResolvingInternalOrderNote[meta.arg.noteId] = false;
    })
);
