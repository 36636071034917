import { createReducer } from '@reduxjs/toolkit';
import { loadMetadata, getPosesForRegion, getRegionTaxes } from 'store/metadata/metadata.actions';
import { MetadataState } from './metadata.types';

export const metadataState: MetadataState = {
  isMetadataLoading: true,
  isMetadataLoaded: false,
  regions: [],
  deliveryAreas: [],
  deliveryPartners: [],
  countryPhonePrefixes: {},
  brands: [],
  kitchens: [],
  countries: [],
  posesForRegion: {},
  pointsOfSale: [],
  regionTaxes: {},
  isLoadingRegionTaxes: false,
  isLoadingPosesForRegion: false,
};

export const metadata = createReducer(metadataState, builder => {
  builder
    .addCase(loadMetadata.pending, state => {
      state.isMetadataLoading = true;
    })
    .addCase(loadMetadata.fulfilled, (state, { payload }) => {
      state.brands = payload.brands;
      state.countries = payload.countries;
      state.countryPhonePrefixes = payload.countryPhonePrefixes;
      state.deliveryAreas = payload.deliveryAreas;
      state.deliveryPartners = payload.deliveryPartners;
      state.kitchens = payload.kitchens;
      state.regions = payload.regions;
      state.pointsOfSale = payload.pointsOfSale;
      state.isMetadataLoading = false;
      state.isMetadataLoaded = true;
    })
    .addCase(loadMetadata.rejected, state => {
      state.isMetadataLoading = false;
    })
    .addCase(getPosesForRegion.pending, state => {
      state.isLoadingPosesForRegion = true;
    })
    .addCase(getPosesForRegion.fulfilled, (state, { payload, meta }) => {
      state.posesForRegion[meta.arg.regionId] = payload;
      state.isLoadingPosesForRegion = false;
    })
    .addCase(getPosesForRegion.rejected, state => {
      state.isLoadingPosesForRegion = false;
    })
    .addCase(getRegionTaxes.pending, state => {
      state.isLoadingRegionTaxes = true;
    })
    .addCase(getRegionTaxes.fulfilled, (state, { payload, meta }) => {
      state.regionTaxes[meta.arg.regionId] = payload;
      state.isLoadingRegionTaxes = false;
    })
    .addCase(getRegionTaxes.rejected, state => {
      state.isLoadingRegionTaxes = false;
    });
});
