import queryString from 'query-string';
import { identity, isArray, pickBy } from 'lodash';
import parse from 'date-fns/parse';
import { format } from 'utils/date';
import { history } from 'appHistory';
import { differenceInDays } from 'date-fns';
import { SEARCH_ORDERS_MAX_DATE_RANGE } from 'utils/constants';
import { SearchOrdersFilters } from './orders.types';
import { NullableDateRange } from 'types/NullableDateRange';

export const isInvalidDateRange = (dateRange: NullableDateRange) => {
  if (dateRange[1] === null || dateRange[0] === null) {
    return true;
  }
  return differenceInDays(dateRange[1], dateRange[0]) > SEARCH_ORDERS_MAX_DATE_RANGE;
};

export const deserializeQueryParams = (search: string) => {
  const queryParamsParsed = queryString.parse(search.replace(/\+/g, '%2B'), {
    arrayFormat: 'comma',
  });

  const params: Record<string, string | (string | null)[] | NullableDateRange | null> = pickBy(
    queryParamsParsed,
    identity
  );

  if (params.from && params.to) {
    params.searchRange = [
      typeof params.from === 'string' ? parse(params.from, 'dd.MM.yyyy', new Date()) : null,
      typeof params.to === 'string' ? parse(params.to, 'dd.MM.yyyy', new Date()) : null,
    ];

    if (isInvalidDateRange(params.searchRange)) {
      delete params.searchRange;
    }
  }

  if (params.orderStatuses && !isArray(params.orderStatuses)) {
    params.orderStatuses = [params.orderStatuses];
  }

  return params;
};

export const serializeQueryParams = (searchFilters: Partial<SearchOrdersFilters>) => {
  const nonEmptyFilters = pickBy(searchFilters, identity);

  if (Array.isArray(searchFilters.searchRange)) {
    delete nonEmptyFilters.searchRange;
    nonEmptyFilters.from = format(searchFilters.searchRange[0], 'dd.MM.yyyy');
    nonEmptyFilters.to = format(searchFilters.searchRange[1], 'dd.MM.yyyy');
  }

  return queryString.stringify(nonEmptyFilters, {
    arrayFormat: 'comma',
  });
};

export const getQueryParamsFromURL = () => {
  if (window.location.pathname.includes('/orders/search')) {
    return deserializeQueryParams(window.location.search);
  } else {
    return {};
  }
};

export const setQueryParamsToURL = (searchFilters: SearchOrdersFilters) => {
  history.push(`?${serializeQueryParams(searchFilters)}`);
};
