export const getStoredData = <T>(key: string, defaultValue?: T): T | undefined => {
  if (window.localStorage) {
    try {
      const storedSettingsRaw = window.localStorage.getItem(key);

      if (storedSettingsRaw) {
        return JSON.parse(storedSettingsRaw);
      }
    } catch (e) {
      console.error(e);

      return defaultValue;
    }
  }

  return defaultValue;
};

export const updateStoredData = <T>(key: string, value: T): void => {
  if (window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};
