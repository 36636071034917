import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PunchOrderFlowState } from '../punchOrderFlow.types';
import {
  addPositionNote,
  changeGroupPositionQuantity,
  changePositionAddonQuantity,
  clearPositions,
  fetchAvailableBrands,
  removeBrand,
  removeGroupPosition,
  removePositionAddon,
  resetBrand,
  resetKitchen,
  selectBrand,
  selectComboGroupPosition,
  selectPositionAddon,
  setActivePositionID,
  setAddonPriceOverride,
  setAllPositionsTouched,
  setComboGroupValidity,
  setCurrentBrand,
  setCustomizationCompleted,
  setExpectedTotal,
  setExpectedTotalDiscountName,
  setGroupPositionPriceOverride,
  setIsPriceDiscrepancy,
  setOrderNote,
  setPositionDetails,
  setPositionPriceOverride,
  setPositionsForCurrentBrand,
  setPositionsLoading,
  setSelectedPositionsForCurrentOrder,
  setShowCategories,
  setShowMenuExpanded,
  resetSelectedBrands,
  searchMenuItem,
  setActiveMenuPositionMasterId,
} from './orderBody.actions';

import {
  resetDiscounts,
  setIsPositionLevelDiscount,
  setPositionLevelDiscount,
  setOrderLevelDiscounts,
  clearAvailableDiscounts,
} from 'store/punchOrderFlowDiscounts/punchOrderFlowDiscounts.actions';

import { SelectedPositionAddon, SelectedPosition } from 'types/SelectedPosition';

import { MenuPositionType } from 'types/MenuPositionType';
import sortBy from 'lodash/sortBy';

import { isPriceChanged } from '../utils/isPriceChanged.util';
import { setSubItemQuantity } from '../utils/setSubItemQuantity.util';
import { createSelectedPositionPrice } from '../utils/createSelectedPositionPrice.util';
import { updatePositions } from '../utils/updatePositions.util';

const clearAllPositions = (state: PunchOrderFlowState) => {
  state.orderBody.synchronizedPositions = {};
  state.orderBody.selectedPositions = {};
  state.orderBody.activePositionID = null;
  state.orderBody.isPriceDiscrepancy = false;
  state.orderBody.expectedTotal = '';
  state.orderBody.expectedTotalDiscountName = null;
  state.priceDetails = null;
  state.isBasketSynchronized = true;
};

export const makeOrderBodyReducer = (builder: ActionReducerMapBuilder<PunchOrderFlowState>) =>
  builder
    .addCase(fetchAvailableBrands.pending, state => {
      state.orderBody.isLoadingAvailableBrands = true;
    })

    .addCase(fetchAvailableBrands.rejected, state => {
      state.orderBody.isLoadingAvailableBrands = false;
    })

    .addCase(fetchAvailableBrands.fulfilled, (state, { payload }) => {
      state.orderBody.isLoadingAvailableBrands = false;
      state.orderBody.availableBrands = payload;
    })

    .addCase(setCurrentBrand, (state, { payload }) => {
      state.orderBody.currentBrand = payload;
    })

    .addCase(resetBrand, state => {
      state.orderBody.currentBrand = null;
      state.orderBody.selectedBrands = [];
    })

    .addCase(selectBrand, (state, { payload }) => {
      const brand = state.orderBody.selectedBrands.find(brand => brand.id === payload.id);
      if (!brand) {
        state.orderBody.selectedBrands = [...state.orderBody.selectedBrands, payload];
      }
    })

    .addCase(resetSelectedBrands, state => {
      state.orderBody.selectedBrands = [];
    })

    .addCase(removeBrand, (state, { payload }) => {
      const selectedPositionsCloned = { ...state.orderBody.selectedPositions };

      if (payload) {
        delete selectedPositionsCloned[payload];
        const refreshedListOfBrands = state.orderBody.selectedBrands.filter(
          br => br.id !== payload
        );
        state.orderBody.selectedBrands = refreshedListOfBrands;

        if (refreshedListOfBrands.length) {
          const [firstBrand] = refreshedListOfBrands;
          state.orderBody.currentBrand = firstBrand;
        } else {
          clearAllPositions(state);
          state.orderBody.currentBrand = null;
        }
      }

      const isIncludingAnyBrand = Object.keys(selectedPositionsCloned).length > 0;

      state.orderBody.activePositionID = null;
      state.orderBody.selectedPositions = selectedPositionsCloned;
      state.priceDetails = isIncludingAnyBrand ? state.priceDetails : null;
    })

    .addCase(setIsPriceDiscrepancy, (state, { payload }) => {
      state.orderBody.isPriceDiscrepancy = payload;

      if (!payload) {
        state.isBasketSynchronized = false;
        state.isApplyingDiscounts = false;
        state.orderBody.synchronizedPositions = {};
        state.priceDetails = null;
      }
    })

    .addCase(setExpectedTotal, (state, { payload }) => {
      state.orderBody.expectedTotal = payload;
    })

    .addCase(setExpectedTotalDiscountName, (state, { payload }) => {
      state.orderBody.expectedTotalDiscountName = payload;
    })

    .addCase(clearAvailableDiscounts, state => {
      state.orderBody.expectedTotalDiscountName = null;
    })

    .addCase(searchMenuItem, (state, { payload }) => {
      state.orderBody.searchMenuItemValue = payload;
      const { positions, showMenuExpanded: isMenuExpanded } = state.orderBody;
      if (!payload.length) {
        state.orderBody.searchMenuItemResult = isMenuExpanded ? positions : [];
      } else {
        const positionsMatchingQuery = positions.filter(position => {
          return (
            position.name.toLowerCase().includes(payload.toLowerCase()) ||
            position.description.toLowerCase().includes(payload.toLowerCase())
          );
        });
        state.orderBody.searchMenuItemResult = positionsMatchingQuery;
      }
    })

    .addCase(resetDiscounts, state => {
      state.isBasketSynchronized = false;
      state.isApplyingDiscounts = false;
      state.orderBody.synchronizedPositions = {};
      state.priceDetails = null;
    })

    .addCase(setOrderLevelDiscounts, state => {
      state.isBasketSynchronized = false;
      state.isApplyingDiscounts = false;
      state.orderBody.synchronizedPositions = {};
      state.priceDetails = null;
    })

    .addCase(setPositionLevelDiscount, state => {
      state.isBasketSynchronized = false;
      state.isApplyingDiscounts = false;
      state.orderBody.synchronizedPositions = {};
      state.priceDetails = null;
    })

    .addCase(setIsPositionLevelDiscount, state => {
      state.isBasketSynchronized = false;
      state.isApplyingDiscounts = false;
      state.orderBody.synchronizedPositions = {};
      state.priceDetails = null;
    })

    .addCase(setGroupPositionPriceOverride, (state, { payload }) => {
      const { brandId, elementIndex, comboIndex, groupId, price } = payload;
      const { groups } = state.orderBody.selectedPositions[brandId][comboIndex];
      const groupPosition = groups[groupId].positions[elementIndex];

      if (isPriceChanged(groupPosition.priceOverride, price)) {
        state.isBasketSynchronized = false;
        state.orderBody.synchronizedPositions = {};
      }

      groupPosition.priceOverride = {
        ...groupPosition.priceOverride,
        ...price,
      };
    })

    .addCase(setPositionPriceOverride, (state, { payload }) => {
      const { brandId, index, price } = payload;
      const position = state.orderBody.selectedPositions[brandId][index];

      if (isPriceChanged(position.priceOverride, price)) {
        state.isBasketSynchronized = false;
        state.orderBody.synchronizedPositions = {};
      }

      position.priceOverride = {
        ...position.priceOverride,
        ...price,
      };
    })

    .addCase(setAddonPriceOverride, (state, { payload }) => {
      const { brandId, positionIndex, addonIndex, price } = payload;
      const position = state.orderBody.selectedPositions[brandId][positionIndex];
      const addon = position.addons[addonIndex];

      if (isPriceChanged(addon.priceOverride, price)) {
        state.isBasketSynchronized = false;
        state.orderBody.synchronizedPositions = {};
      }

      addon.priceOverride = {
        ...addon.priceOverride,
        ...price,
      };
    })

    .addCase(setShowMenuExpanded, (state, { payload }) => {
      state.orderBody.showMenuExpanded = payload;
    })

    .addCase(setShowCategories, (state, { payload }) => {
      state.orderBody.showCategories = payload;
    })

    .addCase(clearPositions, state => {
      clearAllPositions(state);
    })

    .addCase(resetKitchen, state => {
      clearAllPositions(state);
      state.resolvedKitchen = null;
      state.orderBody.selectedBrands = [];
      state.orderBody.currentBrand = null;
    })

    .addCase(setCustomizationCompleted, (state, { payload }) => {
      const { brandId, positionIndex } = payload;
      const selectedPosition = state.orderBody.selectedPositions[brandId][positionIndex];
      const groups = selectedPosition.groups || {};
      const groupsIDs = Object.keys(groups);
      selectedPosition.isCustomizationCompleted = true;
      selectedPosition.touchedGroups = groupsIDs;
    })

    .addCase(setComboGroupValidity, (state, { payload }) => {
      const { brandId, positionIndex, groupId, isValid } = payload;
      state.orderBody.selectedPositions[brandId][positionIndex].groupsValidity[groupId] = isValid;
    })

    .addCase(setAllPositionsTouched, state => {
      const newSelectedPositions: Record<string, SelectedPosition[]> = {};

      for (const [key, value] of Object.entries(state.orderBody.selectedPositions)) {
        newSelectedPositions[key] = value.map(position => ({
          ...position,
          isCustomizationCompleted: true,
          touchedGroups: Object.keys(position.groups),
        }));
      }

      state.orderBody.selectedPositions = newSelectedPositions;
    })

    .addCase(setOrderNote, (state, { payload }) => {
      state.orderBody.orderNote = payload;
    })

    .addCase(setActivePositionID, (state, { payload }) => {
      state.orderBody.activePositionID = payload.activePositionID;
      state.orderBody.activePositionIndex = payload.activePositionIndex;
    })

    .addCase(addPositionNote, (state, { payload }) => {
      const { brandId, positionIndex, note } = payload;
      state.orderBody.selectedPositions[brandId][positionIndex].note = note;
    })

    .addCase(removeGroupPosition, (state, { payload }) => {
      const { brandId, elementIndex, comboIndex, groupId } = payload;
      const { groups } = state.orderBody.selectedPositions[brandId][comboIndex];

      state.isBasketSynchronized = false;
      state.orderBody.synchronizedPositions = {};
      groups[groupId].positions.splice(elementIndex, 1);

      if (groups[groupId].positions.length === 0) {
        delete groups[groupId];
      }
    })

    .addCase(changePositionAddonQuantity, (state, { payload }) => {
      const { brandId, positionIndex, addonIndex, quantity } = payload;

      const position = state.orderBody.selectedPositions[brandId][positionIndex];
      const taxRate = state.orderSource.taxRate?.value || 0;

      state.isBasketSynchronized = false;
      state.orderBody.synchronizedPositions = {};
      position.addons[addonIndex] = setSubItemQuantity(
        position.addons[addonIndex],
        quantity,
        position.quantity,
        taxRate
      );
    })

    .addCase(removePositionAddon, (state, { payload }) => {
      const { brandId, positionIndex, addonIndex } = payload;
      const { addons } = state.orderBody.selectedPositions[brandId][positionIndex];

      state.isBasketSynchronized = false;
      state.orderBody.synchronizedPositions = {};
      addons.splice(addonIndex, 1);
    })

    .addCase(changeGroupPositionQuantity, (state, { payload }) => {
      const { brandId, elementIndex, comboIndex, groupId, quantity } = payload;
      const { groups, quantity: comboQuantity } =
        state.orderBody.selectedPositions[brandId][comboIndex];
      const { positions } = groups[groupId];
      const taxRate = state.orderSource.taxRate?.value || 0;

      state.isBasketSynchronized = false;
      state.orderBody.synchronizedPositions = {};
      if (typeof elementIndex !== 'undefined') {
        positions[elementIndex] = setSubItemQuantity(
          positions[elementIndex],
          quantity,
          comboQuantity,
          taxRate
        );
      }
    })

    .addCase(setPositionDetails, (state, { payload }) => {
      const details = {
        ...payload.details,
        addons: sortBy(payload.details.addons, [addon => addon.name.toLowerCase()]),
        groups:
          'groups' in payload.details
            ? sortBy(payload.details.groups, 'order').map(group => ({
                ...group,
                menuItems: sortBy(group.menuItems, [item => item.name.toLowerCase()]),
                addons: sortBy(group.addons, [addon => addon.name.toLowerCase()]),
              }))
            : [],
      };
      state.orderBody.positionsDetails[payload.masterId] = details;
    })

    .addCase(selectComboGroupPosition, (state, { payload }) => {
      const { brandId, comboIndex, groupId, element } = payload;
      const selectedPosition = state.orderBody.selectedPositions[brandId][comboIndex];
      const { groups, quantity } = selectedPosition;
      const menuNetPrice = (element.price?.net ?? 0) * quantity;
      const taxRate = state.orderSource.taxRate?.value || 0;

      state.isBasketSynchronized = false;
      state.orderBody.synchronizedPositions = {};

      if (!groups[groupId]) {
        groups[groupId] = {
          positions: [],
        };
      }

      groups[groupId].positions = updatePositions(
        {
          ...element,
          priceOverride: createSelectedPositionPrice({ menuNetPrice, taxRate }),
        },
        taxRate,
        quantity,
        groups[groupId].positions
      );
    })

    .addCase(selectPositionAddon, (state, { payload }) => {
      const { brandId, positionIndex, addon } = payload;
      const positions = state.orderBody.selectedPositions[brandId];
      const { quantity } = positions[positionIndex];
      const menuNetPrice = (addon.price?.net ?? 0) * quantity;
      const taxRate = state.orderSource.taxRate?.value || 0;

      state.isBasketSynchronized = false;
      state.orderBody.synchronizedPositions = {};

      positions[positionIndex].addons = updatePositions<Omit<SelectedPositionAddon, 'quantity'>>(
        {
          ...addon,
          priceOverride: createSelectedPositionPrice({ menuNetPrice, taxRate }),
          type: MenuPositionType.ADDON,
        },
        taxRate,
        quantity,
        positions[positionIndex].addons
      );
    })

    .addCase(setPositionsLoading, (state, { payload }) => {
      state.orderBody.isLoadingPositions = payload;
    })

    .addCase(setPositionsForCurrentBrand, (state, { payload }) => {
      state.orderBody.positions = sortBy(payload, [position => position.name.toLowerCase()]);
    })

    .addCase(setSelectedPositionsForCurrentOrder, (state, { payload }) => {
      state.orderBody.synchronizedPositions = {};
      state.orderBody.selectedPositions[payload.currentBrandId] = payload.selectedPositions;
      state.isBasketSynchronized = false;
    })

    .addCase(setActiveMenuPositionMasterId, (state, { payload }) => {
      state.orderBody.activeMenuPositionMasterId = payload.id;
    });
