import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';

import { KitopiLogo } from '../KitopiLogo/KitopiLogo';

export const SplashScreenRoot = styled('div')({
  position: 'relative',
  textAlign: 'center',
});

export const StyledPaper = styled(Paper)(({ theme }) => ({
  '&&': {
    backgroundColor: theme.palette.background.default,
    width: '300px',
    margin: '80px auto',
  },
}));

export const StyledKitopiLogo = styled(KitopiLogo)({
  width: '160px',
});

export const StyledTypography = styled(Typography)({
  '&&': {
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
});
