import { ReactNode } from 'react';
import { ButtonColor } from './ConfirmationDialog.types';
import { Button, Dialog, Typography } from '@kitopi-com/ui';
import { noop } from 'lodash';
import { Box } from '@mui/material';

interface ConfirmationDialogProps {
  isVisible: boolean;
  title?: string;
  message?: ReactNode;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  confirmButtonColor?: ButtonColor;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog = ({
  isVisible,
  title = 'Confirm action',
  message = 'Are you sure you want to perform this action?',
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'Confirm',
  confirmButtonColor = 'primary',
  onCancel,
  onConfirm,
}: ConfirmationDialogProps) => (
  <Dialog onClose={noop} open={isVisible} data-testid="confirmation-dialog">
    <Dialog.Title>{title}</Dialog.Title>

    <Dialog.Content>
      <Box maxWidth={600}>
        <Typography variant="H3">{message}</Typography>
      </Box>
    </Dialog.Content>

    <Dialog.Actions>
      <Button color="primary" variant="outlined" onClick={onCancel}>
        {cancelButtonLabel}
      </Button>
      <Button color={confirmButtonColor} onClick={onConfirm} autoFocus>
        {confirmButtonLabel}
      </Button>
    </Dialog.Actions>
  </Dialog>
);
