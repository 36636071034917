import { darkThemeConfig, Theme } from '@kitopi-com/ui';
import { createTheme } from '@mui/system';
import { AppTheme } from 'types/AppTheme';

export const darkTheme = createTheme({
  ...darkThemeConfig,
  components: {
    ...darkThemeConfig.components,
    Typography: {
      baseColor: darkThemeConfig.palette.white,
      secondaryBaseColor: darkThemeConfig.palette.grey[100],
    },
    Body: {
      backgroundColor: 'rgb(47, 56, 64)',
    },
    TopBar: {
      backgroundColor: '#282D36',
    },
    Drawer: {
      backgroundColor: '#282D36',
    },
    MenuPosition: {
      default: {
        borderColor: '#546B7E',
        backgroundColor: darkThemeConfig.palette.grey[500],
      },
    },
    PunchingOrderProgressIndicator: {
      backgroundColor: darkThemeConfig.palette.grey[500],
      expandButton: {
        backgroundColor: '#494f5a',
        color: darkThemeConfig.palette.white,
      },
      stepLink: {
        backgroundColor: '#343a45',
        color: darkThemeConfig.palette.grey[300],
        enabledBackgroundColor: '#282D36',
        enabledColor: darkThemeConfig.palette.white,
      },
    },
  },
} satisfies AppTheme) as Theme;
