import { getAllPages, api } from 'api';
import { Url } from 'utils/constants';
import countryPrefixes from 'assets/data/country-prefixes.json';
import { List } from 'types/List';
import { Region } from 'types/Region';
import { Country } from 'types/Country';
import { DeliveryPartnerDetails } from 'types/DeliveryPartnerDetails';
import { BrandDetails } from 'types/BrandDetails';
import { CountryPrefix } from 'types/CountryPrefix';
import { POSDetails } from 'types/POSDetails';
import { RegionTaxRate } from 'types/RegionTaxRate';
import { PointOfSaleListEntry } from 'types/PointOfSaleListEntry';

export const getRegions = (): Promise<List<Region>> =>
  getAllPages(`${Url.METADATA_API_URL}/regions`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });

export const getCountries = (): Promise<List<Country>> =>
  getAllPages(`${Url.METADATA_API_URL}/countries`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });

export const getDeliveryPartners = (): Promise<List<DeliveryPartnerDetails>> =>
  getAllPages(`${Url.METADATA_API_URL}/delivery-partners`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });

export const getBrands = (): Promise<List<BrandDetails>> =>
  getAllPages(`${Url.MENU_MANAGEMENT_API_URL}/brands`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });

export const getCountryPrefixes = (): Promise<Record<string, CountryPrefix>> =>
  Promise.resolve(countryPrefixes);

export const getPointsOfSale = (): Promise<List<PointOfSaleListEntry>> =>
  getAllPages(`${Url.METADATA_API_URL}/point-of-sales/list`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });

export const getPosesForRegion = ({ regionId }: { regionId: string }): Promise<List<POSDetails>> =>
  getAllPages(`${Url.METADATA_API_URL}/regions/${regionId}/point-of-sales`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });

export const getPosDetails = ({ pointOfSaleId }: { pointOfSaleId: string }): Promise<POSDetails> =>
  api.get(`${Url.METADATA_API_URL}/point-of-sales/${pointOfSaleId}/details`);

export const getRegionTaxes = ({ regionId }: { regionId: string }): Promise<List<RegionTaxRate>> =>
  getAllPages(`${Url.METADATA_API_URL}/regions/${regionId}/taxes`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });
