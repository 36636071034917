import sortBy from 'lodash/sortBy';
import { setSubItemQuantity } from 'store/punchOrderFlow/utils/setSubItemQuantity.util';

import {
  SelectedPositionAddon,
  SelectedGroupMenuItem,
  SelectedGroupAddon,
} from 'types/SelectedPosition';

export const updatePositions = <
  T extends
    | Omit<SelectedPositionAddon, 'quantity'>
    | Omit<SelectedGroupMenuItem, 'quantity'>
    | Omit<SelectedGroupAddon, 'quantity'>
>(
  position: T,
  taxRate: number,
  parentQuantity: number,
  positions?: (T & { quantity: number })[]
): (T & { quantity: number })[] => {
  if (!positions) {
    return [{ ...position, quantity: 1 }];
  }

  const findCurrentElement = (el: T) => {
    if ((position as SelectedGroupMenuItem).variantValueId) {
      return (
        el.id === position.id &&
        (el as SelectedGroupMenuItem).variantValueId ===
          (position as SelectedGroupMenuItem).variantValueId
      );
    } else {
      return el.id === position.id;
    }
  };

  const currentIndex = positions.findIndex(findCurrentElement);

  if (currentIndex < 0) {
    const allPositions = [...positions, { ...position, quantity: 1 }];
    return sortBy(allPositions, ['name']);
  }

  const currentElement = positions[currentIndex];

  positions[currentIndex] = setSubItemQuantity(
    positions[currentIndex],
    (currentElement?.quantity ?? 1) + 1,
    parentQuantity ?? 1,
    taxRate
  );

  return positions;
};
