import { initialize } from '@kitopi-com/auth';
import { useState } from 'react';
import { authConfig as iframeAuthConfig } from './auth.config';
import { authConfig as appAuthConfig } from 'auth.config';
import { DASHBOARD_URL } from 'utils/constants';
import { checkIfRequestStorageAccessEnabled } from '../utils/checkIfRequestStorageAccessEnabled.util';

interface UseCookieAuth {
  setIsLoggedIn?: () => void;
}

export const useCookieAuth = ({ setIsLoggedIn }: UseCookieAuth) => {
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [isAuthorizationPending, setIsAuthorizationPending] = useState(true);
  const [isRedirectedToDashboard, setIsRedirectedToDashboard] = useState(false);
  const isRequestStorageAccessEnabled = checkIfRequestStorageAccessEnabled();
  const redirectToLogoutPage = () => {
    window.open(
      `${DASHBOARD_URL}/logout?redirectTo=${DASHBOARD_URL}/salesforce-info`,
      'parent',
      'width=700,height=700'
    );
  };

  const redirectToLoginPage = () => {
    window.open(
      `${DASHBOARD_URL}/login?redirectTo=${DASHBOARD_URL}/salesforce-info`,
      'parent',
      'width=700,height=700'
    );
  };

  const authInitialize = async (
    isIframeAuth: boolean | undefined,
    isInitializedByUser: boolean
  ) => {
    await initialize(isIframeAuth ? iframeAuthConfig : appAuthConfig)
      .then(() => {
        setIsAuthInitialized(true);
        setIsLoggedIn?.();
      })
      .catch(() => {
        setIsLoggedIn?.();
        if (isInitializedByUser) {
          redirectToLoginPage();
          setIsRedirectedToDashboard(true);
        }
      })
      .finally(() => {
        setIsAuthorizationPending(false);
      });
  };

  const access = async ({
    isInitializedByUser,
    isIframeAuth,
  }: {
    isInitializedByUser: boolean;
    isIframeAuth?: boolean;
  }) => {
    if (isRequestStorageAccessEnabled) {
      await document.requestStorageAccess().then(
        async () => {
          await authInitialize(isIframeAuth, isInitializedByUser);
        },
        () => {
          setIsAuthorizationPending(false);
          console.log('localStorage access denied');
        }
      );
    } else {
      await authInitialize(isIframeAuth, isInitializedByUser);
    }
  };

  return {
    access,
    isAuthInitialized,
    isRedirectedToDashboard,
    isAuthorizationPending,
    redirectToLogoutPage,
    redirectToLoginPage,
  };
};
