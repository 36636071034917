import { createAction } from '@reduxjs/toolkit';
import { Customer } from 'types/Customer';

export const setSelectedCustomerAddressId = createAction<string>(
  'punchOrderFlowNext/setSelectedCustomerAddressId'
);

export const setCoordinatesMatchingError = createAction<string | undefined>(
  'punchOrderFlowNext/setCoordinatesMatchingError'
);

export const setSelectedCustomer = createAction<Customer>('punchOrderFlowNext/setSelectedCustomer');

export const resetSelectedCustomer = createAction('punchOrderFlowNext/resetSelectedCustomer');

export const setCustomerDefaults = createAction('punchOrderFlowNext/setCustomerDefaults');

export const setIsCustomerEditModeEnabled = createAction<boolean>(
  'punchOrderFlowNext/setIsCustomerEditModeEnabled'
);
