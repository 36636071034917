import { useEffect, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMetadata } from 'store/metadata/metadata.actions';
import { AppWrapperContainer } from './styles';
import { AppDispatch } from 'store';
import { isMetadataLoadedSelector } from 'store/metadata/metadata.selectors';
import { SplashScreen } from 'components/common/SplashScreen/SplashScreen';

interface AppWrapperProps {
  isAuthInitialized: boolean;
}

export const AppWrapper = ({ children, isAuthInitialized }: PropsWithChildren<AppWrapperProps>) => {
  const dispatch = useDispatch<AppDispatch>();

  const isMetadataLoaded = useSelector(isMetadataLoadedSelector);

  useEffect(() => {
    if (isAuthInitialized) {
      dispatch(loadMetadata());
    }
  }, [dispatch, isAuthInitialized]);

  if (!isAuthInitialized || !isMetadataLoaded) {
    return <SplashScreen text="Customer Experience Center" />;
  }

  return <AppWrapperContainer>{children}</AppWrapperContainer>;
};
