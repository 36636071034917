import { Wallet } from 'types/Wallet';
import { List } from 'types/List';
import { api } from 'api';
import { Url } from 'utils/constants';
import { WalletAction } from 'types/WalletAction';
import { ChangeWalletPointsAmount } from 'types/ChangeWalletPointsAmount';

export const loadWallets = ({
  phrase,
  perPage,
  page,
  signal,
  state,
}: {
  phrase?: string;
  perPage?: number;
  signal?: AbortSignal;
  page?: number;
  state?: string;
}): Promise<List<Wallet>> =>
  api.get(`${Url.PERSONALIZATION_API_URL}/wallets`, {
    signal,
    params: {
      phrase,
      perPage,
      page,
      state,
    },
  });

export const getWalletDetails = ({ walletId }: { walletId: string }): Promise<Wallet> =>
  api.get(`${Url.PERSONALIZATION_API_URL}/wallets/${walletId}`);

export const getWalletActions = ({
  perPage,
  page,
  walletId,
  signal,
}: {
  perPage?: number;
  page?: number;
  walletId: string;
  signal?: AbortSignal;
}): Promise<List<WalletAction>> =>
  api.get(`${Url.PERSONALIZATION_API_URL}/wallets/${walletId}/actions`, {
    signal,
    params: {
      perPage,
      page,
    },
  });

export const updateWallet = (
  wallet: { walletId: string } & ChangeWalletPointsAmount
): Promise<void> => {
  const { walletId, ...body } = wallet;
  return api.put(`${Url.PERSONALIZATION_API_URL}/wallets/${walletId}/amounts`, body);
};

interface GetWalletPointsAmountDueForTheOrderProps {
  orderId: string;
  orderTotal: number;
  orderDeliveryFee: number;
  isPriceAdjusted: boolean;
}

export const getWalletPointsAmountDueForTheOrder = ({
  orderId,
  orderTotal,
  orderDeliveryFee,
  isPriceAdjusted,
}: GetWalletPointsAmountDueForTheOrderProps): Promise<{ walletAmount: number }> =>
  api.post(
    `${Url.PERSONALIZATION_API_URL}/wallet-orders/${orderId}/modifications`,
    {
      orderTotal,
      deliveryFee: orderDeliveryFee,
      priceAdjusted: isPriceAdjusted,
    },
    {
      hasErrorDialog: false,
      hasConnectivityErrorDialog: false,
    }
  );
