import {
  SelectedGroup,
  SelectedGroupAddon,
  SelectedGroupMenuItem,
  SelectedPositionAddon,
} from 'types/SelectedPosition';
import { createSelectedPositionPrice } from './createSelectedPositionPrice.util';

export const setSubItemQuantity = <
  T extends
    | Omit<SelectedPositionAddon, 'quantity'>
    | Omit<SelectedGroupMenuItem, 'quantity'>
    | Omit<SelectedGroupAddon, 'quantity'>
    | SelectedGroup
>(
  subItem: T,
  quantity: number,
  parentQuantity: number,
  taxRate: number
): T => {
  if ('price' in subItem && 'priceOverride' in subItem) {
    const menuNetPrice = (subItem?.price?.net ?? 0) * parentQuantity * quantity;
    const hasChangedPrice = subItem.priceOverride.isEdited;
    return {
      ...subItem,
      quantity,
      priceOverride: {
        ...(hasChangedPrice
          ? subItem.priceOverride
          : createSelectedPositionPrice({ menuNetPrice, taxRate })),
        menuNetPrice,
      },
    };
  }
  return {
    ...subItem,
    quantity,
  };
};
