import { alpha, createTheme, darken, PaletteColorOptions } from '@mui/material/styles';
import { oldAppColors } from 'utils/theme/oldAppColors';

declare module '@mui/material/styles' {
  interface CustomPalette {
    default: PaletteColorOptions;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    default: true;
  }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

export const oldTheme = createTheme({
  shape: {
    borderRadius: 0,
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#323b44',
      paper: '#38434e',
    },
    primary: {
      main: oldAppColors.primary,
    },
    secondary: {
      main: oldAppColors.secondary,
    },
    default: createColor(oldAppColors.defaultButton),
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: oldAppColors.inputBackground,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #4d5c69',
          backgroundColor: '#303b46',
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        notchedOutline: {
          borderColor: oldAppColors.inputBackground,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            textTransform: 'uppercase',
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-shrink.MuiInputLabel-outlined': {
            color: '#9fa4a7',
            textTransform: 'uppercase',
            transform: 'translate(0px, -2rem)',
          },
        },
      },
      defaultProps: {
        margin: 'dense',
        shrink: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#9fa4a7',
          textTransform: 'uppercase',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginDense: {
          marginTop: '2rem',
          marginBottom: '4px',
        },
      },
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
      defaultProps: {
        color: 'primary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: 'rgba(255, 152, 0, 0.10)',
        },
        standardError: {
          backgroundColor: 'rgba(255, 0, 0, 0.10)',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'default' },
          style: {
            color: oldAppColors.white,
            backgroundColor: oldAppColors.defaultButton,
            '&:hover': {
              backgroundColor: darken(oldAppColors.defaultButton, 0.1),
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'default' },
          style: {
            color: oldAppColors.white,
            borderColor: alpha(oldAppColors.white, 0.21),
            '&:hover': {
              color: oldAppColors.white,
              backgroundColor: alpha(oldAppColors.white, 0.08),
              borderColor: alpha(oldAppColors.white, 0.23),
            },
          },
        },
        {
          props: { variant: 'text', color: 'default' },
          style: {
            color: oldAppColors.gray,
            '&:hover': {
              color: darken(oldAppColors.gray, 0.1),
              backgroundColor: oldAppColors.defaultButton,
            },
          },
        },
      ],
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
        color: 'default',
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          fontWeight: 400,
          fontSize: '1.1rem',
        },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: 'unset' } },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAccordion: {
      styleOverrides: { root: { background: oldAppColors.darkPanelBackground } },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          backgroundColor: '#303b46',
          borderBottom: '1px solid #4d5c69',
        },
      },
    },
  },
});
