import { forEach } from 'lodash';
import { DeliveryType } from 'types/DeliveryType';
import { OrderType } from 'types/OrderType';
import { formatDateForApi } from 'utils/date';
import { MenuPositionType } from 'types/MenuPositionType';
import { CreateOrderPosition } from 'types/CreateOrderPosition';
import { PunchOrderFlowState } from 'store/punchOrderFlow/punchOrderFlow.types';
import { PunchOrderFlowDiscountsState } from 'store/punchOrderFlowDiscounts/punchOrderFlowDiscounts.types';
import { createPriceAdjustments } from 'utils/orders';
import { SettingsState } from 'store/settings/settings.reducer';
import { BaseCreateOrder } from 'types/BaseCreateOrder';
import { FinalDeliveryType } from 'types/FinalDeliveryType';
import { getAnySupportedPaymentMethod } from 'utils/paymentMethod';
import { getOrderLevelPositionDiscount } from './getOrderLevelPositionDiscount.util';
import { SelectedPositionAddon } from 'types/SelectedPosition';
import { serializeSelectedPositionPriceDivideByQuantity } from './serializeSelectedPositionPriceDivideByQuantity.util';
import { serializeSelectedPositionPrice } from './serializeSelectedPositionPrice.util';
import { serializeComboGroups } from './serializeComboGroups.util';

const getAddons = (addons: SelectedPositionAddon[], isNet: boolean, parentQuantity: number) =>
  addons
    .filter(addon => !!addon.quantity)
    .map(({ id, quantity, priceOverride }) => {
      return {
        addonId: id,
        quantity,
        priceOverride: serializeSelectedPositionPriceDivideByQuantity(
          priceOverride,
          isNet,
          quantity,
          parentQuantity
        ),
      };
    });

export const getBaseOrderRequestBody = (
  state: {
    punchOrderFlow: PunchOrderFlowState;
    punchOrderFlowDiscounts: PunchOrderFlowDiscountsState;
    settings: SettingsState;
  },
  orderCompositionDurationInSeconds?: number
): BaseCreateOrder | undefined => {
  const { isPunchingOrderGrossPrice } = state.settings;
  const isNet = !isPunchingOrderGrossPrice;
  const punchOrderFlowState = state.punchOrderFlow;
  const { orderType, deliveryType, selectedPos } = punchOrderFlowState.orderSource;
  const expectedPickUpTime = punchOrderFlowState.orderDetails?.expectedPickUpTime;
  const {
    includeCutlery: isIncludeCutlery,
    paymentMethod,
    deliveryPartnerId,
    contactNumber,
  } = punchOrderFlowState.orderDetails;
  const {
    selectedPositions,
    orderNote,
    isPriceDiscrepancy,
    expectedTotal,
    expectedTotalDiscountName,
  } = punchOrderFlowState.orderBody;
  const sourceOrderFriendlyId = punchOrderFlowState.orderSource.sourceOrderID || null;
  const customDeliveryFee = punchOrderFlowState.customDeliveryFee ?? null;

  const { selectedOrderLevelDiscounts, selectedPositionLevelDiscounts, selectedDeliveryDiscount } =
    state.punchOrderFlowDiscounts;

  if (!selectedPos || typeof punchOrderFlowState.resolvedKitchen === 'string') {
    return undefined;
  }

  const hasGeneralDiscount =
    selectedOrderLevelDiscounts.length === 1 &&
    (selectedOrderLevelDiscounts[0].generalDiscountDetails ||
      selectedOrderLevelDiscounts[0].newEatopiAccountDiscountDetails);

  const orderPositions: CreateOrderPosition[] = [];

  forEach(selectedPositions, (positions, brandID) => {
    forEach(positions, position => {
      const discountId =
        selectedPositionLevelDiscounts[position.id]?.id ??
        getOrderLevelPositionDiscount(selectedOrderLevelDiscounts, position)?.id;

      const { masterId, note, quantity, priceOverride, variantValueId, addons } = position;

      if (quantity === 0) {
        return;
      }

      if (position.type === MenuPositionType.ITEM) {
        const item = {
          menuItemId: masterId,
          variantValueId,
          addons: getAddons(addons, isNet, quantity),
        };

        orderPositions.push({
          brandId: brandID,
          note,
          quantity,
          item,
          discountId,
          priceOverride: serializeSelectedPositionPrice(priceOverride, isNet, quantity, 1),
        });
      } else {
        const combo = {
          menuComboId: masterId,
          comboGroups: serializeComboGroups(position.groups, isNet, quantity),
          addons: getAddons(position.addons, isNet, quantity),
        };

        orderPositions.push({
          brandId: brandID,
          note,
          quantity,
          combo,
          discountId,
          priceOverride: serializeSelectedPositionPrice(priceOverride, isNet, quantity, 1),
        });
      }
    });
  });

  const kitchenId = punchOrderFlowState.resolvedKitchen?.id;
  const isKitopiOwnDelivery =
    orderType === OrderType.DELIVERY && deliveryType === DeliveryType.KITOPI_DELIVERY;

  if (!kitchenId) {
    throw new Error('kitchenId is not defined');
  }

  return {
    expectedPickUpTime: expectedPickUpTime ? formatDateForApi(expectedPickUpTime) : undefined,
    includeCutlery: isIncludeCutlery,
    note: orderNote,
    orderType,
    paymentMethod: paymentMethod || getAnySupportedPaymentMethod(selectedPos),
    sourceOrderFriendlyId: sourceOrderFriendlyId ?? undefined,
    orderPositions,
    deliveryType: deliveryType !== FinalDeliveryType.NO_DELIVERY ? deliveryType : undefined,
    deliveryPartnerId: isKitopiOwnDelivery && deliveryPartnerId ? deliveryPartnerId : undefined,
    kitchenId,
    contactNumber: contactNumber || null,
    customDeliveryFee: customDeliveryFee !== null ? Number(customDeliveryFee) : undefined,
    orderCompositionDurationInSeconds,
    discountId: hasGeneralDiscount ? selectedOrderLevelDiscounts[0].id : undefined,
    deliveryDiscountId: selectedDeliveryDiscount?.id,
    priceAdjustments: createPriceAdjustments({
      isPriceDiscrepancy,
      expectedTotal,
      expectedTotalDiscountName,
    }),
  };
};
