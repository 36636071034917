import { groupBy, sortBy } from 'lodash';
import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { PunchOrderFlowState } from './punchOrderFlow.types';
import { OrderType } from 'types/OrderType';
import { DeliveryType } from 'types/DeliveryType';

import { makeOrderBodyReducer } from './orderBody/orderBody.reducer';
import { makeOrderDetailsReducer } from './orderDetails/orderDetails.reducer';
import { makeOrderSourceReducer } from './orderSource/orderSource.reducer';
import { makeSelectedCustomerReducer } from './selectedCustomer/selectedCustomer.reducer';
import {
  applyDiscounts,
  fetchBranchesForPOS,
  getDeliveryAreasForBranch,
  getDeliveryAreasForRegion,
  punchOrder,
  resetPunchOrderFlow,
  resolveKitchen,
  setCustomDeliveryFee,
  setDefaultKitchenForBrandAndDeliveryArea,
  setInitialState,
} from './punchOrderFlow.actions';

export const punchOrderFlowState: PunchOrderFlowState = {
  orderSource: {
    draftId: undefined,
    compositionStartTime: null,
    phoneNumber: '',
    sourceOrderID: '',
    selectedPos: undefined,
    selectedRegion: undefined,
    orderType: OrderType.DELIVERY,
    deliveryType: DeliveryType.DEFAULT,
    currency: null,
    isKitchensForRegionLoading: false,
    availableKitchensPerRegion: [],
    isKitchenDetailsLoading: false,
    potentialDuplicates: [],
    isCreatingOrderDraft: false,
    isLoadingPotentialDuplicates: false,
    selectedBranch: undefined,
    selectedKitchen: undefined,
  },
  orderDetails: {
    contactNumber: null,
    includeCutlery: true,
    internalNotes: '',
    paymentMethod: '',
    brandDeliveryPartners: [],
    kitchenDeliveryPartners: [],
    deliveryPartnerId: null,
    expectedPickUpTime: null,
    useKitchenTimezone: true,
  },
  customDeliveryFee: null,
  selectedCustomer: null,
  selectedCustomerAddressId: null,
  coordinatesMatchingError: undefined,
  availableDeliveryAreas: [],
  orderBody: {
    orderNote: '',
    availableBrands: [],
    currentBrand: null,
    activePositionID: null,
    activePositionIndex: null,
    selectedPositions: {},
    selectedBrands: [],
    synchronizedPositions: {},
    positions: [],
    positionsDetails: {},
    showMenuExpanded: false,
    showCategories: false,
    isLoadingAvailableBrands: false,
    isPriceDiscrepancy: false,
    expectedTotal: '',
    expectedTotalDiscountName: null,
    isLoadingPositions: false,
    searchMenuItemResult: [],
    searchMenuItemValue: '',
    activeMenuPositionMasterId: null,
  },
  poses: [],
  isPosesLoading: false,
  resolvedKitchen: null,
  isKitchenResolving: false,
  isOrderPunching: false,
  availableBranchesForPOS: [],
  isApplyingDiscounts: false,
  priceDetails: null,
  isBasketSynchronized: true,
  isCustomerEditModeEnabled: false,
  isSourceOrderIdRequired: false,
  initialStateReloaded: false,
};

const makeMainReducer = (builder: ActionReducerMapBuilder<PunchOrderFlowState>) =>
  builder
    .addCase(resolveKitchen, (state, { payload }) => {
      state.isKitchenResolving = false;
      state.resolvedKitchen = payload;
    })

    .addCase(setDefaultKitchenForBrandAndDeliveryArea, state => {
      state.isKitchenResolving = true;
      state.resolvedKitchen = punchOrderFlowState.resolvedKitchen;
    })

    .addCase(getDeliveryAreasForBranch.fulfilled, (state, { payload }) => {
      state.availableDeliveryAreas = sortBy(payload.elements, 'name');
    })

    .addCase(getDeliveryAreasForRegion.fulfilled, (state, { payload }) => {
      state.availableDeliveryAreas = sortBy(payload.elements, 'name');
    })

    .addCase(applyDiscounts.pending, state => {
      state.isBasketSynchronized = true;
      state.isApplyingDiscounts = true;
    })

    .addCase(applyDiscounts.rejected, state => {
      state.isApplyingDiscounts = false;
    })

    .addCase(applyDiscounts.fulfilled, (state, { payload }) => {
      state.isApplyingDiscounts = false;
      state.priceDetails = payload.orderPriceDetails;
      state.orderBody.synchronizedPositions = groupBy(payload.orderPositions, 'brandId');
    })

    .addCase(punchOrder.pending, state => {
      state.isOrderPunching = true;
    })

    .addCase(punchOrder.fulfilled, () => {
      return punchOrderFlowState;
    })

    .addCase(punchOrder.rejected, state => {
      state.isOrderPunching = false;
    })

    .addCase(fetchBranchesForPOS.fulfilled, (state, { payload }) => {
      state.availableBranchesForPOS = payload.elements;
    })

    .addCase(setCustomDeliveryFee, (state, { payload }) => {
      state.customDeliveryFee = payload;
      state.isBasketSynchronized = false;
    })

    .addCase(resetPunchOrderFlow, () => {
      return punchOrderFlowState;
    })

    .addCase(setInitialState, (state, { payload }) => {
      state.orderSource = payload.orderSource;
      state.orderDetails = payload.orderDetails;
      state.customDeliveryFee = payload.customDeliveryFee;
      state.selectedCustomer = payload.selectedCustomer;
      state.selectedCustomerAddressId = payload.selectedCustomerAddressId;
      state.coordinatesMatchingError = payload.coordinatesMatchingError;
      state.availableDeliveryAreas = payload.availableDeliveryAreas;
      state.orderBody = payload.orderBody;
      state.poses = payload.poses;
      state.isPosesLoading = payload.isPosesLoading;
      state.resolvedKitchen = payload.resolvedKitchen;
      state.isKitchenResolving = payload.isKitchenResolving;
      state.isOrderPunching = payload.isOrderPunching;
      state.availableBranchesForPOS = payload.availableBranchesForPOS;
      state.isApplyingDiscounts = payload.isApplyingDiscounts;
      state.priceDetails = payload.priceDetails;
      state.isBasketSynchronized = payload.isBasketSynchronized;
      state.isCustomerEditModeEnabled = payload.isCustomerEditModeEnabled;
      state.isSourceOrderIdRequired = payload.isSourceOrderIdRequired;
      state.initialStateReloaded = true;
    });

export const punchOrderFlowNext = createReducer(punchOrderFlowState, builder => {
  makeMainReducer(builder);
  makeOrderBodyReducer(builder);
  makeOrderSourceReducer(builder);
  makeOrderDetailsReducer(builder);
  makeSelectedCustomerReducer(builder);
});
