import { uniq, reduce, isEqual } from 'lodash';
import { generateRandomString } from 'utils/string';
import { Order } from 'types/Order';
import { Discount } from 'types/Discount';
import {
  BrandPositions,
  isExistingPosition,
  isNewPosition,
  SelectedAddon,
  SelectedComboGroup,
  SelectedGroupMenuItem,
  SelectedPosition,
} from 'store/orderEdition/orderEdition.types';
import { CreateOrderPosition } from 'types/CreateOrderPosition';
import { CreateOrderAddon } from 'types/CreateOrderAddon';
import { OrderAddon } from 'types/OrderAddon';
import { OrderComboGroup } from 'types/OrderComboGroup';
import { OrderComboGroupItem } from 'types/OrderComboGroupItem';
import { UpdateOrderPosition } from 'types/UpdateOrderPosition';
import { validationSchema } from 'components/OrderPriceAdjustments/OrderPriceAdjustments.validation';
import { PriceOverride } from 'types/PriceOverride';
import { isOrderPositionPrice, OrderPositionPrice } from 'types/OrderPositionPrice';
import { isNullish } from 'types/Nullable';
import { OrderPosition } from 'types/OrderPosition';
import { getExistingPositionWithCustomizationsPrice } from 'utils/orders';

type BrandPositionsCollection = Record<string, BrandPositions>;
type SelectedPositionsCollection = Record<string, SelectedPosition>;

export const getInitialOrderBody = (
  positionsGroupedByBrandId: Record<string, OrderPosition[]>
): Record<string, BrandPositions> => {
  return reduce(
    positionsGroupedByBrandId,
    (acc: BrandPositionsCollection, positions, brandId) => {
      acc[brandId] = {
        brandName: positions[0].brandName,
        positions: reduce(
          positions,
          (acc: SelectedPositionsCollection, position) => {
            const positionTempId = generateRandomString();

            acc[positionTempId] = {
              ...position,
              positionTempId,
              isExistingPosition: true,
              isRemoved: false,
            };
            return acc;
          },
          {}
        ),
      };

      return acc;
    },
    {}
  );
};

const getOrderLevelPositionDiscount = (
  selectedOrderLevelDiscounts: Discount[],
  position: SelectedPosition
) => {
  const menuMasterId = position.combo?.menuMasterId ?? position.item?.menuMasterId ?? '';
  const positionMasterId = position.combo?.menuComboId ?? position.item?.menuItemId ?? '';

  const includedInBrandDiscount = (discount: Discount) =>
    discount.brandDiscountDetails?.menuMasterIds?.includes(menuMasterId);

  const includedInBogoDiscount = (discount: Discount) => {
    const details = discount.buyXGetYFreeDiscountDetails;

    if (details && details.menuMasterIds?.includes(menuMasterId)) {
      if (position.combo) {
        return details.menuComboMasterIds?.includes(positionMasterId);
      } else {
        return details.menuItemMasterIds?.includes(positionMasterId);
      }
    }

    return false;
  };

  return selectedOrderLevelDiscounts.find(
    discount => includedInBrandDiscount(discount) || includedInBogoDiscount(discount)
  );
};

type PositionToSave = CreateOrderPosition & { id?: string; isRemoved: boolean; seatIndex?: number };

const getAdjustedPrice = (brandPosition: SelectedPosition) => {
  if (isExistingPosition(brandPosition)) {
    const customizationPrice = Number(getExistingPositionWithCustomizationsPrice(brandPosition));
    const existingAdjustedPrice = {
      total: customizationPrice - (brandPosition.adjustedDiscount || 0),
      isNet: true,
    };
    if (brandPosition.adjustedPrice === null || brandPosition.adjustedPrice === undefined) {
      if (existingAdjustedPrice.total !== customizationPrice) {
        return existingAdjustedPrice;
      } else {
        return null;
      }
    }
  }
  return brandPosition.adjustedPrice;
};

const serializePositionPrice = (
  { isPriceOverriddenAsNet, netPrice, grossPrice }: OrderPositionPrice,
  quantity: number
): PriceOverride | undefined => {
  if (isNullish(isPriceOverriddenAsNet)) {
    return undefined;
  }

  return {
    total: (isPriceOverriddenAsNet ? netPrice : grossPrice) * quantity,
    isNet: isPriceOverriddenAsNet,
  };
};

export const getOrderPositionsToSave = (
  orderBody: Record<string, BrandPositions> = {},
  selectedOrderLevelDiscounts: Discount[] = [],
  selectedPositionLevelDiscounts: Record<string, Discount> = {}
): PositionToSave[] => {
  const result: PositionToSave[] = [];

  const mapAddons = (
    addons?: Record<string, SelectedAddon> | SelectedAddon[] | OrderAddon[]
  ): CreateOrderAddon[] =>
    Object.values(addons ?? []).map((addon: SelectedAddon | OrderAddon) => ({
      quantity: addon.quantity,
      addonId: 'addonId' in addon ? addon.addonId : addon.id,
      priceOverride: isOrderPositionPrice(addon)
        ? serializePositionPrice(addon, addon.quantity)
        : undefined,
    }));

  const positions = Object.values(orderBody).flatMap(brandDetails =>
    Object.values(brandDetails.positions)
  );
  const existingPositions = positions.filter(isExistingPosition);
  const newPositions = positions.filter(isNewPosition);

  [...existingPositions, ...newPositions].forEach(brandPosition => {
    const positionGenericProps = {
      id: isExistingPosition(brandPosition) ? brandPosition.id : undefined,
      isRemoved: isExistingPosition(brandPosition) ? brandPosition.isRemoved : false,
      brandId: brandPosition.brandId,
      quantity: brandPosition.quantity,
      note: brandPosition.note,
    };

    if (brandPosition.item) {
      const { item } = brandPosition;

      result.push({
        ...positionGenericProps,
        discountId:
          selectedPositionLevelDiscounts[brandPosition.id]?.id ??
          getOrderLevelPositionDiscount(selectedOrderLevelDiscounts, brandPosition)?.id,
        item: {
          menuItemId: item.menuItemId,
          variantValueId: item.variantValueId,
          addons: mapAddons(item.addons),
        },
        priceOverride: isOrderPositionPrice(item)
          ? serializePositionPrice(item, brandPosition.quantity)
          : undefined,
        adjustedPrice: getAdjustedPrice(brandPosition),
      });
    } else if (brandPosition.combo) {
      const { combo } = brandPosition;

      result.push({
        ...positionGenericProps,
        discountId:
          selectedPositionLevelDiscounts[brandPosition.id]?.id ??
          getOrderLevelPositionDiscount(selectedOrderLevelDiscounts, brandPosition)?.id,
        combo: {
          menuComboId: combo.menuComboId,
          addons: mapAddons(combo.addons),
          comboGroups: (combo.comboGroups as Array<SelectedComboGroup | OrderComboGroup>).map(
            comboGroup => ({
              items: (comboGroup.items as Array<SelectedGroupMenuItem | OrderComboGroupItem>)?.map(
                item => ({
                  menuItemId: item.menuItemId,
                  variantValueId: item.variantValueId,
                  quantity: item.quantity,
                  priceOverride: isOrderPositionPrice(item)
                    ? serializePositionPrice(item, item.quantity)
                    : undefined,
                })
              ),
              menuComboGroupId: comboGroup.menuComboGroupId,
              addons: mapAddons(comboGroup.addons),
              priceOverride: isOrderPositionPrice(comboGroup)
                ? serializePositionPrice(comboGroup, brandPosition.quantity)
                : undefined,
            })
          ),
        },
        priceOverride:
          'grossPrice' in combo ? serializePositionPrice(combo, brandPosition.quantity) : undefined,
        adjustedPrice: getAdjustedPrice(brandPosition),
      });
    }
  });

  return result;
};

interface UpdatedOrder {
  orderPositions: CreateOrderPosition[];
  orderPositionsToAdd: CreateOrderPosition[];
  orderPositionsToUpdate: UpdateOrderPosition[];
  orderPositionsToDelete: string[];
  orderDiscountId?: string;
  orderDeliveryDiscountId?: string;
}

export const getUpdatedOrder = (
  orderBody: Record<string, BrandPositions> = {},
  selectedOrderLevelDiscounts: Discount[] = [],
  selectedPositionLevelDiscounts: Record<string, Discount> = {},
  selectedDeliveryDiscount?: Discount,
  initialDiscounts?: {
    selectedOrderLevelDiscounts: Discount[];
    selectedPositionLevelDiscounts: Record<string, Discount>;
    selectedDeliveryDiscount?: Discount;
  }
): UpdatedOrder => {
  const hasGeneralDiscount =
    selectedOrderLevelDiscounts.length === 1 &&
    (selectedOrderLevelDiscounts[0].generalDiscountDetails ||
      selectedOrderLevelDiscounts[0].newEatopiAccountDiscountDetails);

  const positions = getOrderPositionsToSave(
    orderBody,
    selectedOrderLevelDiscounts,
    selectedPositionLevelDiscounts
  );

  const orderPositions = positions.filter(position => !position.isRemoved);

  const orderPositionsToAdd = positions.filter(position => !position.id);
  const orderPositionsToDelete = positions
    .filter(position => position.isRemoved && position.id)
    .map(position => position.id as string);
  const orderPositionsToUpdate = positions
    .filter(position => !position.isRemoved && position.id)
    .map(position => ({
      orderPositionId: position.id as string,
      discountId: position.discountId,
      adjustedPrice: position.adjustedPrice,
    }));

  const initialSelectedOrderLevelDiscounts = initialDiscounts?.selectedOrderLevelDiscounts;
  const initialSelectedPositionLevelDiscounts = initialDiscounts?.selectedPositionLevelDiscounts;

  const areOrderPositionsChanged =
    !isEqual(initialSelectedOrderLevelDiscounts, selectedOrderLevelDiscounts) ||
    !isEqual(initialSelectedPositionLevelDiscounts, selectedPositionLevelDiscounts) ||
    orderPositionsToUpdate.some(position => position.adjustedPrice);

  return {
    orderPositions,
    orderPositionsToAdd,
    orderPositionsToDelete,
    orderPositionsToUpdate: areOrderPositionsChanged ? orderPositionsToUpdate : [],
    orderDiscountId: hasGeneralDiscount ? selectedOrderLevelDiscounts[0].id : undefined,
    orderDeliveryDiscountId: selectedDeliveryDiscount?.id,
  };
};

interface SelectedOrderDiscounts {
  selectedOrderLevelDiscounts: Discount[];
  selectedPositionLevelDiscounts: Record<string, Discount>;
  selectedDeliveryDiscount?: Discount;
  isPositionLevelDiscount: boolean;
}

export const getSelectedOrderDiscounts = (
  order: Order,
  availableDiscounts: Discount[]
): SelectedOrderDiscounts => {
  const selectedOrderLevelDiscounts: Discount[] = [];
  const selectedPositionLevelDiscounts: Record<string, Discount> = {};

  const findDiscount = (discountId?: string | null) =>
    availableDiscounts.find(discount => discount.id === discountId);
  const isItemLevelDiscount = (discount: Discount) => discount.itemDiscountDetails;

  const generalDiscount = findDiscount(order.discountId);
  if (generalDiscount) {
    selectedOrderLevelDiscounts.push(generalDiscount);
  }

  const selectedDeliveryDiscount = findDiscount(order.deliveryDiscountId);

  if (order.orderPositions) {
    for (const position of order.orderPositions) {
      const positionDiscount = findDiscount(position.discountId);

      if (!positionDiscount) {
        continue;
      }

      if (isItemLevelDiscount(positionDiscount)) {
        selectedPositionLevelDiscounts[position.id] = positionDiscount;
      } else {
        selectedOrderLevelDiscounts.push(positionDiscount);
      }
    }
  }

  return {
    selectedOrderLevelDiscounts: uniq(selectedOrderLevelDiscounts),
    selectedPositionLevelDiscounts,
    selectedDeliveryDiscount,
    isPositionLevelDiscount: Object.keys(selectedPositionLevelDiscounts).length > 0,
  };
};

export const checkPriceAdjustmentsValid = ({
  isPriceDiscrepancy,
  availableDiscountsLength,
  expectedTotal,
  expectedTotalDiscountName,
}: {
  isPriceDiscrepancy: boolean;
  availableDiscountsLength: number;
  expectedTotal: string;
  expectedTotalDiscountName: string | null;
}): boolean => {
  try {
    validationSchema.validateSync({
      isPriceDiscrepancy,
      availableDiscountsLength,
      expectedTotal,
      expectedTotalDiscountName,
    });
    return true;
  } catch (e) {
    return false;
  }
};
