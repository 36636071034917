import { KitopiDeliveryArea } from 'types/KitopiDeliveryArea';
import { Url } from 'utils/constants';
import { List } from 'types/List';
import { DeliveryArea } from 'types/DeliveryArea';
import { api, getAllPages } from 'api';

export const getDeliveryAreasForKitchenAndBrand = ({
  kitchenID,
  brandID,
}: {
  kitchenID: string;
  brandID: string;
}): Promise<List<DeliveryArea>> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/kitchens/${kitchenID}/delivery-areas?brand-id=${brandID}`
  );

export const getDeliveryAreas = (regionId?: string): Promise<List<KitopiDeliveryArea>> =>
  getAllPages(`${Url.KITCHEN_MANAGEMENT_API_URL}/delivery-areas`, {
    params: { regionId },
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });
