import { List } from 'types/List';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as manualActionsService from 'service/manualActions.service';
import * as positionsService from 'service/positions.service';
import * as orderService from 'service/order.service';
import {
  IssuesStatistics,
  ManualActionIssue,
  SnackbarState,
  ManualActionsState,
  SearchIssuesFilters,
  ResolveIssuePayload,
} from 'store/manualActions/manualActions.types';
import { Issue } from 'types/Issue';
import { MANUAL_ACTIONS_SUBSCRIPTION_SETTINGS_FILTERS } from './manualActions.consts';
import { Nullable } from 'types/Nullable';

export const updateSelectedPage = createAction<{ pageNumber: number }>(
  'manualActions/updateSelectedPage'
);

export const updateIssueExceedLimitedTime = createAction<{ issues: ManualActionIssue[] }>(
  'manualActions/updateIssueExceedLimitedTime'
);

export const updateSnackbar = createAction(
  'manualActions/updateSnackbar',
  ({ isOpen, isSubscribed }: SnackbarState) => {
    const payload: SnackbarState = {};

    if (typeof isSubscribed !== 'undefined') {
      manualActionsService.updateStorage('mamSubscribed', isSubscribed);
      payload.isSubscribed = isSubscribed;
    }

    if (typeof isOpen !== 'undefined') {
      payload.isOpen = isOpen;
    }

    return { payload };
  }
);

export const incrementStatisticUnresolvedCount = createAction(
  'manualActions/incrementStatisticUnresolvedCount'
);

export const incrementStatisticAssignedCount = createAction(
  'manualActions/incrementStatisticAssignedCount'
);

export const decrementStatisticAssignedCount = createAction(
  'manualActions/decrementStatisticAssignedCount'
);

export const resetIssueStatuses = createAction('manualActions/resetIssueStatuses');

export const selectIssue = createAction<Nullable<ManualActionIssue>>('manualActions/selectIssue');

export const updateSearchIssuesFilters = createAsyncThunk<
  Partial<SearchIssuesFilters>,
  Partial<SearchIssuesFilters>,
  { state: { manualActions: ManualActionsState } }
>('manualActions/updateSearchIssuesFilters', payload => {
  manualActionsService.updateStorage<Partial<SearchIssuesFilters>>(
    MANUAL_ACTIONS_SUBSCRIPTION_SETTINGS_FILTERS,
    payload
  );

  return Promise.resolve(payload);
});

export const getIssuesStatistics = createAsyncThunk<IssuesStatistics>(
  'manualActions/getIssuesStatistics',
  manualActionsService.getStatistics
);

export const getIssue = createAsyncThunk<Issue, { issueID: string }>(
  'manualActions/getIssue',
  ({ issueID }: { issueID: string }) => manualActionsService.getIssue({ issueID })
);

export const getIssues = createAsyncThunk<
  List<Issue>,
  { silently: boolean },
  { state: { manualActions: ManualActionsState } }
>('manualActions/getIssues', (_args, { getState }) => {
  const state = getState();
  const { selectedPage, searchIssuesFilters } = state.manualActions;

  return manualActionsService.getIssues({
    pageNumber: selectedPage,
    sortOrder: searchIssuesFilters.issueSorting,
    issueCategories: searchIssuesFilters.issueCategories,
    issueStatuses: searchIssuesFilters.issueStatuses,
    regionIds: searchIssuesFilters.regions?.map(region => region.id),
    kitchenIds: searchIssuesFilters.kitchens?.map(kitchen => kitchen.id),
  });
});

export const resolveIssue = createAsyncThunk(
  'manualActions/resolveIssue',
  ({ issue, payload }: { issue: ManualActionIssue; payload?: ResolveIssuePayload }) =>
    manualActionsService.resolveManualAction({ issueId: issue.issueId, payload })
);

export const assignIssueAgent = createAsyncThunk(
  'manualActions/assignIssueAgent',
  ({ issueId }: { issueId: string }) => manualActionsService.assignAgent({ issueId })
);

export const reassignIssueAgent = createAsyncThunk(
  'manualActions/reassignAgent',
  ({ issueId }: { issueId: string }) => manualActionsService.reassignAgent({ issueId })
);

export const unassignIssueAgent = createAsyncThunk(
  'manualActions/unassignIssueAgent',
  ({ issueId }: { issueId: string }) => manualActionsService.unassignAgent({ issueId })
);

export const fetchPositionsForBrand = createAsyncThunk(
  'manualActions/fetchPositionsForBrand',
  positionsService.getPositionsForBrand
);

export const cancelOrder = createAsyncThunk('manualActions/cancelOrder', orderService.cancelOrder);
