import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PunchOrderFlowState } from '../punchOrderFlow.types';
import { OrderType } from 'types/OrderType';
import {
  resetSelectedCustomer,
  setCoordinatesMatchingError,
  setIsCustomerEditModeEnabled,
  setSelectedCustomer,
  setSelectedCustomerAddressId,
} from './selectedCustomer.actions';

export const makeSelectedCustomerReducer = (
  builder: ActionReducerMapBuilder<PunchOrderFlowState>
) =>
  builder
    .addCase(setSelectedCustomer, (state, { payload }) => {
      state.isCustomerEditModeEnabled = false;
      state.selectedCustomer = payload;
      state.selectedCustomerAddressId =
        state.orderSource.orderType === OrderType.DELIVERY && payload?.addresses?.length
          ? payload.addresses[0].id
          : null;
    })

    .addCase(resetSelectedCustomer, state => {
      state.selectedCustomer = null;
      state.selectedCustomerAddressId = null;
    })

    .addCase(setSelectedCustomerAddressId, (state, { payload }) => {
      state.selectedCustomerAddressId = payload;
    })

    .addCase(setCoordinatesMatchingError, (state, { payload }) => {
      state.coordinatesMatchingError = payload;
    })

    .addCase(setIsCustomerEditModeEnabled, (state, { payload }) => {
      state.isCustomerEditModeEnabled = payload;
    });
