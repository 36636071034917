import { List } from 'types/List';
import { Discount } from 'types/Discount';
import { api } from 'api';
import { Url } from 'utils/constants';

export const getAvailableDiscounts = ({
  countryId,
  pointOfSaleId,
  menuMasterIds,
  date,
}: {
  countryId: string;
  pointOfSaleId: string;
  menuMasterIds: string[];
  date?: string;
}): Promise<List<Discount>> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/ccp/countries/${countryId}/point-of-sales/${pointOfSaleId}/discounts`,
    {
      params: {
        menuMasterId: menuMasterIds,
        date,
      },
    }
  );
