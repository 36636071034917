import axios, { AxiosRequestConfig } from 'axios';
import {
  addAuthorizationHeader,
  normalizeParams,
  addMetrics,
  defaultsInterceptor,
} from './requestInterceptors';

import { errorResponse, successResponse } from './responseInterceptors';
import qs from 'qs';

const createInstance = (options: AxiosRequestConfig) => {
  const instance = axios.create(options);
  instance.interceptors.request.use(defaultsInterceptor);
  instance.interceptors.request.use(addAuthorizationHeader);
  instance.interceptors.request.use(normalizeParams);
  instance.interceptors.request.use(addMetrics);
  instance.interceptors.response.use(successResponse, errorResponse);

  return instance;
};

export const api = createInstance({
  withCredentials: false,
  paramsSerializer: {
    indexes: null,
    serialize: params => qs.stringify(params, { arrayFormat: 'comma' }),
  },
});
