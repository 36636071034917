import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as brandService from 'service/brand.service';
import { CcpBrand } from 'types/CcpBrand';
import { OrderType } from 'types/OrderType';
import { KitchenAssignmentStrategy } from 'types/POSDetails';
import { CcpMenuCombo } from 'types/CcpMenuCombo';
import { CcpMenuComboGroup } from 'types/CcpMenuComboGroup';
import { CcpMenuItem } from 'types/CcpMenuItem';
import { CcpMenuPosition } from 'types/CcpMenuPosition';
import { CcpPositionAddon } from 'types/CcpPositionAddon';
import { SelectedMenuComboGroupAddon } from 'types/SelectedMenuComboGroupAddon';
import { SelectedMenuComboGroupMenuItem } from 'types/SelectedMenuComboGroupMenuItem';
import { SelectedPosition } from 'types/SelectedPosition';
import { SelectedPositionPrice } from 'types/SelectedPositionPrice';

export const fetchAvailableBrands = createAsyncThunk(
  'punchOrderFlowNext/fetchAvailableBrands',
  ({
    pointOfSaleId,
    kitchenId,
    deliveryAreaId,
    kitchenAssignmentStrategy,
    orderType,
  }: {
    pointOfSaleId: string;
    kitchenId: string | undefined;
    deliveryAreaId: string | undefined;
    kitchenAssignmentStrategy: KitchenAssignmentStrategy | undefined;
    orderType: OrderType;
  }) => {
    if (
      kitchenAssignmentStrategy === KitchenAssignmentStrategy.BRANCH_ID &&
      orderType === OrderType.DELIVERY
    ) {
      return Promise.resolve([]);
    }

    if (kitchenId) {
      return brandService
        .getKitchenAndPosBrands({
          pointOfSaleId,
          kitchenId,
        })
        .then(({ elements }) => elements);
    }

    return deliveryAreaId
      ? brandService
          .getDeliveryAreaAndPosBrands({
            pointOfSaleId,
            deliveryAreaId,
          })
          .then(({ elements }) => elements)
      : Promise.resolve([]);
  }
);

export const setCurrentBrand = createAction<CcpBrand | null>('punchOrderFlowNext/setCurrentBrand');

export const selectBrand = createAction<CcpBrand>('punchOrderFlowNext/selectBrand');

export const resetSelectedBrands = createAction('punchOrderFlowNext/resetSelectedBrands');

export const removeBrand = createAction(
  'punchOrderFlowNext/removeBrand',
  (brandId: string | null) => ({
    payload: brandId,
  })
);

export const resetBrand = createAction('punchOrderFlowNext/resetBrand');

export const setIsPriceDiscrepancy = createAction<boolean>(
  'punchOrderFlowNext/setIsPriceDiscrepancy'
);

export const setExpectedTotal = createAction<string>('punchOrderFlowNext/setExpectedTotal');

export const setExpectedTotalDiscountName = createAction<string | null>(
  'punchOrderFlowNext/setExpectedTotalDiscountName'
);
export const setShowMenuExpanded = createAction<boolean>('punchOrderFlowNext/setShowMenuExpanded');
export const setShowCategories = createAction<boolean>('punchOrderFlowNext/setShowCategories');
export const setOrderNote = createAction<string>('punchOrderFlowNext/setOrderNote');

interface SetActivePositionIDParams {
  activePositionID: string | null;
  activePositionIndex: number | null;
}
export const setActivePositionID = createAction<SetActivePositionIDParams>(
  'punchOrderFlowNext/setActivePositionID'
);

interface SetComboGroupValidityParams {
  brandId: string;
  positionIndex: number;
  groupId: string;
  isValid: boolean;
}

export const setComboGroupValidity = createAction<SetComboGroupValidityParams>(
  'punchOrderFlowNext/setComboGroupValidity'
);

interface SetCustomizationCompletedParams {
  brandId: string;
  positionIndex: number;
}

export const setCustomizationCompleted = createAction<SetCustomizationCompletedParams>(
  'punchOrderFlowNext/setCustomizationCompleted'
);

export const setPositionsForCurrentBrand = createAction<CcpMenuPosition[]>(
  'punchOrderFlowNext/setPositionsForCurrentBrand'
);

export const setPositionsLoading = createAction<boolean>('punchOrderFlowNext/setPositionsLoading');

interface SetSelectedPositionsForCurrentOrderParams {
  selectedPositions: SelectedPosition[];
  currentBrandId: string;
}

export const setSelectedPositionsForCurrentOrder =
  createAction<SetSelectedPositionsForCurrentOrderParams>(
    'punchOrderFlowNext/setSelectedPositionsForCurrentOrder'
  );

interface SetPositionDetailsParams {
  brandId: string;
  masterId: string;
  details: CcpMenuItem | CcpMenuCombo;
}

export const setPositionDetails = createAction<SetPositionDetailsParams>(
  'punchOrderFlowNext/setPositionDetails'
);

interface SelectComboGroupPositionParams {
  brandId: string;
  groupId: string;
  element: SelectedMenuComboGroupMenuItem | SelectedMenuComboGroupAddon;
  comboIndex: number;
  groupDetails: CcpMenuComboGroup | undefined;
}

export const selectComboGroupPosition = createAction<SelectComboGroupPositionParams>(
  'punchOrderFlowNext/selectComboGroupPosition'
);

interface SelectPositionAddonParams {
  brandId: string;
  addon: CcpPositionAddon;
  positionIndex: number;
}

export const selectPositionAddon = createAction<SelectPositionAddonParams>(
  'punchOrderFlowNext/selectPositionAddon'
);

interface RemovePositionAddonParams {
  brandId: string;
  positionIndex: number;
  addonIndex: number;
}

export const removePositionAddon = createAction<RemovePositionAddonParams>(
  'punchOrderFlowNext/removePositionAddon'
);
interface ChangeGroupPositionQuantityParams {
  brandId: string;
  groupId: string;
  comboIndex: number;
  elementIndex?: number;
  quantity: number;
}

export const changeGroupPositionQuantity = createAction<ChangeGroupPositionQuantityParams>(
  'punchOrderFlowNext/changeGroupPositionQuantity'
);

interface AddPositionNoteParams {
  brandId: string;
  positionIndex: number;
  note: string;
}

export const addPositionNote = createAction<AddPositionNoteParams>(
  'punchOrderFlowNext/addPositionNote'
);

interface RemoveGroupPositionParams {
  brandId: string;
  groupId: string;
  comboIndex: number;
  elementIndex: number;
}

export const removeGroupPosition = createAction<RemoveGroupPositionParams>(
  'punchOrderFlowNext/removeGroupPosition'
);

interface ChangePositionAddonQuantityParams {
  brandId: string;
  positionIndex: number;
  addonIndex: number;
  quantity: number;
}

export const changePositionAddonQuantity = createAction<ChangePositionAddonQuantityParams>(
  'punchOrderFlowNext/changePositionAddonQuantity'
);

export const setAllPositionsTouched = createAction('punchOrderFlowNext/setAllPositionsTouched');

export const clearPositions = createAction('punchOrderFlowNext/clearPositions');

interface SetPositionPriceOverrideParams {
  index: number;
  brandId: string;
  price: Omit<SelectedPositionPrice, 'isEdited' | 'menuNetPrice'>;
}

export const setPositionPriceOverride = createAction<SetPositionPriceOverrideParams>(
  'punchOrderFlowNext/setPositionPriceOverride'
);

interface SetGroupPositionPriceOverrideParams {
  brandId: string;
  elementIndex: number;
  comboIndex: number;
  groupId: string;
  price: Omit<SelectedPositionPrice, 'menuNetPrice' | 'isEdited'>;
}

export const setGroupPositionPriceOverride = createAction<SetGroupPositionPriceOverrideParams>(
  'punchOrderFlowNext/setGroupPositionPriceOverride'
);

interface SetAddonPriceOverrideParams {
  brandId: string;
  positionIndex: number;
  addonIndex: number;
  price: Omit<SelectedPositionPrice, 'menuNetPrice' | 'isEdited'>;
}

export const setAddonPriceOverride = createAction<SetAddonPriceOverrideParams>(
  'punchOrderFlowNext/setAddonPriceOverride'
);

export const resetKitchen = createAction('punchOrderFlowNext/resetKitchen');

export const searchMenuItem = createAction<string>('punchOrderFlowNext/searchMenuItem');

export const setActiveMenuPositionMasterId = createAction<{ id: string | null }>(
  'punchOrderFlowNext/setActiveMenuPositionMasterId'
);
