import { metadataState } from './metadata/metadata.reducer';
import { MetadataState } from './metadata/metadata.types';

export const migrations: any = {
  //there is wrong typing inside redux persist, https://github.com/rt2zz/redux-persist/issues/1065#issuecomment-554538845
  3: (state: { metadata: MetadataState }) => ({
    ...state,
    metadata: metadataState,
  }),
  4: (state: { metadata: MetadataState }) => ({
    ...state,
    metadata: { ...state.metadata, pointsOfSale: [] },
  }),
};
