import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PunchOrderFlowState } from '../punchOrderFlow.types';
import {
  createOrderDraft,
  getAvailableKitchensForRegion,
  getPotentialDuplicates,
  resetPotentialDuplicates,
  resetSelectedKitchenAndBranch,
  resolveSelectedKitchen,
  setCurrency,
  setTaxRate,
  updateOrderSource,
} from './orderSource.actions';

export const makeOrderSourceReducer = (builder: ActionReducerMapBuilder<PunchOrderFlowState>) =>
  builder
    .addCase(resetSelectedKitchenAndBranch, state => {
      state.orderSource.selectedKitchen = undefined;
      state.orderSource.selectedBranch = undefined;
    })
    .addCase(setTaxRate, (state, { payload }) => {
      state.orderSource.taxRate = payload;
    })

    .addCase(resetPotentialDuplicates, state => {
      state.orderSource.potentialDuplicates = [];
    })

    .addCase(getPotentialDuplicates.pending, state => {
      state.orderSource.isLoadingPotentialDuplicates = true;
    })

    .addCase(getPotentialDuplicates.fulfilled, (state, { payload }) => {
      state.orderSource.isLoadingPotentialDuplicates = false;
      state.orderSource.potentialDuplicates = payload.potentialDuplicates;
    })

    .addCase(getPotentialDuplicates.rejected, state => {
      state.orderSource.isLoadingPotentialDuplicates = false;
    })

    .addCase(createOrderDraft.pending, (state, { meta }) => {
      state.orderSource.isCreatingOrderDraft = true;
      state.orderSource.draftCreatedForPos = meta.arg.pointOfSaleId;
      state.orderSource.draftCreatedForSourceOrderFriendlyId = meta.arg.sourceOrderFriendlyId;
    })

    .addCase(createOrderDraft.fulfilled, (state, { payload }) => {
      state.orderSource.isCreatingOrderDraft = false;
      state.orderSource.draftId = payload.resourceId;
    })

    .addCase(createOrderDraft.rejected, state => {
      state.orderSource.isCreatingOrderDraft = false;
    })

    .addCase(resolveSelectedKitchen.pending, state => {
      state.orderSource.isKitchenDetailsLoading = true;
    })

    .addCase(resolveSelectedKitchen.fulfilled, (state, { payload }) => {
      state.orderSource.isKitchenDetailsLoading = false;
      state.resolvedKitchen = payload;
    })

    .addCase(resolveSelectedKitchen.rejected, state => {
      state.orderSource.isKitchenDetailsLoading = false;
    })

    .addCase(getAvailableKitchensForRegion.pending, state => {
      state.orderSource.isKitchensForRegionLoading = true;
    })

    .addCase(getAvailableKitchensForRegion.fulfilled, (state, { payload }) => {
      state.orderSource.isKitchensForRegionLoading = false;
      state.orderSource.availableKitchensPerRegion = payload.elements;
    })

    .addCase(getAvailableKitchensForRegion.rejected, state => {
      state.orderSource.isKitchensForRegionLoading = false;
    })

    .addCase(setCurrency, (state, { payload }) => {
      state.orderSource.currency = payload;
    })

    .addCase(updateOrderSource, (state, { payload }) => {
      state.isSourceOrderIdRequired = payload.isSourceOrderIdRequired;
      state.orderSource.compositionStartTime = payload.compositionStartTime;
      state.orderSource.orderType = payload.orderType;
      state.orderSource.selectedRegion = payload.selectedRegion || undefined;
      state.orderSource.selectedPos = payload.selectedPos || undefined;
      state.orderSource.sourceOrderID = payload.sourceOrderID;
      state.orderSource.selectedBranch = payload.selectedBranch || undefined;
      state.orderSource.selectedKitchen = payload.selectedKitchen || undefined;
      state.orderSource.deliveryType = payload.deliveryType;
      state.resolvedKitchen = payload.resolvedKitchen;
    });
