import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PunchOrderFlowState } from '../punchOrderFlow.types';
import {
  getBrandPointOfSaleDeliveryPartners,
  getKitchenDeliveryPartners,
  updateOrderDetails,
} from './orderDetails.actions';

export const makeOrderDetailsReducer = (builder: ActionReducerMapBuilder<PunchOrderFlowState>) =>
  builder

    .addCase(getBrandPointOfSaleDeliveryPartners.fulfilled, (state, { payload }) => {
      state.orderDetails.brandDeliveryPartners = payload;
    })

    .addCase(getKitchenDeliveryPartners.fulfilled, (state, { payload }) => {
      state.orderDetails.kitchenDeliveryPartners = payload;
    })

    .addCase(updateOrderDetails, (state, { payload }) => {
      state.orderDetails.includeCutlery = payload.includeCutlery;
      state.orderDetails.internalNotes = payload.internalNotes;
      state.orderDetails.paymentMethod = payload.paymentMethod;
      state.orderDetails.deliveryPartnerId = payload.deliveryPartnerId;
      state.orderDetails.expectedPickUpTime = payload.expectedPickUpTime;
      state.orderDetails.contactNumber = payload.contactNumber;
      state.orderDetails.useKitchenTimezone = payload.useKitchenTimezone;
    });
