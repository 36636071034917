import { extendSession } from '@kitopi-com/auth';
import { AxiosRequestConfig } from 'axios';

export const addAuthorizationHeader = async (config: AxiosRequestConfig) => {
  const session = await extendSession();

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: session.idToken.jwtToken,
    },
  };
};
