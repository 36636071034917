import { api } from 'api';
import { Url } from 'utils/constants';
import { CcpKitchen } from 'types/CcpKitchen';
import { List } from 'types/List';
import { Kitchen } from 'types/Kitchen';
import { AxiosResponse } from 'axios';

export const resolveKitchenForBrandAndDeliveryArea = ({
  brandID,
  deliveryAreaID,
}: {
  brandID: string;
  deliveryAreaID: string;
}): Promise<AxiosResponse<Kitchen | { code: 'KITCHEN_NOT_RESOLVED' }>> =>
  api(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/kitchens/brands/${brandID}/delivery-areas/${deliveryAreaID}/resolve`,
    {
      hasFullResponse: true,
    }
  );

export const resolveKitchenForPosAndBranch = ({
  posID,
  branchID,
}: {
  posID: string | undefined;
  branchID: string;
}): Promise<AxiosResponse<Kitchen | { code: 'KITCHEN_NOT_RESOLVED' }>> =>
  api.get(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/kitchens/point-of-sales/${posID}/branches/${branchID}/resolve`,
    {
      hasFullResponse: true,
    }
  );

export const getCCPKitchens = (regionID?: string): Promise<List<CcpKitchen>> => {
  if (regionID) {
    return api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/ccp/kitchens?region-id=${regionID}`);
  }

  return api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/ccp/kitchens`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });
};

export const getCCPKitchen = (kitchenID: string): Promise<Kitchen> =>
  api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/ccp/kitchens/${kitchenID}`);
