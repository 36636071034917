import { Features } from './FeatureTogglesProvider.types';
import { FEATURE_FLAGS } from 'utils/constants';
export const migrations = [
  (flags: Features) => flags,
  (flags: Features) => ({
    ...flags,
    partialRefunds: 'deprecated',
  }),
  (flags: Features) => ({
    ...flags,
    invoicedOrders: FEATURE_FLAGS.invoicedOrders,
    partialRefunds: 'deprecated',
  }),
  (flags: Features) => ({
    ...flags,
    priceAdjustmentOnItemLevel: 'deprecated',
  }),
  (flags: Features) => ({
    ...flags,
    themeMode: FEATURE_FLAGS.themeMode,
  }),
  (flags: Features) => ({
    ...flags,
    punchingOrderFreezer: FEATURE_FLAGS.punchingOrderFreezer,
  }),
  (flags: Features) => ({
    ...flags,
    grossAndNetPricesInPunchingOrder: FEATURE_FLAGS.grossAndNetPricesInPunchingOrder,
  }),
  (flags: Features) => ({
    ...flags,
    grossAndNetPricesInPunchingOrder: FEATURE_FLAGS.punchingOrderNext,
  }),
  (flags: Features) => ({
    ...flags,
    newCreditNotes: 'deprecated',
  }),
  (flags: Features) => ({
    ...flags,
    newCreditNotes: undefined,
    partialRefunds: undefined,
    priceAdjustmentOnItemLevel: undefined,
    refunds: undefined,
  }),
];
