import addMinutes from 'date-fns/addMinutes';
import {
  format as dateFnsFormat,
  addDays,
  endOfDay as dateFnsEndOfDay,
  startOfDay as dateFnsStartOfDay,
} from 'date-fns';
import { enUsDateLocale } from 'translations/en-US';
import {
  SEARCH_ORDERS_MAX_DATE_RANGE,
  SEARCH_ORDERS_DATE_FORMAT_FROM,
  SEARCH_ORDERS_DATE_FORMAT_TO,
} from './constants';

const API_DATE_FORMAT = `yyyy-MM-dd'T'HH:mm:ssxxxx`;
const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;

interface Duration {
  hours: number;
  minutes: number;
  seconds: number;
  [key: string]: number;
}

export const secondsToDuration = (secs: number | null | undefined): Duration => {
  if (!secs) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const hours = Math.floor(secs / HOUR_IN_SECONDS);
  const rest = secs % HOUR_IN_SECONDS;
  const minutes = Math.floor(rest / MINUTE_IN_SECONDS);
  const seconds = rest % MINUTE_IN_SECONDS;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const formatDuration = (duration: Partial<Duration>, { short = false } = {}): string => {
  let result = '';
  const durationPropertiesOrder = ['hours', 'minutes', 'seconds'];

  for (const propertyName of durationPropertiesOrder) {
    if (duration[propertyName] || result.length || propertyName === 'seconds') {
      const longName = duration[propertyName] === 1 ? propertyName.slice(0, -1) : propertyName;
      const name = short ? propertyName.charAt(0) : ` ${longName}`;

      result += `${duration[propertyName] || 0}${name} `;
    }
  }

  return result.trim();
};

export const getCurrentUTCDate = (): Date => {
  return addMinutes(new Date(), new Date().getTimezoneOffset());
};

export const format = (date: Date, format: string): string =>
  dateFnsFormat(date, format, { locale: enUsDateLocale });

export const formatDateForApi = (date: Date): string => format(date, API_DATE_FORMAT);

export const getSearchOrdersDefaultDateFrom = (): string =>
  format(
    dateFnsStartOfDay(addDays(new Date(), -SEARCH_ORDERS_MAX_DATE_RANGE)),
    SEARCH_ORDERS_DATE_FORMAT_FROM
  );

export const getSearchOrdersDefaultDateTo = (): string =>
  format(dateFnsEndOfDay(new Date()), SEARCH_ORDERS_DATE_FORMAT_TO);
