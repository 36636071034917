import { createContext } from 'react';

import { DialogData } from './ConfirmationDialog.types';

export interface ConfirmationDialogContextI {
  showConfirmation: (dialogData: DialogData) => Promise<boolean>;
}

export const ConfirmationDialogContext = createContext<ConfirmationDialogContextI | undefined>(
  undefined
);
