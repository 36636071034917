import { Nullable } from 'types/Nullable';
import * as yup from 'yup';

const requiredMessage = 'Field is required';

export const validationSchema = yup.object().shape({
  isPriceDiscrepancy: yup.boolean(),
  availableDiscountsLength: yup.number(),

  expectedTotal: yup
    .string()
    .when(
      'isPriceDiscrepancy',
      (isPriceDiscrepancy: boolean, schema: yup.StringSchema): yup.StringSchema => {
        if (isPriceDiscrepancy) {
          return schema.required(requiredMessage);
        }

        return schema;
      }
    ),

  expectedTotalDiscountName: yup
    .string()
    .nullable()
    .when(['isPriceDiscrepancy', 'availableDiscountsLength'], {
      is: (isPriceDiscrepancy: boolean, availableDiscountsLength: number) =>
        isPriceDiscrepancy && availableDiscountsLength > 0,
      then: (schema: yup.StringSchema<Nullable<string>>) => schema.required(requiredMessage),
      otherwise: (schema: yup.StringSchema<Nullable<string>>) => schema,
    }),
});
