import 'normalize.css/normalize.css';
import 'assets/styles/grid.min.css';
import 'assets/styles/common-styles.css';
import { css, getStyle, useTheme, Global } from '@kitopi-com/ui';

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        .modal-component-overlay {
          background: rgba(0, 0, 0, 0.7);
          animation-name: fadeIn;
          animation-duration: 0.3s;
          animation-fill-mode: both;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1350;
        }
      `}
    />
  );
};

export const GlobalCCPStyles = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        html,
        body {
          font-family: 'Roboto', sans-serif;
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: #fff;
          background-color: ${getStyle(theme, 'Body').backgroundColor};
          @media screen and (max-width: 1280px) {
            font-size: 14px;
          }
        }

        div {
          box-sizing: border-box;
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        .container-fluid {
          &.no-padding {
            padding-left: 0;
            padding-right: 0;
          }
        }

        @media print {
          body {
            background-color: #fff;
          }
        }

        @media print {
          html,
          body {
            height: initial !important;
            overflow: initial !important;
            print-color-adjust: exact;
          }
        }
      `}
    />
  );
};
