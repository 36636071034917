import { createReducer } from '@reduxjs/toolkit';
import { ASC } from 'utils/constants';
import { ManualActionsState } from 'store/manualActions/manualActions.types';
import {
  assignIssueAgent,
  decrementStatisticAssignedCount,
  fetchPositionsForBrand,
  getIssuesStatistics,
  getIssue,
  getIssues,
  incrementStatisticAssignedCount,
  incrementStatisticUnresolvedCount,
  resetIssueStatuses,
  resolveIssue,
  unassignIssueAgent,
  reassignIssueAgent,
  updateSelectedPage,
  updateSnackbar,
  updateSearchIssuesFilters,
  selectIssue,
  updateIssueExceedLimitedTime,
} from 'store/manualActions/manualActions.actions';
import { IssueStatusType } from 'types/IssueStatusType';
import { getFromStorage, updateStorage } from 'service/manualActions.service';
import { MANUAL_ACTIONS_SUBSCRIPTION_SETTINGS_FILTERS } from './manualActions.consts';
import { isBefore, sub } from 'date-fns';
import { first, last } from 'lodash';
import { Issue } from 'types/Issue';

const EXCEED_TIME_THRESHOLD = 15;

const initialState: ManualActionsState = {
  isLoading: false,
  statistics: {
    assignedCount: 0,
    unresolvedCount: 0,
  },
  snackbar: {
    isOpen: true,
    isSubscribed: getFromStorage('mamSubscribed', false),
  },
  issuesById: {},
  issues: [],
  issueExceedLimitedTime: false,
  selectedIssue: null,
  selectedPage: 1,
  totalCount: 0,
  positions: {},
  searchIssuesFilters:
    getFromStorage(MANUAL_ACTIONS_SUBSCRIPTION_SETTINGS_FILTERS, false) ||
    updateStorage(MANUAL_ACTIONS_SUBSCRIPTION_SETTINGS_FILTERS, {
      issueCategories: [],
      issueSorting: ASC,
      issueStatuses: [IssueStatusType.NEW, IssueStatusType.ASSIGNED_TO_ME],
      regions: [],
      kitchens: [],
    }),
  silently: false,
  currentlyProcessingIssue: {},
};

export const manualActions = createReducer(initialState, builder => {
  builder
    .addCase(updateSearchIssuesFilters.fulfilled, (state, { payload }) => {
      state.selectedPage = 1;
      state.searchIssuesFilters = {
        ...state.searchIssuesFilters,
        ...payload,
      };
    })
    .addCase(resetIssueStatuses, state => {
      state.searchIssuesFilters.issueStatuses = [];
    })
    .addCase(getIssuesStatistics.fulfilled, (state, { payload }) => {
      state.statistics = payload;
    })
    .addCase(updateSelectedPage, (state, { payload }) => {
      state.selectedPage = payload.pageNumber;
    })
    .addCase(updateIssueExceedLimitedTime, (state, { payload }) => {
      const firstIssue = last(payload.issues);
      const lastIssue = first(payload.issues);
      const now = new Date();
      const subFromNow = sub(now, {
        minutes: EXCEED_TIME_THRESHOLD,
      });

      state.issueExceedLimitedTime =
        !!(firstIssue && isBefore(new Date(firstIssue.createdAt), subFromNow)) ||
        !!(lastIssue && isBefore(new Date(lastIssue.createdAt), subFromNow));
    })
    .addCase(unassignIssueAgent.pending, (state, { meta }) => {
      state.currentlyProcessingIssue[meta.arg.issueId] = true;
    })
    .addCase(unassignIssueAgent.rejected, (state, { meta }) => {
      delete state.currentlyProcessingIssue[meta.arg.issueId];
    })
    .addCase(unassignIssueAgent.fulfilled, (state, { meta }) => {
      delete state.currentlyProcessingIssue[meta.arg.issueId];
    })
    .addCase(assignIssueAgent.pending, (state, { meta }) => {
      state.currentlyProcessingIssue[meta.arg.issueId] = true;
    })
    .addCase(assignIssueAgent.rejected, (state, { meta }) => {
      delete state.currentlyProcessingIssue[meta.arg.issueId];
    })
    .addCase(assignIssueAgent.fulfilled, (state, { meta }) => {
      delete state.currentlyProcessingIssue[meta.arg.issueId];
    })
    .addCase(reassignIssueAgent.pending, (state, { meta }) => {
      state.currentlyProcessingIssue[meta.arg.issueId] = true;
    })
    .addCase(reassignIssueAgent.rejected, (state, { meta }) => {
      delete state.currentlyProcessingIssue[meta.arg.issueId];
    })
    .addCase(reassignIssueAgent.fulfilled, (state, { meta }) => {
      delete state.currentlyProcessingIssue[meta.arg.issueId];
    })
    .addCase(fetchPositionsForBrand.fulfilled, (state, { payload, meta }) => {
      state.positions[meta.arg.brandId] = payload.elements;
    })
    .addCase(getIssues.pending, (state, { meta }) => {
      if (!meta.arg.silently) {
        state.isLoading = true;
      }
    })
    .addCase(getIssues.rejected, state => {
      state.isLoading = false;
    })
    .addCase(getIssues.fulfilled, (state, { payload }) => {
      state.issuesById = payload.elements.reduce((obj: Record<string, Issue>, curr) => {
        obj[curr.issueId] = curr;
        return obj;
      }, {});
      state.issues = payload.elements;
      state.totalCount = payload.totalCount;
      state.isLoading = false;
    })
    .addCase(getIssue.pending, state => {
      state.isLoading = true;
    })
    .addCase(getIssue.rejected, state => {
      state.isLoading = false;
    })
    .addCase(getIssue.fulfilled, (state, { payload }) => {
      state.issuesById[payload.issueId] = payload;
      state.isLoading = false;
    })
    .addCase(resolveIssue.fulfilled, (state, { meta }) => {
      if (meta.arg.issue.assignedTo) {
        state.statistics.assignedCount--;
      }
      state.statistics.unresolvedCount--;
    })
    .addCase(updateSnackbar, (state, { payload }) => {
      state.snackbar = {
        ...state.snackbar,
        ...payload,
      };
    })
    .addCase(incrementStatisticUnresolvedCount, state => {
      state.statistics.unresolvedCount++;
    })
    .addCase(incrementStatisticAssignedCount, state => {
      state.statistics.assignedCount++;
    })
    .addCase(decrementStatisticAssignedCount, state => {
      state.statistics.assignedCount--;
    })
    .addCase(selectIssue, (state, { payload }) => {
      state.selectedIssue = payload;
    });
});
