import { api } from 'api';
import { DESC, Url } from 'utils/constants';
import { createQueryString } from 'utils/createQueryString';
import {
  IssuesStatistics,
  MAM_PAGE_SIZE,
  ResolveIssuePayload,
} from 'store/manualActions/manualActions.types';
import { List } from 'types/List';
import { getStoredData, updateStoredData } from 'service/storage.service';
import { IssueCategory } from 'types/IssueCategory';
import { IssueStatusType } from 'types/IssueStatusType';
import { Issue } from 'types/Issue';
import { isObject } from 'lodash';

export const getIssue = ({ issueID }: { issueID: string }): Promise<Issue> =>
  api.get(`${Url.MANUAL_ACTIONS_API_URL}/issues/${issueID}`);

export const getIssues = ({
  pageNumber = 1,
  pageSize = MAM_PAGE_SIZE,
  sortOrder = DESC,
  issueCategories = [],
  issueStatuses = [],
  regionIds = [],
  kitchenIds = [],
}: {
  pageNumber?: number;
  pageSize?: number;
  sortOrder?: string;
  issueCategories?: IssueCategory[];
  issueStatuses?: IssueStatusType[];
  regionIds?: string[];
  kitchenIds?: string[];
}): Promise<List<Issue>> => {
  const queryString = createQueryString({
    pageNumber,
    pageSize,
    sortOrder,
    issueCategories,
    issueStatuses: issueStatuses?.length
      ? issueStatuses
      : [IssueStatusType.NEW, IssueStatusType.ASSIGNED_TO_ME],
    regionIds,
    kitchenIds,
  });

  return api.get(`${Url.MANUAL_ACTIONS_API_URL}/issues?${queryString}`);
};

export const resolveManualAction = ({
  issueId,
  payload,
}: {
  issueId: string;
  payload?: ResolveIssuePayload;
}): Promise<void> => {
  const options: { method: string; body?: ResolveIssuePayload } = { method: 'PATCH' };

  if (payload) {
    options.body = { ...payload };
  }

  return api.patch(`${Url.MANUAL_ACTIONS_API_URL}/issues/${issueId}/resolve`, options.body);
};

export const assignAgent = ({ issueId }: { issueId: string }): Promise<void> => {
  return api.patch(`${Url.MANUAL_ACTIONS_API_URL}/issues/${issueId}/assign`);
};

export const reassignAgent = ({ issueId }: { issueId: string }): Promise<void> => {
  return api.patch(`${Url.MANUAL_ACTIONS_API_URL}/issues/${issueId}/reassign`);
};

export const unassignAgent = ({ issueId }: { issueId: string }): Promise<void> => {
  return api.patch(`${Url.MANUAL_ACTIONS_API_URL}/issues/${issueId}/unassign`);
};

export const getStatistics = (): Promise<IssuesStatistics> => {
  return api.get(`${Url.MANUAL_ACTIONS_API_URL}/issues/statistics`, {
    hasErrorDialog: false,
    hasConnectivityErrorDialog: false,
  });
};

export const getFromStorage = <T>(key: string, defaultValue?: T) =>
  getStoredData(key, defaultValue);

export const updateStorage = <T>(key: string, value: T) => {
  const storage = getFromStorage(key);
  let result = value;
  if (storage && isObject(storage) && isObject(value)) {
    result = { ...storage, ...value };
    updateStoredData(key, result);
  } else {
    updateStoredData(key, value);
  }

  return result;
};
