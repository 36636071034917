import { Component, ReactNode } from 'react';
import { APP_VERSION, APP_TYPE } from 'utils/constants';
import * as Sentry from '@sentry/browser';
import { instanceId } from 'service/metrics.service';
import { getCurrentSession } from '@kitopi-com/auth';
import { AppErrorBoundary } from 'components/common/AppErrorBoundary/AppErrorBoundary';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  message: string;
  email?: string;
  timestamp?: Date;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false,
    message: '',
    email: undefined,
    timestamp: undefined,
  };

  componentDidCatch(error: Error): void {
    const session = getCurrentSession();

    this.setState({
      hasError: true,
      message: error?.message,
      email: session?.user.email,
      timestamp: new Date(),
    });

    Sentry.addBreadcrumb({
      category: 'general',
      message: `Fatal Error`,
      level: 'error',
    });
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError, message, timestamp, email } = this.state;

    if (hasError) {
      return (
        <AppErrorBoundary
          email={email}
          date={timestamp}
          error={new Error(message)}
          appType={APP_TYPE}
          appVersion={APP_VERSION}
          instanceId={instanceId}
        >
          {children}
        </AppErrorBoundary>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
