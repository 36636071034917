import { getStoredData, updateStoredData } from 'service/storage.service';
import { v4 as uuidv4 } from 'uuid';

interface Metrics {
  readonly instanceId?: string;
}

const metrics = getStoredData<Metrics>('metrics');
const instanceId: string = metrics?.instanceId || uuidv4();

if (!metrics?.instanceId) {
  updateStoredData('metrics', { ...metrics, instanceId });
}

export { instanceId };
