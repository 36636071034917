import { SelectedGroup, SelectedGroupAddon, SelectedGroupMenuItem } from 'types/SelectedPosition';
import { CreateOrderComboGroup } from 'types/CreateOrderComboGroup';

import { CreateOrderComboGroupItem } from 'types/CreateOrderComboGroupItem';
import { CreateOrderAddon } from 'types/CreateOrderAddon';
import { serializeSelectedPositionPriceDivideByQuantity } from './serializeSelectedPositionPriceDivideByQuantity.util';
import { MenuPositionType } from 'types/MenuPositionType';

const isSelectedGroupMenuItem = (
  position: SelectedGroupMenuItem | SelectedGroupAddon
): position is SelectedGroupMenuItem => position.type === MenuPositionType.ITEM;

const isSelectedGroupAddon = (
  position: SelectedGroupMenuItem | SelectedGroupAddon
): position is SelectedGroupAddon => position.type === MenuPositionType.ADDON;

const serializeComboGroupItems = (
  items: SelectedGroupMenuItem[],
  isNet: boolean,
  parentQuantity: number
): CreateOrderComboGroupItem[] =>
  items.map(({ masterId, quantity, variantValueId, priceOverride }) => ({
    menuItemId: masterId,
    quantity,
    variantValueId,
    priceOverride: serializeSelectedPositionPriceDivideByQuantity(
      priceOverride,
      isNet,
      quantity,
      parentQuantity
    ),
  }));

const serializeComboGroupAddons = (
  addons: SelectedGroupAddon[],
  isNet: boolean,
  parentQuantity: number
): CreateOrderAddon[] =>
  addons.map(({ id, quantity, priceOverride }) => ({
    addonId: id,
    quantity,
    priceOverride: serializeSelectedPositionPriceDivideByQuantity(
      priceOverride,
      isNet,
      quantity,
      parentQuantity
    ),
  }));

export const serializeComboGroups = (
  groups: Record<string, SelectedGroup>,
  isNet: boolean,
  quantity: number
): CreateOrderComboGroup[] =>
  Object.entries(groups).map(([groupId, { positions }]) => {
    const positionsWithPositiveQuantity = positions.filter(item => !!item.quantity);

    return {
      menuComboGroupId: groupId,
      items: serializeComboGroupItems(
        positionsWithPositiveQuantity.filter(isSelectedGroupMenuItem),
        isNet,
        quantity
      ),
      addons: serializeComboGroupAddons(
        positionsWithPositiveQuantity.filter(isSelectedGroupAddon),
        isNet,
        quantity
      ),
    };
  });
