import { createSelector } from '@reduxjs/toolkit';
import { memoize, sortBy } from 'lodash';
import { RootState } from 'store';
import { MetadataState } from 'store/metadata/metadata.types';
import { Region } from 'types/Region';

export interface RegionOption extends Region {
  countryName?: string;
}

const metadataSelector = (state: RootState): MetadataState => state.metadata;

export const brandsSelector = createSelector(metadataSelector, metadata => metadata.brands);
export const countryPhonePrefixesSelector = createSelector(
  metadataSelector,
  metadata => metadata.countryPhonePrefixes
);
export const deliveryAreasSelector = createSelector(
  metadataSelector,
  metadata => metadata.deliveryAreas
);

const deliveryPartnersSelector = createSelector(
  metadataSelector,
  metadata => metadata.deliveryPartners
);

const countriesSelector = createSelector(metadataSelector, metadata => metadata.countries);

const kitchensSelector = createSelector(metadataSelector, metadata => metadata.kitchens);

const regionsSelector = createSelector(metadataSelector, metadata => metadata.regions);

const regionsWithCountryNameSelector = createSelector(
  regionsSelector,
  countriesSelector,
  (regions, countries) =>
    regions.map(region => ({
      ...region,
      countryName: countries.find(country => country.id === region.countryId)?.name,
    }))
);

const pointsOfSaleSelector = createSelector(metadataSelector, metadata => metadata.pointsOfSale);

const pointsOfSaleWithRegionNameSelector = createSelector(
  pointsOfSaleSelector,
  regionsWithCountryNameSelector,
  (pointsOfSale, regions) =>
    pointsOfSale.map(pointOfSale => ({
      ...pointOfSale,
      regionName: regions.find(region => region.id === pointOfSale.regionId)?.name,
    }))
);

export const pointsOfSaleSortedPartnerNameSelector = createSelector(
  pointsOfSaleWithRegionNameSelector,
  poses => sortBy(poses, ['partnerName'])
);

export const isMetadataLoadedSelector = createSelector(
  metadataSelector,
  metadata => metadata.isMetadataLoaded
);

const posesForRegionSelector = createSelector(
  metadataSelector,
  metadata => metadata.posesForRegion
);

const regionTaxesSelector = createSelector(metadataSelector, metadata => metadata.regionTaxes);

export const brandsSortedByNameSelector = createSelector(brandsSelector, arr =>
  sortBy(arr, ['name'])
);

export const deliveryPartnersByNameSelector = createSelector(deliveryPartnersSelector, arr =>
  sortBy(arr, ['name'])
);

export const kitchensSortedByNameSelector = createSelector(kitchensSelector, arr =>
  sortBy(arr, ['name'])
);

export const regionsSortedByNameSelector = createSelector(regionsWithCountryNameSelector, arr =>
  sortBy(arr, ['name'])
);

export const kitchenSelector = createSelector(kitchensSelector, kitchens =>
  memoize((kitchenId: string) => kitchens.find(kitchen => kitchen.id === kitchenId))
);

export const posesByRegionIdSelector = createSelector(posesForRegionSelector, regions =>
  memoize(
    (regionId: string | undefined) =>
      (regionId && regions && sortBy(regions[regionId], ['partnerName'])) || []
  )
);

export const taxByRegionIdSelector = createSelector(
  regionTaxesSelector,
  taxes => (regionId: string | undefined) => {
    const regionTaxes = (regionId && taxes && taxes[regionId]) || [];
    return regionTaxes.find(regionTax => !!regionTax.isDefault)?.taxRate;
  }
);

export const isLoadingPosesForRegionSelector = createSelector(
  metadataSelector,
  metadata => metadata.isLoadingPosesForRegion
);
