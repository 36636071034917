import { createContext, useContext, useState } from 'react';
import { useCookieAuth } from './useCookieAuth';

export interface IframeProviderProps {
  orderLink?: string;
  isCustomerDashboardLinkDisabled?: boolean;
  isIframeIssue?: boolean;
  walletsUrl?: string;
  isWalletListProvidedByIframe?: boolean;
  isOrderSearchIframe?: boolean;
  isPunchingOrderIframe?: boolean;
  isIframeActive?: boolean;
}

export interface IframeContext extends IframeProviderProps {
  isManuallyLogedIn: boolean;
  isRedirectedToDashboard: boolean;
  isAuthorizationPending: boolean;
  isAuthInitialized: boolean;
  redirectToLogoutPage: () => void;
  setIsLoggedIn: () => void;
  setIsLoggedOut: () => void;
  access: (params: { isInitializedByUser: boolean; isIframeAuth?: boolean }) => void;
}

const IframeContext = createContext<Partial<IframeContext>>({});

const IframeProvider = ({ children, ...props }: React.PropsWithChildren<IframeProviderProps>) => {
  const [isManuallyLogedIn, setIsManuallyLogedIn] = useState(false);

  const setIsLoggedIn = () => {
    setIsManuallyLogedIn(true);
  };

  const setIsLoggedOut = () => {
    setIsManuallyLogedIn(false);
  };

  const {
    access,
    isAuthInitialized,
    isRedirectedToDashboard,
    isAuthorizationPending,
    redirectToLogoutPage,
  } = useCookieAuth({ setIsLoggedIn });

  return (
    <IframeContext.Provider
      value={{
        ...props,
        isManuallyLogedIn,
        isRedirectedToDashboard,
        isAuthorizationPending,
        isAuthInitialized,
        redirectToLogoutPage,
        setIsLoggedIn,
        setIsLoggedOut,
        access,
      }}
    >
      {children}
    </IframeContext.Provider>
  );
};

export const useIframeContext = () => {
  const context = useContext(IframeContext);
  if (!context) {
    throw new Error('useIframeContext must be used under IframeProvider');
  }
  return context;
};

export default IframeProvider;
