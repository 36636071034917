import { CcpMenuPosition } from 'types/CcpMenuPosition';
import { Issue } from 'types/Issue';
import { IssueCategory } from 'types/IssueCategory';
import { IssueStatusType } from 'types/IssueStatusType';
import { Nullable } from 'types/Nullable';
import { CcpKitchen } from 'types/CcpKitchen';
import { Region } from 'types/Region';

export const MAM_PAGE_SIZE = 50;

export enum IssueResolveStatuses {
  ORDER_ACCEPTED_REPLACEMENT = 'ORDER_ACCEPTED_REPLACEMENT',
  ORDER_PROCESSED_WITHOUT_ITEM = 'ORDER_PROCESSED_WITHOUT_ITEM',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  REQUEST_CANCELLED = 'REQUEST_CANCELLED',
  ORDER_PROCESSED_WITHOUT_FOLLOWING_NOTE = 'ORDER_PROCESSED_WITHOUT_FOLLOWING_NOTE',
  NOTE_CLARIFIED = 'NOTE_CLARIFIED',
  ORDER_PUNCHED = 'ORDER_PUNCHED',
  CUSTOMER_REFUSED = 'CUSTOMER_REFUSED',
  CUSTOMER_NO_RESPONSE = 'CUSTOMER_NO_RESPONSE',
  CUSTOMER_MISSING_CONTACT_DATA = 'CUSTOMER_MISSING_CONTACT_DATA',
  ORDER_REJECTED = 'ORDER_REJECTED',
}

export enum Refund {
  FULL_REFUND = 'FULL_REFUND',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  WALLET_REFUND = 'WALLET_REFUND',
}

export interface IssuesStatistics {
  assignedCount: number;
  unresolvedCount: number;
}

export interface SearchIssuesFilters {
  issueCategories?: IssueCategory[];
  issueSorting?: string;
  issueStatuses: IssueStatusType[];
  regions?: Region[];
  kitchens?: CcpKitchen[];
}

export interface SnackbarState {
  isOpen?: boolean;
  isSubscribed?: boolean;
}

export interface RequestCancelledPayload {
  status: IssueResolveStatuses;
  comment: string;
}

export interface OrderCancelledPayload extends RequestCancelledPayload {
  nextOrderDiscounted: boolean;
  refundType: Refund;
}

export interface OrderAcceptedReplacementPayload extends RequestCancelledPayload {
  refundType?: Nullable<Refund>;
  orderRefunded: boolean;
  nextOrderDiscounted: boolean;
  proposedRefundAmount?: string;
}

export type ResolveIssuePayload =
  | OrderAcceptedReplacementPayload
  | OrderCancelledPayload
  | RequestCancelledPayload;

export interface ResolveIssueFunction<T extends ResolveIssuePayload> {
  (payload: T): Promise<void>;
}

export interface CancelOrderPayload {
  cancelReason: string;
  isScheduledForFullRefund?: boolean;
}

export interface CancelOrderFunction {
  (payload: CancelOrderPayload): Promise<void>;
}

export type ManualActionIssue = Issue;

export interface ManualActionsState {
  isLoading: boolean;
  issuesById: Record<string, ManualActionIssue>;
  issues: ManualActionIssue[];
  issueExceedLimitedTime: boolean;
  selectedIssue: Nullable<ManualActionIssue>;
  selectedPage: number;
  totalCount: number;
  searchIssuesFilters: SearchIssuesFilters;
  positions: Record<string, CcpMenuPosition[]>;
  silently: boolean;
  statistics: IssuesStatistics;
  snackbar: SnackbarState;
  currentlyProcessingIssue: Record<string, boolean>;
}
