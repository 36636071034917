import { Order } from 'types/Order';
import Big from 'big.js';
import { createNextState } from '@reduxjs/toolkit';
import * as walletsService from 'service/wallets.service';
import { OrderValidationResult } from 'types/OrderValidationResult';
import { isNumber } from 'lodash';

export const addWalletPointsToOrderSummary = (
  newOrderSummary: OrderValidationResult,
  currentOrder: Order,
  isPriceAdjusted: boolean
): Promise<OrderValidationResult> => {
  const { walletPaymentAmount } = currentOrder.orderPriceDetails;
  const { total, deliveryFee } = newOrderSummary.orderPriceDetails;

  if (!isNumber(walletPaymentAmount)) {
    return Promise.resolve(newOrderSummary);
  }

  const handleWalletsServiceError = () => {
    const totalWithoutDeliveryFee = Number(new Big(total).minus(deliveryFee));

    return Math.min(walletPaymentAmount, totalWithoutDeliveryFee);
  };

  const mergeOrderSummaryWithWalletPoints = (walletPoints: number) =>
    createNextState(newOrderSummary, newOrderSummaryDraft => {
      newOrderSummaryDraft.orderPriceDetails.walletPaymentAmount = walletPoints;
      newOrderSummaryDraft.orderPriceDetails.total = Number(
        new Big(newOrderSummaryDraft.orderPriceDetails.total).minus(walletPoints)
      );
    });

  return walletsService
    .getWalletPointsAmountDueForTheOrder({
      orderId: currentOrder.id,
      orderTotal: total,
      orderDeliveryFee: deliveryFee,
      isPriceAdjusted,
    })
    .then(({ walletAmount }) => walletAmount)
    .catch(handleWalletsServiceError)
    .then(mergeOrderSummaryWithWalletPoints);
};
