import { Button, DialogActions } from '@mui/material';
interface FeatureTogglesActionsProps {
  areFeaturesChanged: boolean;
  closeDialog: () => void;
  onApply: () => void;
}

export const FeatureTogglesActions = ({
  areFeaturesChanged,
  closeDialog,
  onApply,
}: FeatureTogglesActionsProps) => {
  return (
    <DialogActions>
      {!areFeaturesChanged && (
        <Button
          color="default"
          onClick={closeDialog}
          data-testid="feature-toggles-close-modal-button"
        >
          Close
        </Button>
      )}
      {areFeaturesChanged && (
        <Button color="primary" onClick={onApply} data-testid="feature-toggles-apply-button">
          Apply
        </Button>
      )}
    </DialogActions>
  );
};
