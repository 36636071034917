import { combineReducers } from '@reduxjs/toolkit';

import { i18nReducer } from 'react-redux-i18n';
import { settings } from 'store/settings/settings.reducer';
import { metadata } from 'store/metadata/metadata.reducer';
import { punchOrderFlow } from 'store/punchOrderFlow/punchOrderFlow.reducer';
import { punchOrderFlowDiscounts } from 'store/punchOrderFlowDiscounts/punchOrderFlowDiscounts.reducer';
import { orders } from 'store/orders/orders.reducer';
import { wallets } from 'store/wallets/wallets.reducer';
import { manualActions } from 'store/manualActions/manualActions.reducer';
import { orderEdition } from 'store/orderEdition/orderEdition.reducer';
import { customers } from 'store/customers/customers.reducer';
import { punchOrderFlowNext } from 'store/punchOrderFlowNext/punchOrderFlow.reducer';

export const rootReducer = combineReducers({
  i18n: i18nReducer,
  metadata,
  punchOrderFlow,
  punchOrderFlowNext,
  punchOrderFlowDiscounts,
  orders,
  manualActions,
  settings,
  orderEdition,
  wallets,
  customers,
});
