import flatten from 'lodash/flatten';
import isNumber from 'lodash/isNumber';
import {
  SelectedGroupAddon,
  SelectedGroupMenuItem,
  SelectedPosition,
  SelectedPositionAddon,
} from 'types/SelectedPosition';
import { CcpMenuCombo } from 'types/CcpMenuCombo';
import Big, { BigSource } from 'big.js';
import { Discount } from 'types/Discount';
import { DiscountValueType } from 'types/DiscountValueType';
import { CcpMenuItem } from 'types/CcpMenuItem';
import { OrderPosition } from 'types/OrderPosition';
import { OrderAddon } from 'types/OrderAddon';
import { OrderComboGroupItem } from 'types/OrderComboGroupItem';
import { OrderPriceDetails } from 'types/OrderPriceDetails';
import { OrderPriceDetailsTax } from 'types/OrderPriceDetailsTax';
import { OrderComboGroup } from 'types/OrderComboGroup';
import { OrderPriceAdjustments } from 'types/OrderPriceAdjustments';
import { OrderStatus } from 'types/OrderStatus';
import { applyTaxRate } from 'utils/price';
import { SelectedPositionPrice } from 'types/SelectedPositionPrice';
import { PriceType } from 'types/PriceType';
import { CorrectionCombo } from 'types/CorrectionCombo';
import { CorrectionComboGroup } from 'types/CorrectionComboGroup';
import { CorrectionComboGroupItem } from 'types/CorrectionComboGroupItem';
import { CorrectionAddon } from 'types/CorrectionAddon';
import { CorrectionItem } from 'types/CorrectionItem';

export const getSelectedPositionNetPrice = ({
  isEdited,
  menuNetPrice,
  netPrice,
}: SelectedPositionPrice): number => (isEdited ? Number(netPrice) : menuNetPrice);

const getPositionsTotalPrice = (
  positions: Array<SelectedPositionAddon | SelectedGroupMenuItem | SelectedGroupAddon>
) =>
  positions.reduce(
    (acc, position) => acc.plus(getSelectedPositionNetPrice(position.priceOverride)),
    new Big(0)
  );

export const getPositionWithCustomizationsPrice = (
  position: SelectedPosition,
  positionsDetails: Record<string, CcpMenuItem | CcpMenuCombo>
): Big => {
  const addonsPrice = getPositionsTotalPrice(position.addons);

  const groupsPrice = Object.keys(position.groups).reduce((acc, groupID) => {
    const currentPositionDetails = positionsDetails[position.masterId];
    if (!currentPositionDetails || !('groups' in currentPositionDetails)) {
      return acc;
    }

    const groupDetails = currentPositionDetails.groups.find(group => group.masterId === groupID);
    const group = position.groups[groupID];
    const groupPositions = group.positions;

    if (groupDetails?.priceType === PriceType.ITEM_PRICE) {
      return acc.plus(getPositionsTotalPrice(groupPositions));
    }

    return acc;
  }, new Big(0));

  return new Big(getSelectedPositionNetPrice(position.priceOverride))
    .plus(addonsPrice)
    .plus(groupsPrice);
};

const getExistingCustomizationsPrice = (
  customizations?:
    | Array<OrderAddon | OrderComboGroupItem | OrderComboGroup>
    | Array<CorrectionAddon | CorrectionComboGroup | CorrectionComboGroupItem>
): Big => {
  let result = new Big(0);
  if (customizations) {
    for (const customization of customizations) {
      if (typeof customization.netPrice === 'number') {
        result = result.plus(
          new Big(customization.netPrice).mul(
            'quantity' in customization ? Math.abs(customization.quantity) : 1
          )
        );
      }
    }
  }
  return result;
};

export const getExistingPositionWithCustomizationsPrice = (
  position: OrderPosition | CorrectionItem | CorrectionCombo
): Big => {
  let result = new Big(0);

  if ('item' in position && position.item) {
    result = result
      .plus(getExistingCustomizationsPrice(position.item.addons))
      .plus(position.item.netPrice || 0);
  }

  if ('combo' in position && position.combo) {
    result = result
      .plus(getExistingCustomizationsPrice(position.combo.comboGroups))
      .plus(
        'addons' in position.combo && position.combo.addons
          ? getExistingCustomizationsPrice(position.combo.addons)
          : 0
      )
      .plus(position.combo.netPrice || 0);

    for (const group of position.combo.comboGroups) {
      result = result
        .plus(getExistingCustomizationsPrice(group.addons))
        .plus(getExistingCustomizationsPrice(group.items));
    }
  }

  return result.mul(Math.abs(position.quantity));
};

export const calculateOrderSubtotal = (
  selectedPositionsByBrandId: Record<string, SelectedPosition[]>,
  positionsDetails: Record<string, CcpMenuItem | CcpMenuCombo>
): number => {
  const allSelectedPositions = flatten(Object.values(selectedPositionsByBrandId));

  const subtotal = allSelectedPositions.reduce((acc, position) => {
    return acc.plus(getPositionWithCustomizationsPrice(position, positionsDetails));
  }, new Big(0));

  return Number(subtotal);
};

export const getPositionPrice = (
  quantity: number,
  price?: number,
  parentQuantity?: number
): number | undefined => {
  if (!isNumber(price)) {
    return;
  }

  if (parentQuantity) {
    return Number(new Big(price).mul(quantity).mul(parentQuantity));
  }

  return Number(new Big(price).mul(quantity));
};

export const calculatePositionDiscounts = (
  selectedPositions: Record<string, SelectedPosition[]>,
  selectedPositionDiscounts: Record<string, Discount>,
  positionsDetails: Record<string, CcpMenuItem | CcpMenuCombo>,
  orderSubtotal: number
): Record<string, number[]> => {
  const result: Record<string, number[]> = {};
  const discountUsage: Record<string, Big> = {};

  for (const brandId of Object.keys(selectedPositions)) {
    result[brandId] = selectedPositions[brandId].map(selectedPosition => {
      const discount = selectedPositionDiscounts[selectedPosition.id];

      if (!discount || (discount.minimumSpend && discount.minimumSpend > orderSubtotal)) {
        return 0;
      }

      if (!discountUsage[discount.id]) {
        discountUsage[discount.id] = new Big(0);
      }

      const maxAvailableDiscountValue = discount.maximumDiscountValue
        ? new Big(discount.maximumDiscountValue).minus(discountUsage[discount.id])
        : undefined;

      if (discount.itemDiscountDetails) {
        if (discount.itemDiscountDetails.type === DiscountValueType.PERCENTAGE) {
          const originalPrice = getPositionWithCustomizationsPrice(
            selectedPosition,
            positionsDetails
          );
          const discountRate = new Big(discount.itemDiscountDetails.value).div(100);
          const maximumDiscount = originalPrice.mul(discountRate);
          let finalDiscountValue = maximumDiscount;

          if (maxAvailableDiscountValue && maximumDiscount.gt(maxAvailableDiscountValue)) {
            finalDiscountValue = maxAvailableDiscountValue;
          }

          discountUsage[discount.id] = discountUsage[discount.id].plus(finalDiscountValue);

          return Number(finalDiscountValue);
        }
      }

      return 0;
    });
  }

  return result;
};

export const calculateProportionalPositionItemDiscount = (
  positionDiscount?: BigSource,
  itemPrice?: BigSource,
  positionWithCustomizationsPrice?: BigSource
): Big => {
  if (!positionDiscount || !itemPrice || !positionWithCustomizationsPrice) {
    return Big(0);
  }

  return Number(positionWithCustomizationsPrice)
    ? new Big(positionDiscount).mul(itemPrice).div(positionWithCustomizationsPrice)
    : new Big(0);
};

export const orderStatusesToDisplay: {
  [key in keyof typeof OrderStatus]: string;
} = {
  [OrderStatus.CREATED]: 'Created',
  [OrderStatus.ACCEPTED]: 'Preparing',
  [OrderStatus.PREPARED]: 'Packing',
  [OrderStatus.PACKED]: 'Ready to pickup',
  [OrderStatus.DISPATCHED]: 'Dispatched',
  [OrderStatus.IN_DELIVERY]: 'In delivery',
  [OrderStatus.DELIVERED]: 'Delivered',
  [OrderStatus.CANCELLED]: 'Cancelled',
  [OrderStatus.CLOSED]: 'Closed',
  [OrderStatus.DRAFT]: 'Draft',
};

const applyDeliveryTax = (value: number, deliveryFeeTax: OrderPriceDetailsTax): number => {
  return applyTaxRate(value, Number(deliveryFeeTax.taxRate));
};

export const getOriginalOrderDeliveryFee = (orderPriceDetails: OrderPriceDetails): number => {
  const { deliveryNetFee, deliveryFeeTax } = orderPriceDetails;

  return applyDeliveryTax(deliveryNetFee, deliveryFeeTax);
};

export const getDeliveryFeeDiscount = (orderPriceDetails: OrderPriceDetails): number => {
  const { deliveryFeeTax, deliveryNetFeeDiscount } = orderPriceDetails;

  return applyDeliveryTax(deliveryNetFeeDiscount, deliveryFeeTax);
};

export const createPriceAdjustments = ({
  isPriceDiscrepancy,
  expectedTotal,
  expectedTotalDiscountName,
  isScheduledForPartialRefund,
}: {
  isPriceDiscrepancy: boolean;
  expectedTotal: string;
  expectedTotalDiscountName?: string | null;
  isScheduledForPartialRefund?: boolean;
}): OrderPriceAdjustments | undefined => {
  if (!isPriceDiscrepancy || !expectedTotal) {
    return;
  }

  return {
    total: Number(expectedTotal),
    discountNames: expectedTotalDiscountName ? [expectedTotalDiscountName] : undefined,
    isScheduledForPartialRefund,
  };
};
