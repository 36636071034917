import { togglePunchingOrderGrossPrice, toggleOrderDetailGrossPrice } from './settings.actions';

import { createReducer } from '@reduxjs/toolkit';

export interface SettingsState {
  isPunchingOrderGrossPrice: boolean;
  isOrderDetailGrossPrice: boolean;
}

const initialState: SettingsState = {
  isPunchingOrderGrossPrice: true,
  isOrderDetailGrossPrice: true,
};

export const settings = createReducer(initialState, builder => {
  builder
    .addCase(togglePunchingOrderGrossPrice, state => {
      state.isPunchingOrderGrossPrice = !state.isPunchingOrderGrossPrice;
    })
    .addCase(toggleOrderDetailGrossPrice, state => {
      state.isOrderDetailGrossPrice = !state.isOrderDetailGrossPrice;
    });
});
