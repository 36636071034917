import noop from 'lodash/noop';
import { createContext } from 'react';
import { Features } from './FeatureTogglesProvider.types';
import { featureTogglesDefaultValues } from './FeatureTogglesDefaultValues';
import {
  featureFlagDescriptions,
  FeatureFlags,
  ALL_FEATURE_FLAGS,
  ENVIRONMENT,
  Environment,
} from 'utils/constants';

interface FeatureTogglesContext {
  readonly features: FeatureFlags;
  readonly isTogglingAllowed: boolean;
  readonly environmentsFeatureFlags: Record<string, FeatureFlags>;
  readonly environments: string[];
  readonly currentEnvironment: Environment;
  readonly featureFlagDescriptions: typeof featureFlagDescriptions;
  readonly setFeatures: (updater: (previousState: Features) => Features) => void;
}

const defaultContext: FeatureTogglesContext = {
  features: featureTogglesDefaultValues,
  environmentsFeatureFlags: ALL_FEATURE_FLAGS,
  currentEnvironment: ENVIRONMENT,
  setFeatures: noop,
  isTogglingAllowed: false,
  environments: [],
  featureFlagDescriptions,
};

export const FeatureTogglesContext = createContext(defaultContext);
