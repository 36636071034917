import { DialogTitle, Stack, Typography, Box, Chip } from '@mui/material';
import { useFeatureToggles } from 'context/FeatureTogglesContext/useFeatureToggles';
import { isEqual } from 'lodash';
import { FeatureFlags } from 'utils/constants';

interface FeatureTogglesHeaderProps {
  onResetAll: (featureFlags?: FeatureFlags) => void;
  currentState: FeatureFlags;
}

export const FeatureTogglesHeader = ({ onResetAll, currentState }: FeatureTogglesHeaderProps) => {
  const { environments, environmentsFeatureFlags, currentEnvironment } = useFeatureToggles();
  return (
    <DialogTitle>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Box>
          <Typography gutterBottom variant="h6" component="div">
            Feature toggles
          </Typography>
          <Typography variant="caption" component="div" data-testid="feature-toggles-current-env">
            Current environment: {currentEnvironment}
          </Typography>
          <Typography variant="caption" component="div" data-testid="feature-toggles-envs">
            Local toggling available on: {environments.join(', ')}
          </Typography>
        </Box>

        <Box mr={2} display="flex" alignItems="center">
          <Box mr={2}>Simulated environments:</Box>
          <Box mr={2}>
            <Stack direction="row" spacing={1}>
              {Object.entries(environmentsFeatureFlags).map(([envName, val]) => {
                const isActive = isEqual(val, currentState);
                return (
                  <Chip
                    sx={{ textTransform: 'uppercase' }}
                    key={envName}
                    color={isActive ? 'secondary' : 'default'}
                    variant="outlined"
                    onClick={() => onResetAll(val)}
                    label={envName}
                    clickable
                    size="small"
                    data-testid={`reset-features-to-${envName}`}
                  />
                );
              })}
            </Stack>
          </Box>
        </Box>
      </Stack>
    </DialogTitle>
  );
};
