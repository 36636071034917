import enLocale from 'date-fns/locale/en-US';
import { merge } from 'lodash';
import { buildFormatLongFn } from 'utils/buildFormatLongFn';
import { IssueResolveStatuses, Refund } from 'store/manualActions/manualActions.types';
import { IssueCategory } from 'types/IssueCategory';
import { OrderType } from 'types/OrderType';
import { OrderDetailsPaymentMethod } from 'types/OrderDetailsPaymentMethod';

export const enUS = {
  mam: {
    issues: {
      [IssueCategory.DELIVERY_FAILURE]: 'Delivery Partner',
      [IssueCategory.ONGOING_ORDER_ISSUE]: 'Replacement',
      [IssueCategory.ORDER_POTENTIAL_FRAUD]: 'Possible fraud',
      [IssueCategory.ORDER_NOTE_ISSUE]: 'Order notes',
      [IssueCategory.ORDER_REJECTION]: 'Order rejection',
      [IssueCategory.REJECTION_ORDER_PUNCHING]: 'Rejection order punching',
    },
    resolveReasons: {
      [IssueResolveStatuses.ORDER_ACCEPTED_REPLACEMENT]: 'Order items replaced',
      [IssueResolveStatuses.ORDER_PROCESSED_WITHOUT_ITEM]: 'Order processed without item',
      [IssueResolveStatuses.ORDER_CANCELLED]: 'Order cancelled',
      [IssueResolveStatuses.ORDER_REJECTED]: 'Order cancelled',
      [IssueResolveStatuses.REQUEST_CANCELLED]: 'Request cancelled',
      [IssueResolveStatuses.NOTE_CLARIFIED]: 'Note clarified',
      [IssueResolveStatuses.ORDER_PROCESSED_WITHOUT_FOLLOWING_NOTE]:
        'Order processed without following note',
      [IssueResolveStatuses.ORDER_PUNCHED]: 'Order punched',
      [IssueResolveStatuses.CUSTOMER_REFUSED]: 'Customer refused direct order',
      [IssueResolveStatuses.CUSTOMER_NO_RESPONSE]: 'No response from customer',
      [IssueResolveStatuses.CUSTOMER_MISSING_CONTACT_DATA]: 'No customer contact details',
    },
    refunds: {
      [Refund.FULL_REFUND]: 'Full refund',
      [Refund.PARTIAL_REFUND]: 'Partial refund',
      [Refund.WALLET_REFUND]: 'Wallet refund',
    },
  },
  menu: {
    newOrder: 'New order',
    ordersList: 'Orders',
    customers: 'Customers',
    loyaltyPoints: 'Loyalty points',
    manualActions: 'Manual actions',
    experiments: 'Experiments',
  },
  common: {
    save: 'Save',
    add: 'add',
    addMore: 'add more',
    addNew: 'add new',
    cancel: 'Cancel',
    edit: 'edit',
    name: 'name',
    description: 'description',
    publish: 'publish',
    delete: 'delete',
    previousVersion: 'show previous version',
    editPublishedMenu: 'edit published menu',
    close: 'close',
    hide: 'hide',
    submit: 'submit',
    yes: 'yes',
    no: 'no',
  },
  paymentMethod: {
    [OrderDetailsPaymentMethod.CARD]: 'Card',
    [OrderDetailsPaymentMethod.CASH]: 'Cash',
    [OrderDetailsPaymentMethod.PAID_ONLINE]: 'Online',
  },
  orderTypeOptions: {
    [OrderType.DELIVERY]: 'Delivery',
    [OrderType.DINE_IN]: 'Dine in',
    [OrderType.TAKE_AWAY]: 'Take away',
  },
  components: {
    orderDetails: {
      dineInDetails: {
        cardTitle: 'Dine in',
        editButton: 'Edit',
        tableNumberField: 'Table number',
        customersNumberField: 'Customers number',
        successfulUpdateMessage: 'Order dine in data was sucessfully updated',
      },
    },
  },
  errors: {
    integer: 'Must be an integer',
    minNumber: 'Must be at least %{number}',
    maxNumber: 'Must be at most %{number}',
    maxStringLength: 'Must contain at most %{number} characters',
    printingNotSupported: 'Printing is not supported, data does not provide a valid template',
  },
};

export const enUsDateLocale = merge(enLocale, {
  formatLong: {
    date: buildFormatLongFn({
      formats: {
        full: 'EEEE, MMMM do, y',
        long: 'MMMM do, y',
        medium: 'MMM d, y',
        short: 'dd/MM/yyyy',
      },
      defaultWidth: 'full',
    }),
    time: buildFormatLongFn({
      formats: {
        full: 'HH:mm:ss zzzz',
        long: 'HH:mm:ss z',
        medium: 'HH:mm:ss',
        short: 'HH:mm',
      },
      defaultWidth: 'full',
    }),
    dateTime: buildFormatLongFn({
      formats: {
        full: `{{date}} 'at' {{time}}`,
        long: `{{date}} 'at' {{time}}`,
        medium: '{{date}}, {{time}}',
        short: '{{date}} {{time}}',
      },
      defaultWidth: 'full',
    }),
  },
});
