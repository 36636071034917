import { useContext } from 'react';
import { FeatureTogglesContext } from './FeatureTogglesContext';

export const useFeatureToggles = () => {
  const context = useContext(FeatureTogglesContext);

  if (context === undefined) {
    throw new Error('useFeatureToggles must be used within a FeatureTogglesProvider');
  }

  return context;
};
