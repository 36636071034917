import { createReducer } from '@reduxjs/toolkit';
import {
  getCustomer,
  getCustomerOrders,
  loadCustomersList,
  setCustomerOrdersPage,
  setCustomerOrdersCustomerId,
  updateCustomer,
  createCustomerAddress,
  updateCustomerAddress,
  createNewCustomer,
  getJiraComplaintsOrders,
  setCustomerData,
  resetCustomer,
} from './customers.actions';
import { CustomersState } from './customers.types';

export const initialState: CustomersState = {
  isLoadingCustomersList: true,
  customers: [],
  customersPagesCount: 0,
  isLoadingCustomer: false,
  customer: null,
  isCustomerUpdating: false,
  isCustomerAddressCreating: false,
  isCustomerAddressUpdating: false,
  isCustomerCreating: false,
  customerOrders: {
    isLoadingCustomerOrders: false,
    orders: null,
    pagesCount: null,
    customerId: null,
    page: 1,
    perPage: 5,
  },
  jiraComplaintsOrders: {
    orders: null,
    isLoadingJiraComplaintsOrders: false,
  },
};

export const customers = createReducer(initialState, builder =>
  builder
    .addCase(loadCustomersList.pending, state => {
      state.isLoadingCustomersList = true;
    })
    .addCase(loadCustomersList.fulfilled, (state, { payload, meta }) => {
      state.isLoadingCustomersList = false;
      state.customers = payload.elements;
      state.customersPagesCount = Math.ceil(payload.totalCount / meta.arg.perPage);
    })
    .addCase(loadCustomersList.rejected, (state, { error }) => {
      if (error.name !== 'AbortError') {
        state.isLoadingCustomersList = false;
        state.customers = [];
        state.customersPagesCount = 0;
      }
    })

    .addCase(getCustomer.pending, state => {
      state.isLoadingCustomer = true;
    })
    .addCase(getCustomer.fulfilled, (state, { payload }) => {
      state.isLoadingCustomer = false;
      state.customer = payload;
    })
    .addCase(getCustomer.rejected, state => {
      state.isLoadingCustomer = false;
    })

    .addCase(setCustomerData, (state, { payload }) => {
      state.customer = payload;
    })

    .addCase(updateCustomer.pending, state => {
      state.isCustomerUpdating = true;
    })
    .addCase(updateCustomer.fulfilled, (state, { payload }) => {
      state.isCustomerUpdating = false;
      state.customer = payload;
    })
    .addCase(updateCustomer.rejected, state => {
      state.isCustomerUpdating = false;
    })

    .addCase(getCustomerOrders.pending, (state, { meta }) => {
      if (!meta.arg.silently) {
        state.customerOrders.isLoadingCustomerOrders = true;
      }
    })
    .addCase(getCustomerOrders.fulfilled, (state, { payload }) => {
      state.customerOrders.isLoadingCustomerOrders = false;
      state.customerOrders.orders = payload.orders;
      state.customerOrders.pagesCount = payload.pagesCount;
    })
    .addCase(getCustomerOrders.rejected, state => {
      state.customerOrders.isLoadingCustomerOrders = false;
    })

    .addCase(getJiraComplaintsOrders.pending, state => {
      state.jiraComplaintsOrders.isLoadingJiraComplaintsOrders = true;
    })
    .addCase(getJiraComplaintsOrders.fulfilled, (state, { payload }) => {
      state.jiraComplaintsOrders.isLoadingJiraComplaintsOrders = false;
      state.jiraComplaintsOrders.orders = payload.orders;
    })
    .addCase(getJiraComplaintsOrders.rejected, state => {
      state.jiraComplaintsOrders.isLoadingJiraComplaintsOrders = false;
    })

    .addCase(setCustomerOrdersPage, (state, { payload }) => {
      state.customerOrders.page = payload;
    })

    .addCase(setCustomerOrdersCustomerId, (state, { payload }) => {
      state.customerOrders.customerId = payload;
    })

    .addCase(createCustomerAddress.pending, state => {
      state.isCustomerAddressCreating = true;
    })

    .addCase(createCustomerAddress.rejected, state => {
      state.isCustomerAddressCreating = false;
    })

    .addCase(createCustomerAddress.fulfilled, (state, { meta, payload }) => {
      state.isCustomerAddressCreating = false;
      const address = meta?.arg.address;
      if (address) {
        state.customer?.addresses.push({
          additionalAddressInstructions: address.additionalAddressInstructions,
          addressLine1: address.addressLine1 ?? '',
          addressLine2: address.addressLine2,
          addressType: address.addressType,
          customAddressLabel: address.customAddressLabel ?? '',
          kitopiDeliveryAreaId: address.kitopiDeliveryAreaId,
          postCode: address.postCode,
          id: payload?.resourceId,
          defaultAddress: false,
          kitopiDeliveryAreaName: '',
          coordinates: address.coordinates,
        });
      }
    })

    .addCase(updateCustomerAddress.pending, state => {
      state.isCustomerAddressUpdating = true;
    })

    .addCase(updateCustomerAddress.rejected, state => {
      state.isCustomerAddressUpdating = false;
    })

    .addCase(updateCustomerAddress.fulfilled, (state, { meta }) => {
      state.isCustomerAddressUpdating = false;
      if (state.customer?.addresses) {
        const address = state.customer.addresses.find(
          address => address.id === meta.arg.customerAddressId
        );

        if (address) {
          address.additionalAddressInstructions =
            meta.arg.address?.additionalAddressInstructions ?? '';
          address.addressLine1 = meta.arg.address?.addressLine1 ?? '';
          address.addressLine2 = meta.arg.address?.addressLine2 ?? '';
          address.addressType = meta.arg.address?.addressType;
          address.customAddressLabel = meta.arg.address?.customAddressLabel ?? '';
          address.kitopiDeliveryAreaId = meta.arg.address?.kitopiDeliveryAreaId;
          address.postCode = meta.arg.address?.postCode;
          address.coordinates = meta.arg.address?.coordinates;
        }
      }
    })

    .addCase(createNewCustomer.pending, state => {
      state.isCustomerCreating = true;
    })

    .addCase(createNewCustomer.rejected, state => {
      state.isCustomerCreating = false;
    })

    .addCase(createNewCustomer.fulfilled, state => {
      state.isCustomerCreating = false;
    })
    .addCase(resetCustomer, state => {
      state.customer = null;
    })
);
