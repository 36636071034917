import { useTheme } from '@mui/material';
import { SVGProps } from 'react';

interface KitopiLogoProps extends SVGProps<SVGSVGElement> {
  readonly logoTypeColor?: string;
  readonly logoMarkColor?: string;
}

export const KitopiLogo = ({ logoTypeColor, logoMarkColor, ...otherProps }: KitopiLogoProps) => {
  const theme = useTheme();
  return (
    <svg viewBox="0 200 840 420" {...otherProps} data-testid="kitopi-logo">
      <g fill={logoTypeColor ?? theme.palette.text.primary}>
        <path d="M212,602.32l-33-44.63-15.5,13v31.61H143.68V513.81h19.83V550.5l43.64-36.69h24L193,546l41.9,56.28Z" />
        <path d="M267.52,602.32V513.81h19.83v88.51Z" />
        <path d="M354.41,602.32v-72H319.7V513.81H409V530.3H374.25v72Z" />
        <path d="M535,558.07q0,14-6.13,24.48a41.31,41.31,0,0,1-17.73,16.24q-11.6,5.76-27.58,5.76-15.37,0-26.84-5.51a41.34,41.34,0,0,1-17.85-16q-6.39-10.47-6.39-25,0-14,6.14-24.49a41.31,41.31,0,0,1,17.73-16.24Q468,511.58,484,511.58q15.36,0,26.84,5.52a41.37,41.37,0,0,1,17.85,16Q535,543.56,535,558.07Zm-20.58,0q0-14.39-8.05-22.32t-22.69-7.93q-14.5,0-22.56,8t-8.06,22.26q0,14.37,8.06,22.31t22.68,7.93q14.51,0,22.57-8T514.45,558.07Z" />
        <path d="M571.23,602.32V513.81h43q37.18,0,37.19,29.13,0,14.88-9.55,22.13t-29.13,7.25H590.82v30Zm41.41-45.49q9.92,0,14.5-3.41t4.59-10.6q0-7.07-4.47-10.29t-14.38-3.23H590.82v27.53Z" />
        <path d="M686.52,602.32V513.81h19.83v88.51Z" />
      </g>
      <g fill={logoMarkColor ?? theme.palette.primary.main}>
        <polygon points="425.01 275.68 461.05 233.55 513.73 233.55 458.67 297.24 469.83 310.75 551.5 216.26 453.08 216.26 402.45 275.47 511.58 408.02 458.36 408.02 430.33 374.6 418.92 387.88 450.3 425.3 548.19 425.3 425.01 275.68" />
        <polygon points="442.05 342.78 430.93 329.33 383.84 383.93 383.84 408.02 337.02 408.02 337.02 233.55 376.34 233.55 376.34 373.77 393.62 353.75 393.62 216.26 319.75 216.26 319.75 425.3 401.12 425.3 401.12 390.24 442.05 342.78" />
      </g>
    </svg>
  );
};
