import { createReducer } from '@reduxjs/toolkit';
import {
  addPosition,
  changePaymentMethod,
  clearAvailableDiscounts,
  destroyOrderEditionEntry,
  getAvailableDiscounts,
  getOrderSummary,
  initializeDiscounts,
  initializeEdition,
  modifyPositionQuantity,
  removeExistingPosition,
  removePosition,
  restoreExistingPosition,
  setIsComboValid,
  setDeliveryDiscount,
  setIsPositionLevelDiscount,
  setOrderLevelDiscounts,
  setPositionLevelDiscount,
  updateComboDetails,
  updateItemDetails,
  setIsPriceDiscrepancy,
  setExpectedTotalDiscountName,
  setExpectedTotal,
  setIsScheduledForPartialRefund,
  setPositionPriceAdjustment,
} from './orderEdition.actions';
import {
  isExistingPosition,
  isNewPosition,
  OrderEditionEntry,
  OrderEditionState,
} from './orderEdition.types';
import { generateRandomString } from 'utils/string';
const initialState: OrderEditionState = {
  orders: {},
};

const getOrderEditionEntry = (state: OrderEditionState, orderId: string): OrderEditionEntry => {
  if (orderId in state.orders) {
    return state.orders[orderId];
  }

  const orderEntry: OrderEditionEntry = {
    isLoadingSummary: false,
    isUpdatingPaymentMethod: false,
    availableDiscountsLoaded: false,
    discountsInitialized: false,
    availableDiscounts: [],
    isLoadingAvailableDiscounts: false,
    availableDiscountsMenuMasterIds: [],
    selectedOrderLevelDiscounts: [],
    selectedPositionLevelDiscounts: {},
    orderBody: {},
    isPositionLevelDiscount: false,
    isChanged: false,
    isComboValid: {},
    isPriceDiscrepancy: false,
    expectedTotal: '',
    expectedTotalDiscountName: null,
    isScheduledForPartialRefund: false,
    canChangeScheduledForPartialRefund: false,
    initialDiscounts: {
      selectedOrderLevelDiscounts: [],
      selectedPositionLevelDiscounts: {},
    },
  };

  state.orders[orderId] = orderEntry;

  return orderEntry;
};

export const orderEdition = createReducer(initialState, builder =>
  builder
    .addCase(initializeEdition, (state, { payload }) => {
      const orderEditionEntry = getOrderEditionEntry(state, payload.orderId);
      orderEditionEntry.orderBody = payload.orderBody;
      orderEditionEntry.isPriceDiscrepancy = payload.isPriceDiscrepancy;
      orderEditionEntry.expectedTotal = payload.expectedTotal;
      orderEditionEntry.expectedTotalDiscountName = payload.expectedTotalDiscountName;
      orderEditionEntry.isScheduledForPartialRefund = !!payload.isScheduledForPartialRefund;
      orderEditionEntry.canChangeScheduledForPartialRefund = !payload.isScheduledForPartialRefund;
    })

    .addCase(getOrderSummary.pending, (state, { meta }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.order.id);
      orderEditionEntry.isLoadingSummary = true;
      delete orderEditionEntry.summaryValidationError;
    })
    .addCase(getOrderSummary.rejected, (state, { meta, error }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.order.id);
      orderEditionEntry.isLoadingSummary = false;

      if (error.message && error.name !== 'AbortError') {
        orderEditionEntry.summaryValidationError = error.message;
        delete orderEditionEntry.summary;
      }
    })
    .addCase(getOrderSummary.fulfilled, (state, { meta, payload }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.order.id);
      orderEditionEntry.isLoadingSummary = false;
      orderEditionEntry.summary = payload;
    })

    .addCase(changePaymentMethod.pending, (state, { meta }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.orderId);
      orderEditionEntry.isUpdatingPaymentMethod = true;
    })
    .addCase(changePaymentMethod.rejected, (state, { meta }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.orderId);
      orderEditionEntry.isUpdatingPaymentMethod = false;
    })
    .addCase(changePaymentMethod.fulfilled, (state, { meta }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.orderId);
      orderEditionEntry.isUpdatingPaymentMethod = false;
    })

    .addCase(getAvailableDiscounts.pending, (state, { meta }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.orderId);
      orderEditionEntry.isLoadingAvailableDiscounts = true;
      orderEditionEntry.availableDiscountsMenuMasterIds = meta.arg.menuMasterIds;
    })
    .addCase(getAvailableDiscounts.rejected, (state, { meta }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.orderId);
      orderEditionEntry.isLoadingAvailableDiscounts = false;
      orderEditionEntry.availableDiscountsMenuMasterIds = [];
    })
    .addCase(getAvailableDiscounts.fulfilled, (state, { meta, payload }) => {
      const orderEditionEntry = getOrderEditionEntry(state, meta.arg.orderId);
      orderEditionEntry.isLoadingAvailableDiscounts = false;
      orderEditionEntry.availableDiscounts = payload;
      orderEditionEntry.availableDiscountsLoaded = true;
    })

    .addCase(clearAvailableDiscounts, (state, { payload }) => {
      const orderEditionEntry = getOrderEditionEntry(state, payload);
      orderEditionEntry.availableDiscounts = [];
      orderEditionEntry.availableDiscountsMenuMasterIds = [];
    })

    .addCase(addPosition, (state, { payload }) => {
      const { orderId, brandId, position } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      const positionTempId = generateRandomString();

      orderEditionEntry.orderBody[brandId].positions[positionTempId] = {
        ...position,
        positionTempId,
      };
      orderEditionEntry.isChanged = true;
      delete orderEditionEntry.summary?.orderPositions;
    })
    .addCase(modifyPositionQuantity, (state, { payload }) => {
      const { orderId, brandId, positionTempId, quantity } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      const position = orderEditionEntry.orderBody[brandId].positions[positionTempId];

      position.quantity = quantity;
      orderEditionEntry.isChanged = true;
      delete orderEditionEntry.summary?.orderPositions;
    })
    .addCase(removeExistingPosition, (state, { payload }) => {
      const { orderId, brandId, positionTempId } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      const position = orderEditionEntry.orderBody[brandId].positions[positionTempId];

      if (isExistingPosition(position)) {
        position.isRemoved = true;
        orderEditionEntry.isChanged = true;
        delete orderEditionEntry.summary?.orderPositions;
      }
    })
    .addCase(removePosition, (state, { payload }) => {
      const { orderId, brandId, positionTempId } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      const position = orderEditionEntry.orderBody[brandId].positions[positionTempId];

      if (position.combo) {
        const { menuComboId } = position.combo;

        delete orderEditionEntry.isComboValid[menuComboId];
      }

      delete orderEditionEntry.orderBody[brandId].positions[positionTempId];
      orderEditionEntry.isChanged = true;
      delete orderEditionEntry.summary?.orderPositions;
    })

    .addCase(restoreExistingPosition, (state, { payload }) => {
      const { orderId, brandId, positionTempId } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      const position = orderEditionEntry.orderBody[brandId].positions[positionTempId];

      if (isExistingPosition(position)) {
        position.isRemoved = false;
        orderEditionEntry.isChanged = true;
        delete orderEditionEntry.summary?.orderPositions;
      }
    })

    .addCase(setPositionPriceAdjustment, (state, { payload }) => {
      const { orderId, brandId, positionTempId } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      const position = orderEditionEntry.orderBody[brandId].positions[positionTempId];
      position.adjustedPrice = payload.adjustedPrice;
      orderEditionEntry.isChanged = true;
    })

    .addCase(setIsComboValid, (state, { payload }) => {
      const { orderId, masterId } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.isComboValid[masterId] = payload.isComboValid;
    })

    .addCase(updateComboDetails, (state, { payload }) => {
      const { orderId, brandId, positionTempId, details, comboGroups } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      const position = orderEditionEntry.orderBody[brandId].positions[positionTempId];

      position.note = details.comboNote;

      if (isNewPosition(position) && position.combo) {
        position.combo.addons = details.selectedAddons;
        position.combo.comboGroups = comboGroups;
      }
      orderEditionEntry.isChanged = true;
      delete orderEditionEntry.summary?.orderPositions;
    })
    .addCase(updateItemDetails, (state, { payload }) => {
      const { orderId, brandId, positionTempId, details } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      const position = orderEditionEntry.orderBody[brandId].positions[positionTempId];

      position.note = details.itemNote;

      if (isNewPosition(position) && position.item) {
        position.item.addons = details.selectedAddons;
      }
      orderEditionEntry.isChanged = true;
      delete orderEditionEntry.summary?.orderPositions;
    })

    .addCase(initializeDiscounts, (state, { payload }) => {
      const {
        orderId,
        isPositionLevelDiscount,
        selectedPositionLevelDiscounts,
        selectedOrderLevelDiscounts,
        selectedDeliveryDiscount,
      } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.discountsInitialized = true;
      orderEditionEntry.isPositionLevelDiscount = isPositionLevelDiscount;
      orderEditionEntry.selectedPositionLevelDiscounts = selectedPositionLevelDiscounts;
      orderEditionEntry.selectedOrderLevelDiscounts = selectedOrderLevelDiscounts;
      orderEditionEntry.selectedDeliveryDiscount = selectedDeliveryDiscount;
      orderEditionEntry.initialDiscounts = {
        selectedPositionLevelDiscounts,
        selectedOrderLevelDiscounts,
        selectedDeliveryDiscount,
      };
    })

    .addCase(setDeliveryDiscount, (state, { payload }) => {
      const { orderId, value } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.selectedDeliveryDiscount = value;
      orderEditionEntry.isChanged = true;
    })

    .addCase(setIsPositionLevelDiscount, (state, { payload }) => {
      const { orderId, isPositionLevelDiscount } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.isPositionLevelDiscount = isPositionLevelDiscount;
      orderEditionEntry.selectedOrderLevelDiscounts = [];
      orderEditionEntry.selectedPositionLevelDiscounts = {};
      orderEditionEntry.isChanged = true;
      delete orderEditionEntry.summary?.orderPositions;
    })

    .addCase(setOrderLevelDiscounts, (state, { payload }) => {
      const { orderId, discounts } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.selectedOrderLevelDiscounts = discounts;
      orderEditionEntry.isPositionLevelDiscount = false;
      orderEditionEntry.selectedPositionLevelDiscounts = {};
      orderEditionEntry.isChanged = true;
      delete orderEditionEntry.summary?.orderPositions;
    })

    .addCase(setPositionLevelDiscount, (state, { payload }) => {
      const { orderId, id, discount } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.selectedPositionLevelDiscounts[id] = discount;
      orderEditionEntry.isPositionLevelDiscount = true;
      orderEditionEntry.selectedOrderLevelDiscounts = [];
      orderEditionEntry.isChanged = true;
      delete orderEditionEntry.summary?.orderPositions;
    })

    .addCase(destroyOrderEditionEntry, (state, { payload }) => {
      delete state.orders?.[payload];
    })

    .addCase(setIsPriceDiscrepancy, (state, { payload }) => {
      const { orderId, isPriceDiscrepancy } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.isPriceDiscrepancy = isPriceDiscrepancy;
      orderEditionEntry.isChanged = true;
    })

    .addCase(setExpectedTotal, (state, { payload }) => {
      const { orderId, expectedTotal } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.expectedTotal = expectedTotal;
      orderEditionEntry.isChanged = true;
    })

    .addCase(setExpectedTotalDiscountName, (state, { payload }) => {
      const { orderId, expectedTotalDiscountName } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.expectedTotalDiscountName = expectedTotalDiscountName;
      orderEditionEntry.isChanged = true;
    })

    .addCase(setIsScheduledForPartialRefund, (state, { payload }) => {
      const { orderId, isScheduledForPartialRefund } = payload;
      const orderEditionEntry = getOrderEditionEntry(state, orderId);
      orderEditionEntry.isScheduledForPartialRefund = isScheduledForPartialRefund;
      orderEditionEntry.isChanged = true;
    })
);
