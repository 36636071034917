import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import * as customerService from 'service/customer.service';
import * as orderService from 'service/order.service';
import { OrderListElement } from 'types/OrderListElement';
import { CustomersState } from './customers.types';
import { getSearchOrdersDefaultDateFrom, getSearchOrdersDefaultDateTo } from 'utils/date';
import { AppDispatch } from 'store';
import { notify } from 'utils/notifications';
import { Customer } from 'types/Customer';
import { ResourceLocation } from 'types/ResourceLocation';

import {
  CreateCustomerAddressProps,
  UpdateCustomerAddressProps,
  UpdateCustomerProps,
} from 'service/customer.service';

export const resetCustomer = createAction('customers/resetCustomer');

export const loadCustomersList = createAsyncThunk(
  'customers/loadCustomersList',
  (
    { searchPhrase, page, perPage }: { searchPhrase: string; page: number; perPage: number },
    { signal }
  ) =>
    customerService.getCustomers({
      searchPhrase,
      page,
      perPage,
      signal,
    })
);

export const getCustomer = createAsyncThunk<
  Customer,
  { customerId: string },
  { state: { customers: CustomersState } }
>('customers/getCustomer', customerService.getCustomer, {
  condition: ({ customerId }, { getState }) => {
    const { customer } = getState().customers;
    return !customer || customer.id !== customerId;
  },
});

export const setCustomerData = createAction<Customer>('customers/setCustomerData');

export const createNewCustomer = createAsyncThunk(
  'customers/createNewCustomer',
  customerService.createNewCustomer
);

export const updateCustomer = createAsyncThunk<Customer, UpdateCustomerProps>(
  'customers/updateCustomer',
  arg =>
    customerService
      .updateCustomer(arg)
      .then(() => customerService.getCustomer({ customerId: arg.customerId }))
      .then(customer => {
        notify.success('Customer updated');
        return customer;
      })
);

export const setCustomerOrdersPage = createAction<number>('customers/setCustomerOrdersPage');

export const getCustomerOrders = createAsyncThunk<
  {
    orders: OrderListElement[];
    pagesCount: number;
  },
  { silently: boolean },
  { state: { customers: CustomersState } }
>('customers/getCustomerOrders', (_, { getState }) => {
  const {
    customers: { customerOrders },
  } = getState();
  return orderService
    .getOrders({
      dateFrom: getSearchOrdersDefaultDateFrom(),
      dateTo: getSearchOrdersDefaultDateTo(),
      customerId: customerOrders.customerId,
      page: customerOrders.page,
      perPage: customerOrders.perPage,
    })
    .then(({ elements, totalCount }) => ({
      orders: elements,
      pagesCount: Math.ceil(totalCount / customerOrders.perPage),
    }));
});

export const getJiraComplaintsOrders = createAsyncThunk<
  {
    orders: OrderListElement[];
  },
  undefined,
  { state: { customers: CustomersState } }
>('customers/getJiraComplaintsOrders ', (_, { getState }) => {
  const {
    customers: { customerOrders },
  } = getState();
  return orderService
    .getOrders({
      dateFrom: getSearchOrdersDefaultDateFrom(),
      dateTo: getSearchOrdersDefaultDateTo(),
      customerId: customerOrders.customerId,
      page: 1,
      perPage: 100,
    })
    .then(({ elements }) => ({
      orders: elements,
    }));
});

export const goToPage =
  (page: number) =>
  (dispatch: AppDispatch): void => {
    dispatch(setCustomerOrdersPage(page));
  };

export const setCustomerOrdersCustomerId = createAction<string>(
  'customers/setCustomerOrdersCustomerId'
);

export const createCustomerAddress = createAsyncThunk<
  ResourceLocation<void>,
  CreateCustomerAddressProps
>('customerAddress/create', arg =>
  customerService.createCustomerAddress(arg).then(payload => {
    notify.success('Address created');
    return payload;
  })
);

export const updateCustomerAddress = createAsyncThunk(
  'customerAddress/update',
  (arg: UpdateCustomerAddressProps) =>
    customerService.updateCustomerAddress(arg).then(() => {
      notify.success('Address updated');
    })
);
