interface BuildFormatLongFormats {
  full: string;
  long: string;
  medium: string;
  short: string;
}

interface BuildFormatLongOptions {
  formats: BuildFormatLongFormats;
  defaultWidth: keyof BuildFormatLongFormats;
}

interface FormatLongOptions {
  width?: keyof BuildFormatLongFormats;
}

export const buildFormatLongFn =
  ({ defaultWidth, formats }: BuildFormatLongOptions) =>
  (options: FormatLongOptions): string => {
    return formats[options?.width ?? defaultWidth];
  };
