import { SelectedPositionPrice } from 'types/SelectedPositionPrice';
import { PriceOverride } from 'types/PriceOverride';
import * as Sentry from '@sentry/browser';

const logPositionPriceDividing = (price: number, quantity: number, parentQuantity: number) => {
  if (quantity) {
    const priceFromDividing = price / parentQuantity / quantity;
    const roundedPrice = Number(priceFromDividing.toFixed(2));
    if (roundedPrice * quantity * parentQuantity !== Number(price.toFixed(2))) {
      const extra = {
        price,
        parentQuantity,
        quantity,
        priceFromDividing,
        roundedPrice,
      };
      Sentry.captureException(new Error(`Price cannot be divided by quantity`), { extra });
    }
  }
};

export const serializeSelectedPositionPrice = (
  selectedPositionPrice: SelectedPositionPrice,
  isNet: boolean,
  quantity: number,
  parentQuantity: number
): PriceOverride | undefined => {
  const { isEdited } = selectedPositionPrice;

  if (!isEdited) {
    return undefined;
  }

  const netPrice = Number(selectedPositionPrice.netPrice);
  logPositionPriceDividing(netPrice, quantity, parentQuantity);

  return {
    total: Number(isNet ? selectedPositionPrice.netPrice : selectedPositionPrice.grossPrice),
    isNet,
  };
};
