import useLocalStorageState from 'use-local-storage-state';
import { useCallback, useEffect, useMemo, useRef } from 'react';

interface DataWithVersion {
  _version?: number;
}

interface LocalStorageProperties {
  isPersistent: boolean;
  removeItem: () => void;
}

const applyVersion = <T>(data: T, version: number) => ({
  ...data,
  _version: version,
});

const migrateIfNeeded = <T extends DataWithVersion>(data: T, migrations: ((data: T) => T)[]): T => {
  let transformedData = data;
  let transformedDataVersion = data._version ?? 0;

  let nextStep;
  while ((nextStep = migrations[transformedDataVersion - 1]) !== undefined) {
    transformedDataVersion++;
    const transformationResult = nextStep(transformedData);
    transformedData = applyVersion(transformationResult, transformedDataVersion);
  }

  return transformedData as T;
};

export const useLocalStorageStateWithMigrations = <T extends DataWithVersion>(
  key: string,
  defaultValue: T,
  migrations: ((data: T) => T)[]
): [T, (updater: (previousState: T) => T) => void, LocalStorageProperties] => {
  const [state, updateState, ...rest] = useLocalStorageState(key, { defaultValue });

  const currentVersion = useMemo(() => migrations.length, [migrations]);
  const migratedState = useMemo(() => migrateIfNeeded<T>(state, migrations), [state, migrations]);
  const initialState = useRef(migratedState);

  useEffect(() => {
    updateState(initialState.current);
  }, [updateState]);

  const updateStateWithVersion = useCallback(
    (updater: (previousState: T) => T) => {
      updateState(previousState => {
        const state = updater(previousState);

        return applyVersion(state, currentVersion);
      });
    },
    [currentVersion, updateState]
  );

  return [migratedState, updateStateWithVersion, ...rest];
};
