import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'utils/date';
import { get } from 'lodash';

import { getPosDetails } from 'service/metadata.service';

import * as orderService from 'service/order.service';
import * as positionsService from 'service/positions.service';
import * as brandService from 'service/brand.service';

import { dialog } from 'utils/dialog';
import { notify } from 'utils/notifications';
import store, { AppDispatch } from 'store';
import { MenuPositionType } from 'types/MenuPositionType';
import { OrdersState, SearchOrdersFilters } from 'store/orders/orders.types';
import { OrderListElement } from 'types/OrderListElement';
import { Order } from 'types/Order';
import { OrderStatus } from 'types/OrderStatus';
import { DeliveryDetails } from 'types/DeliveryDetails';
import { SEARCH_ORDERS_DATE_FORMAT_FROM, SEARCH_ORDERS_DATE_FORMAT_TO } from 'utils/constants';
import { InternalOrderNote } from 'types/InternalOrderNote';
import { KitchenDeliveryPartner } from 'types/KitchenDeliveryPartner';
import { KitchenBrandDeliveryPartner } from 'types/KitchenBrandDeliveryPartner';
import { CcpMenuCombo } from 'types/CcpMenuCombo';
import { CcpMenuItem } from 'types/CcpMenuItem';

const ORDERS_PER_PAGE = 100;

interface DisplayError {
  message: string;
  traceId: string;
  code: string;
}

const isDisplayError = (error: unknown): error is DisplayError =>
  typeof error === 'object' && error !== null && 'traceId' in error;

const displayErrorDialog = ({ message, traceId, code }: DisplayError) =>
  dialog.error({ title: code, message, traceId });

export const updateSearchOrdersFilters = createAsyncThunk<
  SearchOrdersFilters,
  Partial<SearchOrdersFilters>,
  { state: { orders: OrdersState } }
>('orders/updateSearchFilters', (searchFilters, { getState }) => {
  const newState = {
    ...getState().orders.searchOrdersFilters,
    ...searchFilters,
  };

  return Promise.resolve(newState);
});

export const searchOrders = createAsyncThunk<
  {
    orders: OrderListElement[];
    totalCountByStatus: {
      [status in OrderStatus]?: number;
    };
    pagesCount: number;
  },
  { silently: boolean },
  { state: { orders: OrdersState } }
>('orders/searchOrders', (_args, { getState, signal }) => {
  const state = getState();
  const { searchOrdersFilters } = state.orders;

  const range = {
    dateFrom: format(
      searchOrdersFilters?.searchRange?.[0] ?? new Date(),
      SEARCH_ORDERS_DATE_FORMAT_FROM
    ),
    dateTo: format(
      searchOrdersFilters?.searchRange?.[1] ?? new Date(),
      SEARCH_ORDERS_DATE_FORMAT_TO
    ),
  };

  return orderService
    .getOrders({
      signal,
      ...searchOrdersFilters,
      ...range,
      perPage: ORDERS_PER_PAGE,
      page: state.orders.selectedPage,
    })
    .then(({ elements, totalCountByStatus, totalCount }) => ({
      orders: elements,
      totalCountByStatus,
      pagesCount: Math.ceil(totalCount / ORDERS_PER_PAGE),
    }));
});

export const setCurrentPage = createAction<{ pageNumber: number }>('orders/setCurrentPage');

export const goToPage =
  (pageNumber: number) =>
  (dispatch: AppDispatch): void => {
    dispatch(setCurrentPage({ pageNumber }));
    dispatch(searchOrders({ silently: false }));
  };

export const loadPosDetails = createAsyncThunk('orders/loadPosDetails', getPosDetails);

export const getOrderDetails =
  ({ orderID, silently }: { orderID: string; silently: boolean }) =>
  async (dispatch: AppDispatch, getState: typeof store.getState): Promise<void> => {
    const state = getState();

    const fetchedPoses = get(state, 'orders.poses');

    dispatch(getOrderDetails.pending({ orderID, silently }));

    try {
      const orderDetails = await orderService.getOrder({ orderID });

      const orderPosId = orderDetails.pointOfSaleId;
      const isPosAlreadyFetched = Object.keys(fetchedPoses).includes(orderPosId);

      if (!isPosAlreadyFetched) {
        dispatch(loadPosDetails({ pointOfSaleId: orderPosId }));
      }
      dispatch(getOrderDetails.fulfilled({ order: orderDetails }));
    } catch (e) {
      dispatch(getOrderDetails.rejected({ orderID }));
      isDisplayError(e) && displayErrorDialog(e);
    }
  };

export const getDeliveryDetails = createAsyncThunk<
  { deliveryDetails: DeliveryDetails },
  { orderID: string; silently: boolean }
>('orders/getDeliveryDetails', ({ orderID }) =>
  orderService
    .getDeliveryDetails({ orderID })
    .then((deliveryDetails: DeliveryDetails) => ({
      deliveryDetails,
    }))
    .catch(error => {
      if (error.status !== 404) {
        displayErrorDialog(error);
      }
      return Promise.reject(error);
    })
);

getOrderDetails.pending = createAction<{ orderID: string; silently: boolean }>(
  'orders/getDetails/pending'
);
getOrderDetails.fulfilled = createAction<{ order: Order }>('orders/getDetails/fulfilled');
getOrderDetails.rejected = createAction<{ orderID: string }>('orders/getDetails/rejected');

export const cancelOrder = createAsyncThunk<
  Order,
  {
    orderID: string;
    posID: string;
    cancelReason: string;
    isScheduledForFullRefund?: boolean;
  }
>('orders/cancelOrder', ({ orderID, posID, cancelReason, isScheduledForFullRefund }) =>
  orderService
    .cancelOrder({ cancelReason, orderID, posID, isScheduledForFullRefund })
    .then(() => orderService.getOrder({ orderID }))
    .then(order => {
      notify.success('Order cancelled');

      return order;
    })
);

export const setOrderAsDelivered = createAsyncThunk<
  Order,
  { pointOfSaleId: string; orderId: string },
  { state: { orders: OrdersState } }
>('orders/setAsDelivered', ({ pointOfSaleId, orderId }, { dispatch }) =>
  orderService
    .setOrderAsDelivered({ pointOfSaleId, orderId })
    .then(() => dispatch(searchOrders({ silently: true })))
    .then(() => orderService.getOrder({ orderID: orderId }))
    .then(order => {
      notify.success('Order successfully set as delivered');
      return order;
    })
);

export const fetchPositions = createAsyncThunk(
  'orders/fetchPositions',
  ({ posId, kitchenId, brandId }: { posId: string; kitchenId: string; brandId: string }) =>
    positionsService
      .getPositionsForBrand({
        posId,
        kitchenId,
        brandId,
      })
      .then(({ elements }) => elements)
);
export const fetchPositionDetails = createAsyncThunk<
  CcpMenuItem | CcpMenuCombo,
  {
    posId: string;
    kitchenId: string;
    masterId: string;
    brandId: string;
    positionType: MenuPositionType;
  }
>('orderService/fetchPositionDetails', ({ posId, kitchenId, masterId, brandId, positionType }) => {
  if (positionType === MenuPositionType.ITEM) {
    return positionsService.getBrandItem({
      posId,
      kitchenId,
      masterId,
      brandId,
    });
  } else {
    return positionsService.getBrandCombo({
      posId,
      kitchenId,
      masterId,
      brandId,
    });
  }
});

export const updateOrderAddress = createAsyncThunk(
  'orders/updateOrderAddress',
  orderService.updateOrderAddress
);

export const assignAgent = createAsyncThunk<Order, { orderId: string }>(
  'orders/assignAgent',
  ({ orderId }) =>
    orderService
      .assignAgent({ orderId })
      .then(() => orderService.getOrder({ orderID: orderId }))
      .then(order => {
        notify.success('You are successfully assigned to the order');

        return order;
      })
);

export const reassignDeliveryPartner = createAsyncThunk(
  'orders/reassignDeliveryPartner',
  orderService.reassignDeliveryPartner
);

export const getBrandPointOfSaleDeliveryPartners = createAsyncThunk<
  KitchenBrandDeliveryPartner[],
  {
    kitchenId: string;
    brandId: string;
    pointOfSaleId: string;
  }
>('orders/getBrandPointOfSaleDeliveryPartners', ({ kitchenId, brandId, pointOfSaleId }) =>
  brandService
    .getBrandPointOfSaleDeliveryPartners({ kitchenId, brandId, pointOfSaleId })
    .then(({ elements }) => elements)
);

export const getKitchenDeliveryPartners = createAsyncThunk<
  KitchenDeliveryPartner[],
  { kitchenId: string }
>('orders/getKitchenDeliveryPartners', ({ kitchenId }) =>
  brandService.getKitchenDeliveryPartners({ kitchenId }).then(({ elements }) => elements)
);

export const getOrderModifications = createAsyncThunk(
  'orders/getOrderModifications',
  orderService.getOrderModifications
);

export const fetchInternalOrderNotes = createAsyncThunk<InternalOrderNote[], { orderId: string }>(
  'orders/fetchInternalOrderNotes',
  ({ orderId }) =>
    orderService
      .getInternalOrderNotes({
        orderId,
      })
      .then(({ elements }) => elements)
);

export const addInternalOrderNote = createAsyncThunk(
  'orders/addInternalOrderNote',
  ({ orderId, note }: { orderId: string; note: string }) =>
    orderService
      .addInternalOrderNote({ orderId, note })
      .then(() => orderService.getInternalOrderNotes({ orderId }))
      .then(order => {
        notify.success('You have successfully added your note to the order');
        return order;
      })
);

export const resolveInternalOrderNote = createAsyncThunk(
  'orders/resolveInternalOrderNote',
  orderService.resolveInternalOrderNote
);

export const deleteInternalOrderNote = createAsyncThunk(
  'orders/deleteInternalOrderNote',
  orderService.deleteInternalOrderNote
);

export const getParcelCheckInTime = createAsyncThunk(
  'orders/getParcelCheckInTime',
  orderService.getParcelCheckInTime
);
