import { useContext } from 'react';

import { ConfirmationDialogContext, ConfirmationDialogContextI } from './ConfirmationDialogContext';

export const useConfirmationDialog = (): NonNullable<ConfirmationDialogContextI> => {
  const context = useContext(ConfirmationDialogContext);

  if (context === undefined) {
    throw new Error('useConfirmationDialog must be used within a ConfirmationDialogProvider');
  }

  return context;
};
