import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { AppErrorBoundaryRoot, StyledErrorOutlineIcon } from './AppErrorBoundary.styles';

interface AppErrorBoundaryProps {
  readonly error?: Error;
  readonly date?: Date;
  readonly email?: string;
  readonly sentryEventId?: string;
  readonly appType: string;
  readonly appVersion: string;
  readonly instanceId: string;
  readonly children: ReactNode;
}

export const AppErrorBoundary = ({
  error,
  date,
  email,
  sentryEventId,
  children,
  appType,
  appVersion,
  instanceId,
}: AppErrorBoundaryProps) => {
  if (!error) {
    return <>{children}</>;
  }

  return (
    <AppErrorBoundaryRoot data-testid="app-error-boundary">
      <StyledErrorOutlineIcon fontSize="large" />

      <Typography variant="h3">Something went wrong</Typography>

      <Box mt={4}>
        <Typography>Error: {error.message}</Typography>
        <Typography>
          App version: {appType}-v{appVersion}
        </Typography>
        <Typography>Current time: {date?.toISOString()}</Typography>
        <Typography>User email: {email}</Typography>
        <Typography>Instance Id: {instanceId}</Typography>
        {sentryEventId && <Typography>Sentry event Id: {sentryEventId}</Typography>}
      </Box>
    </AppErrorBoundaryRoot>
  );
};
