import { useLocalStorageStateWithMigrations } from 'hooks/useLocalStorageStateWithMigrations';
import { useCallback, useMemo } from 'react';
import { createContainer } from 'react-tracked';
import { AppSettings } from './AppSettingsContext.types';
import { migrations } from './AppSettingsContextProvider.migrations';
import { appSettingsDefaultValues } from './appSettingsDefaultValues';

export const useAppSettingsState = () => {
  const [localStorageAppSettings, setLocalStorageAppSettings] =
    useLocalStorageStateWithMigrations<AppSettings>(
      'appSettings',
      appSettingsDefaultValues,
      migrations
    );

  const setAppSettings = useCallback(
    (nextSettings: Partial<AppSettings>) => {
      setLocalStorageAppSettings(prevSettings => ({
        ...prevSettings,
        ...nextSettings,
      }));
    },
    [setLocalStorageAppSettings]
  );

  const state = useMemo(
    () => ({
      appSettings: localStorageAppSettings,
      setAppSettings,
    }),
    [localStorageAppSettings, setAppSettings]
  );

  return [
    state,
    () => {
      throw new Error('Use update functions in the state');
    },
  ] as const;
};

export const { Provider: AppSettingsContextProvider, useTrackedState: useAppSettings } =
  createContainer(useAppSettingsState);
